import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import Select from "react-select";
import axios from "axios";
import "../../../../CSS/AnalyticsBasedOnClientAndItem.css";
import ReactApexChart from "react-apexcharts";
import LoadingScreen from "../../../../Components/LoadingScreen";
import Loading2 from "../../../../Components/Loading2";
import Footer from "../../../../Components/Footer";
import { FaChartPie } from "react-icons/fa";
import { IoBarChartOutline } from "react-icons/io5";
import { BiScatterChart } from "react-icons/bi";
import { BsCalendar2Date } from "react-icons/bs";
import { FaChartArea } from "react-icons/fa";
const VehicleMakewiseExpenses = () => {
  const [isLoading2, setLoading2] = useState(false);
  const [isloading, setisloading] = useState(false);
  const [data, setData] = useState([]);
  const [VehicleNo, setVehicleNo] = useState([]);
  const [selvehicleNo, setSelVehicleNo] = useState("");
  const [fromdate, setFromdate] = useState(
    new Date(new Date().getFullYear(), 0, 2).toISOString()
  );
  const [todate, setTodate] = useState(new Date().toISOString());
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  // if (isloading) {
  //   return <LoadingScreen />;
  // }
  useEffect(() => {
    getVehicleNo();
    AnalyzeBasedOnClient();
    setisloading(false);
  }, [fromdate, todate, selvehicleNo]);

  const AnalyzeBasedOnClient = () => {
    const startDatePass =
      fromdate.slice(8, 10) +
      "/" +
      fromdate.slice(5, 7) +
      "/" +
      fromdate.slice(0, 4);
    const endDatePass =
      todate.slice(8, 10) + "/" + todate.slice(5, 7) + "/" + todate.slice(0, 4);
    console.log("startDatePass", startDatePass, "endDatePass", endDatePass);
    console.log(selvehicleNo);
    if (startDatePass && endDatePass && selvehicleNo) {
      setLoading2(true);
      axios
        .post(
          `${BASEURL}/vehiclemakewiseanalysis`,
          {
            STARTDATE: startDatePass,
            ENDDATE: endDatePass,
            VEHICLEMAKE: selvehicleNo,
          },
          {
            headers: {
              "auth-token": authToken,
            },
          }
        )
        .then((response) => {
          setLoading2(false);
          setData(response.data);
          console.log(response.data);
        })
        .catch((error) => {
          setLoading2(false);
          console.log(error);
        });
    }
  };

  const getVehicleNo = () => {
    axios
      .get(`${BASEURL}/getvehiclemake`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        setVehicleNo(response.data);
      })
      .catch((error) => {
        console.error("Error fetching VehicleNo:", error);
      });
  };
  const [chartType, setChartType] = useState("bar");

  const handleButtonClick = (type) => {
    setChartType(type);
  };

  const [activeDates, setActiveDates] = useState(false);
  const handleDateFilterChange = (filter) => {
    const currentDate = new Date();
    let startDate = null;
    let endDate = null;
    setActiveDates(false);

    switch (filter) {
      case "last7days":
        startDate = new Date(
          currentDate.getTime() - 7 * 24 * 60 * 60 * 1000
        ).toISOString();
        endDate = currentDate.toISOString();
        break;
      case "last3days":
        startDate = new Date(
          currentDate.getTime() - 3 * 24 * 60 * 60 * 1000
        ).toISOString();
        endDate = currentDate.toISOString();
        break;
      case "last30days":
        startDate = new Date(
          currentDate.getTime() - 30 * 24 * 60 * 60 * 1000
        ).toISOString();
        endDate = currentDate.toISOString();
        break;
      case "lastMonth":
        startDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() - 1,
          2
        ).toISOString();
        endDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
        ).toISOString();
        break;
      case "lastQuarter":
        startDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() - 3,
          2
        ).toISOString();
        endDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
        ).toISOString();
        break;
      case "lastYear":
        const previousYear = currentDate.getFullYear() - 1;
        startDate = new Date(previousYear, 0, 2).toISOString(); // January 1st of the previous year
        endDate = new Date(previousYear, 12, 1).toISOString(); // December 31st of the previous year
        break;

      default:
        break;
    }

    setFromdate(startDate);
    setTodate(endDate);
  };

  const filterRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (filterRef.current && !filterRef.current.contains(event.target)) {
      setActiveDates(false);
    }
  };
  if (isloading) {
    return <LoadingScreen />;
  }
  return (
    <div ref={filterRef}>
      <div className="align__date_1">
        <div className="cursor-pointer lg:flex items-center gap-2 w-">
          <div
            className="flex gap-3"
            onClick={() => setActiveDates(!activeDates)}
          >
            <h2 className="text-2xl">
              {" "}
              <BsCalendar2Date />
            </h2>
            <div>
              <span className="bg-white inline-block px-1 rounded border-1px border-solid">
                {fromdate.slice(8, 10) +
                  "-" +
                  fromdate.slice(5, 7) +
                  "-" +
                  fromdate.slice(0, 4)}
              </span>{" "}
              -{" "}
              <span className="bg-white inline-block px-1 rounded">
                {todate.slice(8, 10) +
                  "-" +
                  todate.slice(5, 7) +
                  "-" +
                  todate.slice(0, 4)}
              </span>
            </div>
          </div>
          <div
            className="lg:flex items-center lg:ml-10"
            style={{ zIndex: "100" }}
          >
            <aside>
              <p className="mt-2  lg:mt-0 lg:ml-[100px]">Vehicle Make :</p>
            </aside>
            <Select
              className="route_select"
              onChange={(e) => {
                setSelVehicleNo(e.value);
              }}
              value={
                selvehicleNo
                  ? { label: selvehicleNo, value: selvehicleNo }
                  : { label: "Select Vehicle", value: "" }
              }
              options={[
                { value: "", label: "" },
                ...VehicleNo.map((item) => ({
                  value: item?.MAKE,
                  label: item?.MAKE,
                })),
              ]}
            />
          </div>
        </div>
        <div className="w-[200px]">
          {" "}
          {/* <Select options={options} onChange={handleDateFilterChange} /> */}
        </div>

        {activeDates && (
          <div className="date__custom">
            {/* Date filter buttons */}
            <div className="flex justify-between">
              <h1>Recommended</h1>
            </div>
            <button onClick={() => handleDateFilterChange("last3days")}>
              Last 3 Days
            </button>
            <button onClick={() => handleDateFilterChange("last7days")}>
              Last 7 Days
            </button>

            <button onClick={() => handleDateFilterChange("last30days")}>
              Last 30 Days
            </button>
            <h1>Calendar months</h1>
            <button onClick={() => handleDateFilterChange("lastMonth")}>
              Last Month
            </button>
            <button onClick={() => handleDateFilterChange("lastQuarter")}>
              Last Quarter
            </button>
            <button onClick={() => handleDateFilterChange("lastYear")}>
              Last Year
            </button>
            <h1>Custom date</h1>
            <main className="flex mx-1 gap-3">
              <div className="">
                <div>
                  <p>Start Date :</p>
                </div>
                <div>
                  <input
                    value={fromdate.split("T")[0]}
                    type="date"
                    onClick={() => setActiveDates(true)}
                    onChange={(e) => {
                      setFromdate(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="">
                <div>
                  <p>End Date :</p>
                </div>
                <div>
                  <input
                    value={todate.split("T")[0]}
                    type="date"
                    onClick={() => setActiveDates(true)}
                    onChange={(e) => {
                      if (e.target.value === "") {
                        alert("Please select a valid todate");
                      } else {
                        setTodate(e.target.value);
                      }
                    }}
                  />
                </div>
              </div>
            </main>
          </div>
        )}
      </div>

      <div
        style={{ width: "95%", margin: "0 auto", marginTop: "15px" }}
        onClick={() => setActiveDates(false)}
      >
        {isLoading2 ? (
          <Loading2 />
        ) : (
          <>
            {data && data.length > 0 ? (
              <div>
                {" "}
                <div className="chart__active__buttons">
                  <button
                    className={
                      chartType === "bar"
                        ? "active__chart"
                        : "not__active__chart"
                    }
                    onClick={() => handleButtonClick("bar")}
                  >
                    <IoBarChartOutline />
                  </button>
                  <button
                    className={
                      chartType === "pie"
                        ? "active__chart"
                        : "not__active__chart"
                    }
                    onClick={() => handleButtonClick("pie")}
                  >
                    <FaChartPie />
                  </button>

                  <button
                    className={
                      chartType === "scatter"
                        ? "active__chart"
                        : "not__active__chart"
                    }
                    onClick={() => handleButtonClick("scatter")}
                  >
                    <BiScatterChart />
                  </button>
                  <button
                    className={
                      chartType === "area"
                        ? "active__chart "
                        : "not__active__chart  "
                    }
                    onClick={() => handleButtonClick("area")}
                  >
                    <FaChartArea />
                  </button>
                </div>
                {chartType === "bar" && (
                  <ReactApexChart
                    options={{
                      chart: {
                        id: "bar-chart",
                        toolbar: {
                          show: true,
                        },
                      },
                      plotOptions: {
                        bar: {
                          horizontal: false,
                          columnWidth: "55%",
                          endingShape: "rounded",
                        },
                      },
                      xaxis: {
                        type: "category",
                        categories: data.map((item) =>
                          moment(item?.PERIOD).format(" MMM YYYY")
                        ),
                        title: {
                          text: "Period",
                        },
                      },
                      yaxis: {
                        title: {
                          text: "Vehicle Make",
                        },
                      },
                      zaxis: {
                        title: {
                          text: "Total Cost",
                        },
                      },
                      dataLabels: {
                        enabled: false,
                      },
                    }}
                    series={[
                      {
                        name: "Total Cost",
                        data: data.map((item) => ({
                          x: item?.PERIOD,
                          y: item?.TOTALCOST,
                          z: item?.VEHICLEMAKE,
                        })),
                      },
                    ]}
                    type="bar"
                    height={300}
                  />
                )}
                {chartType === "area" && (
                  <ReactApexChart
                    options={{
                      chart: {
                        id: "area-chart", // Change the ID for the area chart
                      },
                      xaxis: {
                        categories: data.map((item) =>
                          moment(item?.PERIOD).format(" MMM YYYY")
                        ),
                        title: {
                          text: "PERIOD", // X-axis label
                        },
                      },
                      yaxis: [
                        {
                          title: {
                            text: "AMOUNT", // Y-axis label
                          },
                        },
                      ],
                      dataLabels: {
                        enabled: false, // Disable the data labels for area chart
                      },
                    }}
                    series={[
                      {
                        name: "TOTAL COST",
                        data: data.map((item) => item?.TOTALCOST),
                      },
                      {
                        name: "VEHICLE MAKE",
                        data: data.map((item) => item?.VEHICLEMAKE),
                      },
                    ]}
                    type="area" // Change chart type to "area"
                    height={300}
                  />
                )}
                {chartType === "pie" && (
                  <ReactApexChart
                    options={{
                      labels: data.map((item) =>
                        moment(item?.PERIOD).format(" MMM YYYY")
                      ),
                      title: {
                        text: "Pie Chart", // Title of the pie chart
                      },
                      dataLabels: {
                        enabled: true, // Enable data labels for pie chart
                      },
                      tooltip: {
                        enabled: true,
                        y: {
                          formatter: function (val, opts) {
                            let index = opts.dataPointIndex;
                            let name = "TOTAL COST ";
                            return name + ": " + val;
                          },
                        },
                      },
                    }}
                    series={data.map((item) => item?.TOTALCOST)}
                    type="pie" // Set chart type to "pie"
                    height={300}
                  />
                )}
                {chartType === "scatter" && (
                  <ReactApexChart
                    options={{
                      xaxis: {
                        type: "datetime",
                        labels: {
                          formatter: function (val) {
                            return moment(val).format(" MMM YYYY");
                          },
                          title: {
                            text: "Period", // X-axis label
                          },
                        },
                      },
                      yaxis: {
                        title: {
                          text: "Vehicle Make", // Y-axis label
                        },
                      },
                      zaxis: {
                        title: {
                          text: "Total Cost",
                        },
                      },
                      title: {
                        text: "Scatter Chart",
                      },
                    }}
                    series={[
                      {
                        name: "Total Cost",
                        data: data.map((item) => ({
                          x: new Date(item?.PERIOD),
                          y: item?.TOTALCOST,
                          z: item?.VEHICLEMAKE,
                        })),
                      },
                    ]}
                    type="scatter"
                    height={300}
                  />
                )}
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "20px",
                  marginTop: "50px",
                  textDecoration: "underline",
                }}
              >
                {selvehicleNo && <p> No Data Available</p>}
                {!selvehicleNo && <p> Select Vehicle Make</p>}
              </div>
            )}
          </>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default VehicleMakewiseExpenses;
