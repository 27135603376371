import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import "../../../../CSS/AnalyticsBasedOnPaymentmode.css";
import Select from "react-select";
import axios from "axios";
import moment from "moment";
import ReactApexChart from "react-apexcharts";
import LoadingScreen from "../../../../Components/LoadingScreen";

import "../../../../CSS/ListofVehicles.css";
import { useNavigate } from "react-router-dom";
import ItemsCategoryStockValuation from "./ItemsCategoryStockValuation";
import ItemsClassificationStockValuation from "./ItemsClassificationStockValuation";
import { useSelector } from "react-redux";
import { selectUser } from "../../../../Components/features/userSlice";
const StockDashboard = () => {
  const user = useSelector(selectUser);
  const userEmail = user.email;
  const [selectedComponent, setSelectedComponent] = useState({
    value: "ItemsCategoryStockValuation",
    label: "Items Category Stock Valuation",
  });
  const options = [
    {
      value: "ItemsCategoryStockValuation",
      label: "Items Category Stock Valuation",
    },
    {
      value: "ItemsClassificationStockValuation",
      label: "Items Classification Stock Valuation",
    },
  ];

  const handleChange = (selectedOption) => {
    setSelectedComponent(selectedOption);
  };

  const [isloading, setisloading] = useState(true);

  useEffect(() => {
    setisloading(false);
  }, []);

  if (isloading) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <div
        className="Unapp_payment"
        style={{
          display: "flex",
          justifyContent: "space-between",
          position: "sticky",
          top: "0",
          left: "0",
          zIndex: 9999,
        }}
      >
        <h1 style={{ textAlign: "center" }} className="Titles">
          Tech23 Dashboards
        </h1>
        <div className="flex items-center gap-[50px]">
          <p className="text-white">
            Logged in as : {userEmail.split("@")[0].toUpperCase()}
          </p>
          <div className="back_btn">
            {" "}
            <Link
              style={{ textDecoration: "none", color: "white" }}
              to={"/AnalyticsDashboard"}
            >
              <button className="View">Back</button>
            </Link>
          </div>
        </div>
      </div>

      <header className="header__of__main__dashboard flex justify-between items-end px-3">
        <h2 className="header__of__page">Stock Analytics</h2>

        <div className="w-[400px] mx-2 " style={{ zIndex: 999 }}>
          <Select
            value={selectedComponent}
            onChange={handleChange}
            options={options}
            placeholder="Select Component"
          />
        </div>
      </header>

      <div>
        {selectedComponent &&
          selectedComponent.value === "ItemsCategoryStockValuation" && (
            <ItemsCategoryStockValuation />
          )}
        {selectedComponent &&
          selectedComponent.value === "ItemsClassificationStockValuation" && (
            <ItemsClassificationStockValuation />
          )}
      </div>
    </div>
  );
};

export default StockDashboard;
