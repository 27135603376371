// import React from "react";
// import { useEffect, useState } from "react";
// import axios from "axios";
// import "../../CSS/UnPayments.css";
// import { Link, useNavigate } from "react-router-dom";
// import moment from "moment";
// import LoadingScreen from "../../Components/LoadingScreen";
// import "./UnPayments";
// import { useSelector } from "react-redux";
// import { selectUser } from "../../Components/features/userSlice";
// import Footer from "../../Components/Footer";

// const TradingDashboard = () => {
//   return (
//     <div>
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "space-between",
//           position: "sticky",
//           top: "0",
//           left: "0",
//           zIndex: "500",
//         }}
//         className="Unapp_payment"
//       >
//         <h1 style={{ textAlign: "center" }} className="Titles">
//           Tech23 Dashboards
//         </h1>
//         <div className="nav_1">
//           <div className="back_btn">
//             <Link
//               style={{ textDecoration: "none", color: "white" }}
//               to={"/Dashboard2"}
//             >
//               <button className="View">Back</button>
//             </Link>
//           </div>
//         </div>
//       </div>
//       <div className="scroll">
//         <table
//           style={{ textTransform: "uppercase" }}
//           className="Payment_data_header"
//         >
//           <thead className="thead">
//             <tr className="Payment_tr">
//               <th className="header_payments_vendor">DEPARTMENTS</th>
//               <th className="header_payments_amount">UNAPPROVED DOCUMENTS</th>
//             </tr>
//           </thead>
//           <tbody className="Payments_tbody">
//             <tr className="Payments_tbody_tr">
//               <td
//                 className="td value_payments_vendor"
//                 style={{ width: "300px" }}
//               >
//                 Finance Department
//               </td>
//               <td className="td value_payments_amount">2</td>
//             </tr>

//             <tr className="Payments_tbody_tr">
//               <td
//                 className="td value_payments_vendor"
//                 style={{ width: "300px" }}
//               >
//                 Stores Department
//               </td>
//               <td className="td value_payments_amount">2</td>
//             </tr>

//             <tr className="Payments_tbody_tr">
//               <td
//                 className="td value_payments_vendor"
//                 style={{ width: "300px" }}
//               >
//                 Production Department
//               </td>
//               <td className="td value_payments_amount">2</td>
//             </tr>

//             <tr className="Payments_tbody_tr">
//               <td
//                 className="td value_payments_vendor"
//                 style={{ width: "300px" }}
//               >
//                 Sales
//               </td>
//               <td className="td value_payments_amount">2</td>
//             </tr>
//           </tbody>
//         </table>
//       </div>
//       <Footer />
//     </div>
//   );
// };

// export default TradingDashboard;

import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../../Components/features/userSlice";

import Footer from "../../Components/Footer";
import financeU from "../../Images/financeU.png";
import storesU from "../../Images/storesU.png";
import productionU from "../../Images/productionU.png";
import salesU from "../../Images/salesU.png";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../Components/features/userSlice";
function TradingDashboard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const userEmail = user.email;
  const logoutOff = (e) => {
    e.preventDefault();
    dispatch(logout());
    navigate("/");
  };

  return (
    <div>
      <div
        className="Unapp_payment"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",

          position: "sticky",
          top: "0",
          left: "0",
          zIndex: "500",
        }}
      >
        <h1 style={{ textAlign: "center" }} className="Titles">
          Tech23 Dashboards
        </h1>
        <div className="flex items-center gap-[50px]">
          <p className="text-white">
            Logged in as : {userEmail.split("@")[0].toUpperCase()}
          </p>
          <div className="back_btn">
            <Link
              style={{ textDecoration: "none", color: "white" }}
              to={"/Dashboard2"}
            >
              <button className="View">Back</button>
            </Link>
          </div>
        </div>
      </div>
      <div className="h2">
        <h1>Welcome To Trading Approval Platform</h1>
      </div>

      {/*  */}

      <section className="for__main__dashboard">
        <div
          // onClick={() => {
          //   navigate("/SalesDashboard");
          // }}
          className="align__img__and__heading"
        >
          <img src={financeU} height="150px" width="200px" alt="dashboard" />
          <h3> Finance Department</h3>
        </div>
        <div
          // onClick={() => {
          //   navigate("/StockDashboard");
          // }}
          className="align__img__and__heading"
        >
          <img src={storesU} height="150px" width="200px" alt="dashboard" />
          <h3>Stores Department</h3>
        </div>
        <div
          // onClick={() => {
          //   navigate("/PurchasesDashboard");
          // }}
          className="align__img__and__heading"
        >
          <img src={productionU} height="150px" width="200px" alt="dashboard" />
          <h3>Production Department</h3>
        </div>
        <div
          // onClick={() => {
          //   navigate("/FinancialDashboard");
          // }}
          className="align__img__and__heading"
        >
          <img src={salesU} height="150px" width="200px" alt="dashboard" />
          <h3>Sales Department</h3>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default TradingDashboard;
