import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import "../../../../CSS/AnalyticsBasedOnPaymentmode.css";
import Select from "react-select";
import axios from "axios";
import moment from "moment";
import ReactApexChart from "react-apexcharts";
import LoadingScreen from "../../../../Components/LoadingScreen";
import MonthWiseMileageForSelectedDriver from "./MonthWiseMileageForSelectedDriver";
import FuelandMileagebyMonthforSelectedVehicle from "./FuelandMileagebyMonthforSelectedVehicle";
import FuelandMileagebyVehicle from "./FuelandMileagebyVehicle ";
import DriverWiseMileageExpensesandFuel from "./DriverWiseMileageExpensesandFuel";
import TransportExpensesforFuelandMileagebyMonth from "./TransportExpensesforFuelandMileagebyMonth";
import TransportExpensesforMonthAmount from "./TransportExpensesforMonthAmount";
import TransportExpensesforVehicleAmount from "./TransportExpensesforVehicleAmount";
import TransportexpensesformonthAmountforselectedVehicle from "./TransportexpensesformonthAmountforselectedVehicle";
import RouteWiseMileageExpensesForSelectedRoute from "./RouteWiseMileageExpensesForSelectedRoute";
import RoutewiseMileageExpensesandFuelforSelectedRoute from "./RoutewiseMileageExpensesandFuelforSelectedRoute";
import RouteWiseMileage from "./RouteWiseMileage";
import "../../../../CSS/ListofVehicles.css";
import { useNavigate } from "react-router-dom";
import TransportDashboardsNavbar from "../../../../Components/TransportDashboardsNavbar";

const TransportExpenses = () => {
  const [selectedComponent, setSelectedComponent] = useState({
    value: "FuelandMileagebyMonthforSelectedVehicle",
    label: "Fuel and Trip Expense Month wise for Selected Vehicle",
  });
  const options = [
    {
      value: "FuelandMileagebyMonthforSelectedVehicle",
      label: "Fuel and Trip Expense Month wise for Selected Vehicle",
    },

    {
      value: "FuelandMileagebyVehicle",
      label: "Fuel and Trip Expense Expenses Vehicle wise",
    },

    {
      value: "TransportExpensesforFuelandMileagebyMonth",
      label: " Fuel and Trip Expense Month wise",
    },
    {
      value: "TransportexpensesformonthAmountforselectedVehicle",
      label: " Trip Expense Month wise for selected Vehicle ",
    },

    {
      value: "TransportExpensesforMonthAmount",
      label: "Trip Expenses Month wise",
    },
    {
      value: "TransportExpensesforVehicleAmount",
      label: "Trip Expenses Vehicle wise ",
    },

    {
      value: "RouteWiseMileage",
      label: "Trip Expense Route Wise ",
    },
    {
      value: "RouteWiseMileageExpensesForSelectedRoute",
      label: "Route Wise Trip Expense For Selected Route",
    },
    {
      value: "RoutewiseMileageExpensesandFuelforSelectedRoute",
      label: "Route wise Trip Expense and Fuel for Selected Route",
    },
    {
      value: "DriverWiseMileageExpensesandFuel",
      label: "Trip and Fuel Expense Driver Wise ",
    },
    {
      value: "MonthWiseMileageForSelectedDriver",
      label: "Trip Expense Month Wise For Selected Driver",
    },
  ];

  const handleChange = (selectedOption) => {
    setSelectedComponent(selectedOption);
  };

  const [isloading, setisloading] = useState(true);

  useEffect(() => {
    setisloading(false);
  }, []);

  if (isloading) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <TransportDashboardsNavbar />

      <header className="header__of__main__dashboard flex justify-between items-end px-3">
        <h2 className="header__of__page">Transport Expenses</h2>

        <div
          className="lg:w-[400px] text-sm w-[100%] mx-2 "
          style={{ zIndex: 999 }}
        >
          <Select
            value={selectedComponent}
            onChange={handleChange}
            options={options}
            placeholder="Select Component"
          />
        </div>
      </header>

      <div>
        {selectedComponent &&
          selectedComponent.value === "MonthWiseMileageForSelectedDriver" && (
            <MonthWiseMileageForSelectedDriver />
          )}
        {selectedComponent &&
          selectedComponent.value ===
            "FuelandMileagebyMonthforSelectedVehicle" && (
            <FuelandMileagebyMonthforSelectedVehicle />
          )}

        {selectedComponent &&
          selectedComponent.value === "FuelandMileagebyVehicle" && (
            <FuelandMileagebyVehicle />
          )}

        {selectedComponent &&
          selectedComponent.value ===
            "TransportExpensesforFuelandMileagebyMonth" && (
            <TransportExpensesforFuelandMileagebyMonth />
          )}

        {selectedComponent &&
          selectedComponent.value === "TransportExpensesforMonthAmount" && (
            <TransportExpensesforMonthAmount />
          )}

        {selectedComponent &&
          selectedComponent.value === "TransportExpensesforVehicleAmount" && (
            <TransportExpensesforVehicleAmount />
          )}

        {selectedComponent &&
          selectedComponent.value ===
            "TransportexpensesformonthAmountforselectedVehicle" && (
            <TransportexpensesformonthAmountforselectedVehicle />
          )}
        {selectedComponent &&
          selectedComponent.value === "RouteWiseMileage" && (
            <RouteWiseMileage />
          )}
        {selectedComponent &&
          selectedComponent.value ===
            "RouteWiseMileageExpensesForSelectedRoute" && (
            <RouteWiseMileageExpensesForSelectedRoute />
          )}
        {selectedComponent &&
          selectedComponent.value ===
            "RoutewiseMileageExpensesandFuelforSelectedRoute" && (
            <RoutewiseMileageExpensesandFuelforSelectedRoute />
          )}
        {selectedComponent &&
          selectedComponent.value === "DriverWiseMileageExpensesandFuel" && (
            <DriverWiseMileageExpensesandFuel />
          )}
      </div>
    </div>
  );
};

export default TransportExpenses;
