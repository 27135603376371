import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import axios from "axios";
import moment from "moment";
import ReactApexChart from "react-apexcharts";
import LoadingScreen from "../../../Components/LoadingScreen";
import Loading2 from "../../../Components/Loading2";

const BreakDowns = () => {
  const [fromdate, setFromdate] = useState(
    new Date(new Date().getFullYear(), 0, 2).toISOString()
  );
  const [todate, setTodate] = useState(new Date().toISOString());
  var [selItemCode, setSelItemCode] = useState("");
  const [selItemName, setSelItemName] = useState("");
  const [product, setProduct] = useState([]);
  const [data, setData] = useState([]);
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const [VehicleNo, setVehicleNo] = useState([]);
  var [selvehicleNo, setSelVehicleNo] = useState("");
  const [Location, setLocation] = useState([]);
  var [sellocation, setSelLocation] = useState("");
  const [datawithVehicle, setDatawithVehicle] = useState([]);
  const [datawithlocation, setDatawithLocation] = useState([]);
  const [datawithvechandlocation, setDatawithVechandLoaction] = useState([]);
  const [datawithlocationandriver, setDatawithLocationandriver] = useState([]);
  const [datawithdriver, setDatawithDriver] = useState([]);
  const [datawithdrivandvehicle, setDatawithDrivandVehicle] = useState([]);
  const [abc, setAbc] = useState([]);
  const [isloading, setisloading] = useState(true);
  const [isLoading2, setLoading2] = useState(true);

  useEffect(() => {
    BreakDown();
    getProduct();
    getVehicleNo();
    getLocation();
    setisloading(false);
  }, [selItemCode, selItemName, selvehicleNo, sellocation, fromdate, todate]);

  const getProduct = () => {
    axios
      .get(`${BASEURL}/DriverDetails`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        setProduct(response.data);
      })
      .catch((error) => {
        console.error("Error fetching itemcode:", error);
      });
  };

  const getVehicleNo = () => {
    axios
      .get(`${BASEURL}/VehicleNo`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        setVehicleNo(response.data);
      })
      .catch((error) => {
        console.error("Error fetching VehicleNo:", error);
      });
  };

  const getLocation = () => {
    axios
      .get(`${BASEURL}/LocationOfBreakdown`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        setLocation(response.data);
      })
      .catch((error) => {
        console.error("Error fetching itemcode:", error);
      });
  };
  const handleItemName = (e) => {
    const selectedValue = e;
    const selectedOption = product.find(
      (obj) => obj["EMPLOYEE NAME"] === selectedValue
    );
    if (selectedValue === "") {
      setSelItemName("");
      setSelItemCode("");
    } else if (selectedOption === "") {
      setSelItemCode("");
      setSelItemName("");
    } else {
      setSelItemName(selectedValue);
      setSelItemCode(selectedOption["EMPLOYEE CODE"]);
    }
  };

  const handleItemCode = (e) => {
    const selectedValue = e;
    const selectedOption = product.find(
      (obj) => obj["EMPLOYEE CODE"] === selectedValue
    );
    if (selectedValue === "") {
      setSelItemName("");
      setSelItemCode("");
    } else if (selectedOption === "") {
      setSelItemName("");
      setSelItemCode("");
    } else {
      setSelItemCode(selectedValue);
      setSelItemName(selectedOption["EMPLOYEE NAME"]);
    }
  };

  const BreakDown = () => {
    if (selvehicleNo === "" && sellocation === "" && selItemCode === "") {
      selvehicleNo = "null";
      sellocation = "null";
      selItemCode = "null";
      setLoading2(true);

      axios
        .post(
          `${BASEURL}/NumberOfBreakdowns/${selvehicleNo}/${sellocation}/${selItemCode}`,
          {
            Fromdate: moment(fromdate).format("DD/MM/YYYY"),
            Todate: moment(todate).format("DD/MM/YYYY"),
          },
          {
            headers: {
              "auth-token": authToken,
            },
          }
        )
        .then((response) => {
          setLoading2(false);
          setData(response.data);
          console.log(response.data);
        })
        .catch((error) => {
          setLoading2(false);
          console.log(error);
        });
    } else if (
      selvehicleNo !== "" &&
      sellocation === "" &&
      selItemCode === ""
    ) {
      sellocation = "null";
      selItemCode = "null";
      setLoading2(true);
      axios
        .post(
          `${BASEURL}/NumberOfBreakdowns/${selvehicleNo}/${sellocation}/${selItemCode}`,
          {
            Fromdate: moment(fromdate).format("DD/MM/YYYY"),
            Todate: moment(todate).format("DD/MM/YYYY"),
          },
          {
            headers: {
              "auth-token": authToken,
            },
          }
        )
        .then((response) => {
          setLoading2(false);
          setDatawithVehicle(response.data);
          console.log(response.data);
        })
        .catch((error) => {
          setLoading2(false);
          console.log(error);
        });
    } else if (
      selvehicleNo === "" &&
      sellocation !== "" &&
      selItemCode === ""
    ) {
      selvehicleNo = "null";
      selItemCode = "null";
      setLoading2(true);

      axios
        .post(
          `${BASEURL}/NumberOfBreakdowns/${selvehicleNo}/${sellocation}/${selItemCode}`,
          {
            Fromdate: moment(fromdate).format("DD/MM/YYYY"),
            Todate: moment(todate).format("DD/MM/YYYY"),
          },
          {
            headers: {
              "auth-token": authToken,
            },
          }
        )
        .then((response) => {
          setLoading2(false);
          setDatawithLocation(response.data);
          console.log(response.data);
        })
        .catch((error) => {
          setLoading2(false);
          console.log(error);
        });
    } else if (
      selvehicleNo === "" &&
      sellocation === "" &&
      selItemCode !== ""
    ) {
      selvehicleNo = "null";
      sellocation = "null";
      setLoading2(true);
      axios
        .post(
          `${BASEURL}/NumberOfBreakdowns/${selvehicleNo}/${sellocation}/${selItemCode}`,
          {
            Fromdate: moment(fromdate).format("DD/MM/YYYY"),
            Todate: moment(todate).format("DD/MM/YYYY"),
          },
          {
            headers: {
              "auth-token": authToken,
            },
          }
        )
        .then((response) => {
          setLoading2(false);
          setDatawithDriver(response.data);
          console.log(response.data);
        })
        .catch((error) => {
          setLoading2(false);
          console.log(error);
        });
    } else if (selvehicleNo !== "" && sellocation !== "" && selItemCode == "") {
      selItemCode = "null";
      setLoading2(true);
      axios
        .post(
          `${BASEURL}/NumberOfBreakdowns/${selvehicleNo}/${sellocation}/${selItemCode}`,
          {
            Fromdate: moment(fromdate).format("DD/MM/YYYY"),
            Todate: moment(todate).format("DD/MM/YYYY"),
          },
          {
            headers: {
              "auth-token": authToken,
            },
          }
        )
        .then((response) => {
          setLoading2(false);
          setDatawithVechandLoaction(response.data);
          console.log(response.data);
        })
        .catch((error) => {
          setLoading2(false);
          console.log(error);
        });
    } else if (
      selvehicleNo !== "" &&
      sellocation === "" &&
      selItemCode !== ""
    ) {
      sellocation = "null";
      setLoading2(true);
      axios
        .post(
          `${BASEURL}/NumberOfBreakdowns/${selvehicleNo}/${sellocation}/${selItemCode}`,
          {
            Fromdate: moment(fromdate).format("DD/MM/YYYY"),
            Todate: moment(todate).format("DD/MM/YYYY"),
          },
          {
            headers: {
              "auth-token": authToken,
            },
          }
        )
        .then((response) => {
          setLoading2(false);
          setDatawithDrivandVehicle(response.data);
          console.log(response.data);
        })
        .catch((error) => {
          setLoading2(false);
          console.log(error);
        });
    } else if (
      selvehicleNo === "" &&
      sellocation !== "" &&
      selItemCode !== ""
    ) {
      selvehicleNo = "null";
      setLoading2(true);
      axios
        .post(
          `${BASEURL}/NumberOfBreakdowns/${selvehicleNo}/${sellocation}/${selItemCode}`,
          {
            Fromdate: moment(fromdate).format("DD/MM/YYYY"),
            Todate: moment(todate).format("DD/MM/YYYY"),
          },
          {
            headers: {
              "auth-token": authToken,
            },
          }
        )
        .then((response) => {
          setLoading2(false);
          setDatawithLocationandriver(response.data);
          console.log(response.data);
        })
        .catch((error) => {
          setLoading2(false);
          console.log(error);
        });
    } else if (
      selvehicleNo !== "" &&
      sellocation !== "" &&
      selItemCode !== ""
    ) {
      setLoading2(true);
      axios
        .post(
          `${BASEURL}/NumberOfBreakdowns/${selvehicleNo}/${sellocation}/${selItemCode}`,
          {
            Fromdate: moment(fromdate).format("DD/MM/YYYY"),
            Todate: moment(todate).format("DD/MM/YYYY"),
          },
          {
            headers: {
              "auth-token": authToken,
            },
          }
        )
        .then((response) => {
          setLoading2(false);
          setAbc(response.data);
          console.log(response.data);
        })
        .catch((error) => {
          setLoading2(false);
          console.log(error);
        });
    }
  };
  if (isloading) {
    return <LoadingScreen />;
  }
  return (
    <div>
      <div
        className="Unapp_payment"
        style={{
          display: "flex",
          justifyContent: "space-between",
          position: "sticky",
          top: "0",
          left: "0",
          zIndex: "500",
        }}
      >
        <h1 style={{ textAlign: "center" }} className="Titles">
          Tech23 Dashboards
        </h1>
        <div className="nav_1">
          <div className="back_btn">
            {" "}
            <Link
              style={{ textDecoration: "none", color: "white" }}
              to={"/TransportDashboard1"}
            >
              <button className="View">Back</button>
            </Link>
          </div>
        </div>
      </div>
      <div className="h2">
        {/* <h2>Number of Break Downs</h2> */}
        <h2>BreakDowns Analytics</h2>
      </div>
      <div className="clearbutton__allPage">
        <button
          className=""
          onClick={() => {
            window.location.reload();
          }}
        >
          Clear
        </button>
      </div>
      <div className="align__date_1">
        <div className="list_start">
          <div>
            <p>Start Date :</p>
          </div>
          <div>
            <input
              className="input_list_start"
              value={fromdate.split("T")[0]}
              type="date"
              onChange={(e) => {
                if (e.target.value === "") {
                  alert("Please select a valid fromdate");
                } else {
                  setFromdate(e.target.value);
                }
              }}
            />
          </div>
        </div>
        <div className="list_of_vehi">
          <div>
            <p>End Date :</p>
          </div>
          <div>
            <input
              className="input_list_end"
              value={todate.split("T")[0]}
              type="date"
              onChange={(e) => {
                if (e.target.value === "") {
                  alert("Please select a valid todate");
                } else {
                  setTodate(e.target.value);
                }
              }}
            />
          </div>
        </div>

        <div className="analytics_supp_select_1" style={{ zIndex: "100" }}>
          <aside style={{}}>
            <p>Vehicle Number :</p>
          </aside>
          <div style={{ width: "300px" }}>
            <Select
              onChange={(e) => {
                // if (data.length <= 0) {
                //   alert("There is no data to sort!");
                // } else {
                setSelVehicleNo(e.value);
                // if (e.value === "") {
                //   setDataWithVehicleNo([]);
                // }
              }}
              value={
                selvehicleNo
                  ? { label: selvehicleNo, value: selvehicleNo }
                  : { label: "", value: "" }
              }
              options={[
                { value: "", label: "" },
                ...VehicleNo.map((item) => ({
                  value: item.OT_JOB_VEHICLE_NO,
                  label: item.OT_JOB_VEHICLE_NO,
                })),
              ]}
            />
          </div>
        </div>
      </div>
      <div className="Supp_analytics_Supp_Name_1">
        <div className="analytics_Category_2_select list_driver_code">
          <aside>
            <p>Driver Code :</p>
          </aside>
          <Select
            className="Select_item_code code_1"
            onChange={(selectedOption) => {
              // if (data.length <= 0) {
              //   alert("There is no data to sort!");
              // } else {
              handleItemCode(selectedOption.value);
            }}
            value={
              selItemCode
                ? { label: selItemCode, value: selItemCode }
                : { label: "", value: "" }
            }
            options={[
              { value: "", label: "" },
              ...product.map((item) => ({
                value: item["EMPLOYEE CODE"],
                label: item["EMPLOYEE CODE"],
              })),
            ]}
          />
        </div>
        <div className="analytics_Category_2_select list_driver_name">
          <aside>
            <p>Driver Name :</p>
          </aside>
          <Select
            className="Select_item_name driver"
            onChange={(selectedOption) => {
              // if (data.length <= 0) {
              //   alert("There is no data to sort!");
              // } else {
              handleItemName(selectedOption.value);
            }}
            value={
              selItemName
                ? { label: selItemName, value: selItemName }
                : { label: "", value: "" }
            }
            options={[
              { value: "", label: "" },
              ...product.map((item) => ({
                value: item["EMPLOYEE NAME"],
                label: item["EMPLOYEE NAME"],
              })),
            ]}
          />
        </div>

        <div className="analytics_Category_2_select ">
          <aside>
            <p className="location_no">Location :</p>
          </aside>
        </div>
        <div>
          <Select
            className="Select_Category driver code_2"
            onChange={(selectedOption) => {
              //   if (data.length <= 0) {
              //     alert("There is no data to sort!");
              //   } else {
              setSelLocation(selectedOption.value);
            }}
            value={
              sellocation
                ? { label: sellocation, value: sellocation }
                : { label: "", value: "" }
            }
            options={[
              { value: "", label: "" },
              ...Location.map((item) => ({
                value: item.OT_BREAKDOWN_LOCATION,
                label: item.OT_BREAKDOWN_LOCATION,
              })),
            ]}
          />
        </div>
      </div>
      <div style={{ width: "90%", margin: "0 auto", marginTop: "15px" }}>
        {isLoading2 ? (
          <Loading2 />
        ) : (
          <>
            {selvehicleNo &&
            !sellocation &&
            !selItemCode &&
            datawithVehicle.length > 0 ? (
              <ReactApexChart
                options={{
                  chart: {
                    id: "line-chart", // Change the ID for the line chart
                  },
                  xaxis: {
                    categories: datawithVehicle.map((item) =>
                      moment(item.MONTHNAME).format("MMM-YYYY")
                    ),
                    title: {
                      text: "Months", // X-axis label
                    },
                  },
                  yaxis: {
                    title: {
                      text: "Number of BreakDowns", // Y-axis label
                    },
                  },
                  dataLabels: {
                    enabled: false, // Disable the data labels for line chart
                  },
                }}
                series={[
                  {
                    name: "Number of BreakDowns",
                    data: datawithVehicle.map((item) => item.BREAKDOWNS),
                  },
                ]}
                type="line" // Change chart type to "line"
                height={300}
              />
            ) : !selvehicleNo &&
              sellocation &&
              !selItemCode &&
              datawithlocation.length > 0 ? (
              <ReactApexChart
                options={{
                  chart: {
                    id: "line-chart", // Change the ID for the line chart
                  },
                  xaxis: {
                    categories: datawithdrivandvehicle.map((item) =>
                      moment(item.MONTHNAME).format("MMM-YYYY")
                    ),
                    title: {
                      text: "Months", // X-axis label
                    },
                  },
                  yaxis: {
                    title: {
                      text: "Number of BreakDowns", // Y-axis label
                    },
                  },
                  dataLabels: {
                    enabled: false, // Disable the data labels for line chart
                  },
                }}
                series={[
                  {
                    name: "Number of BreakDowns",
                    data: datawithdrivandvehicle.map((item) => item.BREAKDOWNS),
                  },
                ]}
                type="line" // Change chart type to "line"
                height={300}
              />
            ) : !selvehicleNo &&
              !sellocation &&
              selItemCode &&
              datawithdriver.length > 0 ? (
              <ReactApexChart
                options={{
                  chart: {
                    id: "line-chart", // Change the ID for the line chart
                  },
                  xaxis: {
                    categories: datawithdriver.map((item) =>
                      moment(item.MONTHNAME).format("MMM-YYYY")
                    ),
                    title: {
                      text: "Months", // X-axis label
                    },
                  },
                  yaxis: {
                    title: {
                      text: "Number of BreakDowns", // Y-axis label
                    },
                  },
                  dataLabels: {
                    enabled: false, // Disable the data labels for line chart
                  },
                }}
                series={[
                  {
                    name: "Number of BreakDowns",
                    data: datawithdriver.map((item) => item.BREAKDOWNS),
                  },
                ]}
                type="line" // Change chart type to "line"
                height={300}
              />
            ) : selvehicleNo &&
              sellocation &&
              !selItemCode &&
              datawithvechandlocation.length > 0 ? (
              <ReactApexChart
                options={{
                  chart: {
                    id: "line-chart", // Change the ID for the line chart
                  },
                  xaxis: {
                    categories: datawithvechandlocation.map((item) =>
                      moment(item.MONTHNAME).format("MMM-YYYY")
                    ),
                    title: {
                      text: "Months", // X-axis label
                    },
                  },
                  yaxis: {
                    title: {
                      text: "Number of BreakDowns", // Y-axis label
                    },
                  },
                  dataLabels: {
                    enabled: false, // Disable the data labels for line chart
                  },
                }}
                series={[
                  {
                    name: "Number of BreakDowns",
                    data: datawithvechandlocation.map(
                      (item) => item.BREAKDOWNS
                    ),
                  },
                ]}
                type="line" // Change chart type to "line"
                height={300}
              />
            ) : selvehicleNo &&
              !sellocation &&
              selItemCode &&
              datawithdrivandvehicle.length > 0 ? (
              <ReactApexChart
                options={{
                  chart: {
                    id: "line-chart", // Change the ID for the line chart
                  },
                  xaxis: {
                    categories: datawithdrivandvehicle.map((item) =>
                      moment(item.MONTHNAME).format("MMM-YYYY")
                    ),
                    title: {
                      text: "Months", // X-axis label
                    },
                  },
                  yaxis: {
                    title: {
                      text: "Number of BreakDowns", // Y-axis label
                    },
                  },
                  dataLabels: {
                    enabled: false, // Disable the data labels for line chart
                  },
                }}
                series={[
                  {
                    name: "Number of BreakDowns",
                    data: datawithdrivandvehicle.map((item) => item.BREAKDOWNS),
                  },
                ]}
                type="line" // Change chart type to "line"
                height={300}
              />
            ) : !selvehicleNo &&
              sellocation &&
              selItemCode &&
              datawithlocationandriver.length > 0 ? (
              <ReactApexChart
                options={{
                  chart: {
                    id: "line-chart", // Change the ID for the line chart
                  },
                  xaxis: {
                    categories: datawithlocationandriver.map((item) =>
                      moment(item.MONTHNAME).format("MMM-YYYY")
                    ),
                    title: {
                      text: "Months", // X-axis label
                    },
                  },
                  yaxis: {
                    title: {
                      text: "Number of BreakDowns", // Y-axis label
                    },
                  },
                  dataLabels: {
                    enabled: false, // Disable the data labels for line chart
                  },
                }}
                series={[
                  {
                    name: "Number of BreakDowns",
                    data: datawithlocationandriver.map(
                      (item) => item.BREAKDOWNS
                    ),
                  },
                ]}
                type="line" // Change chart type to "line"
                height={300}
              />
            ) : selvehicleNo && sellocation && selItemCode && abc.length > 0 ? (
              <ReactApexChart
                options={{
                  chart: {
                    id: "line-chart", // Change the ID for the line chart
                  },
                  xaxis: {
                    categories: abc.map((item) =>
                      moment(item.MONTHNAME).format("MMM-YYYY")
                    ),
                    title: {
                      text: "Months", // X-axis label
                    },
                  },
                  yaxis: {
                    title: {
                      text: "Number of BreakDowns", // Y-axis label
                    },
                  },
                  dataLabels: {
                    enabled: false, // Disable the data labels for line chart
                  },
                }}
                series={[
                  {
                    name: "Number of BreakDowns",
                    data: abc.map((item) => item.BREAKDOWNS),
                  },
                ]}
                type="line" // Change chart type to "line"
                height={300}
              />
            ) : !selvehicleNo &&
              !sellocation &&
              !selItemCode &&
              data.length > 0 ? (
              <ReactApexChart
                options={{
                  chart: {
                    id: "line-chart", // Change the ID for the line chart
                  },
                  xaxis: {
                    categories: data.map((item) => item.VEHILE),
                    title: {
                      text: "Vehicle", // X-axis label
                    },
                  },
                  yaxis: {
                    title: {
                      text: "Number of BreakDowns", // Y-axis label
                    },
                  },
                  dataLabels: {
                    enabled: false, // Disable the data labels for line chart
                  },
                }}
                series={[
                  {
                    name: "Number of BreakDowns",
                    data: data.map((item) => item.BREAKDOWNS),
                  },
                ]}
                type="line" // Change chart type to "line"
                height={300}
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "20px",
                  marginTop: "50px",
                  textDecoration: "underline",
                }}
              >
                NO RESULTS FOUND
              </div>
            )}{" "}
          </>
        )}
      </div>
    </div>
  );
};

export default BreakDowns;
