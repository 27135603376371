import React from "react";

import { Link, useNavigate } from "react-router-dom";
import { logout } from "../../../Components/features/userSlice";
import { useDispatch } from "react-redux";
import Footer from "../../../Components/Footer";

function TransportDashboard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logoutOff = (e) => {
    e.preventDefault();
    dispatch(logout());
    navigate("/");
  };
  return (
    <div>
      <div
        className="Unapp_payment"
        style={{
          display: "flex",
          justifyContent: "space-between",
          position: "sticky",
          top: "0",
          left: "0",
          zIndex: "500",
        }}
      >
        <h1 style={{ textAlign: "center" }} className="Titles">
          Tech23 Dashboards
        </h1>
        <div className="nav_1">
          <div className="back_btn">
            {" "}
            <Link
              style={{ textDecoration: "none", color: "white" }}
              to={"/Dashboard1"}
            >
              <button className="View">Back</button>
            </Link>
            <button className="View" onClick={(e) => logoutOff(e)}>
              Logout
            </button>
          </div>
        </div>
      </div>
      <div className="h2">
        <h1>Transport Dashboards</h1>
      </div>

      <div className="dashboard">
        <div
          className="card"
          onClick={() => {
            navigate("/ListofVehicles");
          }}
        >
          <div className="card-content">
            <div className="card-title">List of Vehicles in Workshop</div>
          </div>
        </div>

        <div
          className="card"
          onClick={() => {
            navigate("/BreakDowns");
          }}
        >
          <div className="card-content">
            <div className="card-title">No of Breakdowns on Truck</div>
          </div>
        </div>

        <div
          className="card"
          onClick={() => {
            navigate("/AnalyticsBasedOnQuantity");
          }}
        >
          <div className="card-content">
            <div className="card-title">Transport Deliveries</div>
          </div>
        </div>

        <div
          className="card"
          onClick={() => {
            navigate("/VehicleExpense");
          }}
        >
          <div className="card-content">
            <div className="card-title">Transport Expenses</div>
          </div>
        </div>

        <div
          className="card"
          onClick={() => {
            navigate("/AnalyticsBasedOnClientAndItem");
          }}
        >
          <div className="card-content">
            <div className="card-title">Workshop Expense</div>
          </div>
        </div>

        <div
          className="card"
          onClick={() => {
            navigate("/AnalyticsBasedOnAllCombinations");
          }}
        >
          <div className="card-content">
            <div className="card-title">Purchases</div>
          </div>
        </div>
        <div
          className="card"
          onClick={() => {
            navigate("/AnalyticsBasedOnAllCombinations");
          }}
        >
          <div className="card-content">
            <div className="card-title">Journey Analysis</div>
          </div>
        </div>
        <div
          className="card"
          onClick={() => {
            navigate("/AnalyticsBasedOnAllCombinations");
          }}
        >
          <div className="card-content">
            <div className="card-title">Trucks Leaving Workshop</div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default TransportDashboard;
