import React from 'react';
import ScaleLoader from 'react-spinners/ScaleLoader';

function Loading2() {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '50vh',
            }}
        >
            <ScaleLoader
                className='Loaders'
                color=" #002D62"
            />
            <span style={{ color: '#002D62', fontSize: '18px', fontWeight: 'bold' }}>Loading...</span>
        </div>

    );
}

export default Loading2