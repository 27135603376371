import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import LoadingScreen from "../../Components/LoadingScreen";
import { useSelector } from "react-redux";
import { selectUser } from "../../Components/features/userSlice";
import Footer from "../../Components/Footer";
import "../../CSS/UnPettyCashVouchers.css";

const UnPtCVo = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const [unptcvo, setunptcvo] = useState([]);
  const [isloading, setisloading] = useState(true);
  const [currentPage, setcurrentPage] = useState(1);
  const recordPerPage = 10;
  const [filterAccount, setfilterAccount] = useState("");
  const [filterDocNo, setfilterDocNo] = useState("");
  const [filterFromdate, setfilterFromdate] = useState("");
  const [filterTodate, setfilterTodate] = useState("");
  const [savedDocno, setSavedDocno] = useState([]);
  const user = useSelector(selectUser);
  const email = user.email;
  const navigate = useNavigate();
  const authToken = window.sessionStorage.getItem("auth-token");
  const [numcount, setnumCount] = useState(true);
  useEffect(() => {
    getUnapprovedDetails();
  }, []);

  function Approve() {
    var errMsg = false;
    if (savedDocno.length === 0) {
      alert("Please select a payment to approve");
    } else {
      const alertConfirm = window.confirm("Are you sure you want to Approve");
      if (alertConfirm === true) {
        for (var i = 0; i < savedDocno.length; i++) {
          axios
            .post(
              `${BASEURL}/ApprovePettyCashVoucher`,
              {
                data: savedDocno[i],
                mail: email.split("@")[0].toUpperCase(),
              },
              {
                headers: {
                  "auth-token": authToken,
                },
              }
            )
            .then((response) => {
              if (axios.AxiosError) {
                errMsg = true;
              }
            });
        }
        if (errMsg) {
          alert("An error Occured");
        } else if (!errMsg) {
          alert("Approved Successfully");
        }
        window.location.reload();
      }
    }
  }

  function saveDocNo(docno) {
    setSavedDocno([...savedDocno, { docno: docno }]);
  }

  function handleView(value) {
    navigate("/ViewPettyCashVouchers", { state: { value } });
  }

  const getUnapprovedDetails = () => {
    axios
      .get(`${BASEURL}/UnPtCVo`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        setunptcvo(response?.data);
        setnumCount(false);
        setisloading(false);
      })
      .catch((err) => {
        setnumCount(false);
        setisloading(false);
        console.log("error while fetching unapproved petty Cash Vouchers", err);
      });
  };
  const lastIndex = currentPage * recordPerPage;

  const firstIndex = lastIndex - recordPerPage;

  if (isloading) {
    return <LoadingScreen />;
  }
  return (
    <div>
      <div
        className="Unapp_payment"
        style={{
          display: "flex",
          justifyContent: "space-between",
          position: "sticky",
          top: "0",
          left: "0",
          zIndex: "500",
        }}
      >
        <h1 style={{ textAlign: "center" }} className="Titles">
          Tech23 Dashboards
        </h1>
        <div className="back_btn">
          {" "}
          <Link
            style={{ textDecoration: "none", color: "white" }}
            to={"/HomePage"}
          >
            {" "}
            <div className="button_1">
              {/* <div className="Approve">
                <button
                  className="View"
                  style={{ width: "110px" }}
                  onClick={(e) => {
                    e.preventDefault();
                    Approve();
                  }}
                >
                  Approve
                </button>
              </div> */}
              <div>
                <button className="View">Back</button>{" "}
              </div>
            </div>
          </Link>
        </div>
      </div>

      <div className="media1_design">
        <div className="h2">
          <h2>Unapproved Petty Cash Vouchers</h2>
        </div>
        <div className="Vend_unp">
          <div className="Vendor">
            <div>
              <label>
                <b>Search By Account :</b>
              </label>
            </div>
            <div>
              <input
                className="input_1"
                type="text"
                id="vendor"
                onChange={(e) => setfilterAccount(e.target.value)}
              />
            </div>
          </div>
          <div className="Doc_No">
            <div>
              <label>
                <b>Search By DocNo :</b>
              </label>
            </div>
            <div>
              <input
                className="input_1"
                type="number"
                id="docno"
                style={{ width: "70px" }}
                onChange={(e) => setfilterDocNo(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="from_unp">
          <div className="from">
            <div>
              <label>
                <b>From Date:</b>
              </label>
            </div>
            <div>
              <input
                className="input_1"
                type="date"
                id="from"
                style={{ width: "115px" }}
                onChange={(e) => setfilterFromdate(e.target.value)}
              />
            </div>
          </div>
          <div className="to">
            <div>
              <label>
                <b>To Date:</b>
              </label>
            </div>
            <div>
              <input
                className="input_1"
                type="date"
                id="to"
                style={{ width: "115px" }}
                onChange={(e) => setfilterTodate(e.target.value)}
              />
            </div>
          </div>
          <div className="btns">
            <button
              className="button"
              onClick={() => {
                setfilterAccount("");
                setfilterDocNo("");
                setfilterFromdate("");
                setfilterTodate("");
                document.getElementById("vendor").value = "";
                document.getElementById("docno").value = "";
                document.getElementById("from").value = "";
                document.getElementById("to").value = "";
              }}
            >
              Clear
            </button>
            <button
              className="button"
              onClick={(e) => {
                e.preventDefault();
                Approve();
              }}
            >
              Approve
            </button>
          </div>
        </div>
      </div>

      <div className="scroll">
        <table
          style={{ textTransform: "uppercase" }}
          className="Payment_data_header"
        >
          <thead className="thead">
            {/* <tr className="Payment_tr">
              <th style={{ width: "20px" }}></th>
              <th style={{ paddingright: "40px", width: "100px" }}>DOC NO</th>
              <th style={{ paddingLeft: "18px" }}>DATE</th>
              <th style={{ textAlign: "left" }}>ACCOUNT</th>
              <th style={{ textAlign: "left", paddingLeft: "24px" }}>AMOUNT</th>
              <th style={{ textAlign: "left" }}>CURRENCY</th>
              <th style={{ width: "100px" }}>Action</th>
            </tr> */}
            <tr className="Payment_tr">
              <th style={{ width: "20px" }}></th>
              <th>DOC NO</th>
              <th className="header_petty_cash_voucher_date">DATE</th>
              <th>ACCOUNT</th>
              <th className="header_petty_cash_voucher_amount">AMOUNT</th>
              <th>CURRENCY</th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody className="Payments_tbody">
            {unptcvo
              .filter((item) => {
                return filterAccount.toLowerCase() === ""
                  ? item
                  : item?.ACCOUNT.toLowerCase().includes(filterAccount);
              })
              .filter((item) => {
                return filterDocNo.toString() === ""
                  ? item
                  : item?.DOCNO.toString().includes(filterDocNo);
              })
              .filter((date) => {
                if (filterFromdate && filterTodate) {
                  return (
                    date?.DATE >= filterFromdate && date?.DATE <= filterTodate
                  );
                } else if (filterFromdate) {
                  return date?.DATE >= filterFromdate;
                } else if (date <= filterTodate) {
                  return date?.DATE <= filterTodate;
                } else {
                  return date;
                }
              })
              .map((vo) => {
                return (
                  <tr className="Payments_tbody_tr" key={vo.DOCNO}>
                    <td style={{ padding: "15px 0px 0px 0px" }}>
                      <input
                        type="checkbox"
                        className="checkbox"
                        onChange={() => {
                          saveDocNo(vo?.DOCNO);
                        }}
                      ></input>
                    </td>
                    <td
                      className="td value_petty_cash_docno"
                      // style={{ textAlign: "right", paddingRight: "68px" }}
                    >
                      {vo.DOCNO ? vo.DOCNO : "N/A"}
                    </td>

                    <td
                      className="td value_petty_cash_date"
                      // style={{
                      //   textAlign: "center",
                      //   margin: "2px 2px",
                      //   padding: "20px 30px 20px 0px",
                      // }}
                    >
                      {vo.DATE ? moment(vo.DATE).format("DD/MM/YYYY") : "N/A"}
                    </td>

                    <td
                      className="td value_petty_cash_account"
                      // style={{
                      //   textAlign: "left",
                      //   padding: "0px 5px 0px 0px",
                      //   width: "251px",
                      // }}
                    >
                      {vo.ACCOUNT ? vo.ACCOUNT : "N/A"}
                    </td>

                    <td
                      className="td value_petty_cash_amount"
                      // style={{
                      //   textAlign: "right",
                      //   margin: "2px 2px",
                      //   padding: "0px 60px 0px 0px",
                      // }}
                    >
                      {vo.AMOUNT
                        ? vo.AMOUNT.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : "N/A"}
                    </td>
                    <td
                      className="td value_petty_cash_curr"
                      // style={{
                      //   textAlign: "center",
                      //   margin: "4px 4px",
                      //   padding: "0px 25px 0px 0px",
                      // }}
                    >
                      {vo.CURRENCY ? vo.CURRENCY : "N/A"}
                    </td>
                    <td style={{ paddingLeft: "53px" }}>
                      <button
                        className="button"
                        type="button"
                        onClick={() => {
                          handleView(vo?.DOCNO);
                        }}
                      >
                        View
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      {numcount ? (
        <div className="pagination">
          <div>
            <button
              style={
                currentPage === 1
                  ? { visibility: "hidden" }
                  : { visibility: "visible" }
              }
              className="Violation_btn"
              onClick={() => {
                setcurrentPage(Number(currentPage) - 1);

                if (currentPage <= 1) {
                  setcurrentPage(1);
                } else {
                  setcurrentPage(Number(currentPage) - 1);
                }
              }}
            >
              &lt;
            </button>
          </div>

          {/* <input
          className="enter_no"
          type="number"
          placeholder="Go-to Page"
          onChange={(e) => {
            setPage(e.target.value);
          }}
        ></input> */}

          {/* <button
          className="View"
          onClick={() => {
            if (page <= 0) {
              setcurrentPage(1);
            } else if (page > Math.ceil(Number(unps.length / 20))) {
              setcurrentPage(Math.ceil(Number(unps.length / 20)));
            } else {
              setcurrentPage(page);
            }
          }}
        >
          Go-to
        // </button> */}

          <div>
            <button
              style={
                currentPage === Math.ceil(Number(unptcvo.length / 20))
                  ? { visibility: "hidden" }
                  : { visibility: "visible" }
              }
              className="Violation_btn"
              onClick={() => {
                setcurrentPage(Number(currentPage) + 1);

                if (currentPage >= Math.ceil(Number(unptcvo.length / 20))) {
                  setcurrentPage(Math.ceil(Number(unptcvo.length / 20)));
                } else {
                  setcurrentPage(Number(currentPage) + 1);
                }
              }}
            >
              &gt;
            </button>
          </div>
        </div>
      ) : (
        <p></p>
      )}
      <Footer />
    </div>
  );
};

export default UnPtCVo;
