import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUser } from "../../Components/features/userSlice";
import LoadingScreen from "../../Components/LoadingScreen";
import moment from "moment";
import Footer from "../../Components/Footer";

const ViewJournals = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const [viewJournals, setViewJournals] = useState([]);
  const location = useLocation();
  const propValue = location.state.value;
  const user = useSelector(selectUser);
  const email = user.email;
  const navigate = useNavigate();
  let totalCRAmount = 0;
  const [isloading, setisloading] = useState(true);
  const authToken = window.sessionStorage.getItem("auth-token");

  viewJournals.forEach((pd) => {
    if (pd?.CRDR === "CR") {
      totalCRAmount += pd?.AMOUNT;
    }
  });

  function getViewDetails() {
    axios
      .post(
        `${BASEURL}/ViewJournals`,
        {
          docno: propValue,
        },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((res) => {
        setViewJournals(res?.data);
        setisloading(false);
      })
      .catch((err) => {
        setisloading(false);
        console.log("error while fetching View Journal Details", err);
      });
  }
  useEffect(() => {
    getViewDetails();
  }, []);

  function Approve() {
    const alertConfirm = window.confirm("Are you sure you want to Approve");
    if (alertConfirm === true) {
      axios
        .post(
          `${BASEURL}/ApproveJournals`,
          {
            data: { docno: propValue },
            mail: email.split("@")[0].toUpperCase(),
          },
          {
            headers: {
              "auth-token": authToken,
            },
          }
        )
        .then((response) => {
          alert("Approved Succesfully !");
          navigate("/UnapprovedJournals");
        });
      // window.location.reload();
    }
  }

  if (isloading) {
    return <LoadingScreen />;
  }
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          position: "sticky",
          top: "0",
          left: "0",
          zIndex: "500",
        }}
        className="Unapp_payment"
      >
        <h1 style={{ textAlign: "center" }} className="Titles">
          Tech23 Dashboards
        </h1>
        <div className="nav_1">
          {/* <div className="Approve_4">
            <button
              className="View"
              style={{ width: "110px" }}
              onClick={(e) => {
                e.preventDefault();
                Approve();
              }}
            >
              Approve
            </button>
          </div> */}
          <div className="back_btn">
            {" "}
            <Link
              style={{ textDecoration: "none", color: "white" }}
              to={"/UnapprovedJournals"}
            >
              <button className="View">Back</button>
            </Link>
          </div>
        </div>
      </div>

      <div>
        <div className="h2">
          <h2>Unapproved Journals</h2>
        </div>
        <div className="petty_cash_vouchers">
          <div>
            <label>DOCNO</label>
            <span> : </span>
            <label>
              {viewJournals[0]?.DOCNO ? viewJournals[0]?.DOCNO : ""}
            </label>
          </div>

          <div>
            <label>DATE</label>
            <span> : </span>
            <label>
              {viewJournals[0]?.PAYMENTDATE
                ? moment(viewJournals[0]?.PAYMENTDATE).format("DD/MM/YYYY")
                : ""}
            </label>
          </div>

          <div>
            <label>PROCESSED BY</label>
            <span> : </span>
            <label>
              {viewJournals[0]?.PROCESSEDBY ? viewJournals[0]?.PROCESSEDBY : ""}
            </label>
          </div>
        </div>

        <div className="Narration">
          <label>
            <span>NARRATION</span>
          </label>
          <span>:</span>
          <textarea className="Narration_1">
            {viewJournals[0]?.NARRATION ? viewJournals[0]?.NARRATION : ""}
          </textarea>

          <div>
            <button
              className="button_2"
              style={{ width: "110px" }}
              onClick={(e) => {
                e.preventDefault();
                Approve();
              }}
            >
              Approve
            </button>
          </div>
        </div>
      </div>

      {/* table */}
      <div className="scroll">
        <table
          style={{ textTransform: "uppercase" }}
          className="Payment_data_header"
        >
          <thead className="thead">
            <tr className="Payment_tr_1">
              {/* <th style={{ padding: "0px 10px" }}>DOC NO</th> */}
              {/* <th style={{}}>ACCOUNT CODE</th> */}
              <th style={{ paddingLeft: "10px" }}>ACCOUNT NAME</th>
              {/* <th style={{ textAlign: "left" }}>ACCOUNT TYPE</th> */}
              <th className="view_journals_cr_dr">DR/CR</th>
              <th className="view_journals_amount">AMOUNT</th>
              <th className="view_journals_curr">CURRENCY</th>
              <th className="view_journals_conv_rate">CONVERSION RATE</th>
            </tr>
          </thead>

          <tbody className="Payments_tbody">
            {viewJournals.map((pd) => {
              return (
                <tr className="Payments_tbody_tr" key={pd?.DOCNO}>
                  {/* <td className="td" style={{ paddingLeft: "55px" }}>
                    {pd.DOCNO}
                  </td>
                  <td className="td" style={{ paddingLeft: "55px" }}>
                    {pd.ACCOUNTCODE}
                  </td> */}

                  <td
                    style={{ paddingLeft: "10px" }}
                    className="td account_name"
                  >
                    {pd.ACCOUNTNAME ? pd.ACCOUNTNAME : "N/A"}
                  </td>

                  <td className="td value_journals_cr_dr">
                    {pd.CRDR ? pd.CRDR : "N/A"}
                  </td>
                  <td className="td value_journals_amount">
                    {pd.AMOUNT
                      ? pd.AMOUNT.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : "N/A"}
                  </td>
                  <td className="td value_journals_curr">
                    {pd.CURRENCY ? pd.CURRENCY : "N/A"}
                  </td>
                  <td className="td conversion_rate">
                    {pd.CONVRATE ? pd.CONVRATE : "N/A"}
                  </td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <td
                colSpan="2"
                style={{
                  textAlign: "right",
                  paddingRight: "35px",
                  paddingBlock: "15px",
                }}
              >
                Total CR Amount:
              </td>
              <td className="journal_view_total_cr_dr">
                {totalCRAmount
                  ? totalCRAmount.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : "N/A"}
              </td>
              <td colSpan="2"></td>
            </tr>
          </tfoot>
        </table>
      </div>
      <Footer />
    </div>
  );
};

export default ViewJournals;
