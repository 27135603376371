import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "./features/userSlice";
const TransportApprovalNavbar = () => {
  const user = useSelector(selectUser);
  const userEmail = user.email;
  return (
    <div>
      <div
        className="flex justify-between items-center bg-[#002d62] text-white "
        style={{
          position: "sticky",
          top: "0",
          left: "0",
          zIndex: 9999,
        }}
      >
        <h1 style={{ textAlign: "center" }} className="Titles">
          Tech23 Dashboards
        </h1>

        <div className="flex items-center gap-[50px]">
          <p className="">
            Logged in as : {userEmail.split("@")[0].toUpperCase()}
          </p>
          <div className="back_btn">
            {" "}
            <Link
              style={{ textDecoration: "none", color: "white" }}
              to={"/TransportDashboardUnapproved"}
            >
              <button className="View">Back</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransportApprovalNavbar;
