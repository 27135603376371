import React from "react";

import "./CSS/Dashboards.css";
import { useNavigate } from "react-router-dom";
import Navbar from "./Components/Navbar";
import { Link } from "react-router-dom";
// import Footer from "./Components/Footer";
import Transport from "./Images/transport.PNG";
import Trading from "./Images/trading.PNG";
import production from "./Images/production.png";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "./Components/features/userSlice";
import cnfapproval from "./Images/cnfapproval.PNG";

function Dashboard1() {
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const userEmail = user.email;
  return (
    <div>
      <div
        className="Unapp_payment"
        style={{
          display: "flex",
          justifyContent: "space-between",
          position: "sticky",
          alignItems: "center",
          top: "0",
          left: "0",
          zIndex: "500",
        }}
      >
        <h1 style={{ textAlign: "center" }} className="Titles">
          Tech23 Dashboards
        </h1>
        <div className="flex items-center gap-[50px]">
          <p className="text-white">
            Logged in as : {userEmail.split("@")[0].toUpperCase()}
          </p>
          <div className="back_btn">
            {" "}
            <Link
              style={{ textDecoration: "none", color: "white" }}
              to={"/Dashboards"}
            >
              <button className="View">Back</button>
            </Link>
          </div>
        </div>
      </div>

      <section className="for__main__dashboard">
        <div
          onClick={() => {
            navigate("/AnalyticsDashboard");
          }}
          className="align__img__and__heading"
        >
          <img src={Trading} height="150px" width="180px" alt="dashboard" />
          <h3>Trading</h3>
        </div>
        <div
          onClick={() => {
            navigate("/TransportDashboard1");
          }}
          className="align__img__and__heading"
        >
          <img src={Transport} height="150px" width="200px" alt="dashboard" />
          <h3>Transport</h3>
        </div>
        <div
          onClick={() => {
            navigate("/Production");
          }}
          className="align__img__and__heading"
        >
          <img src={production} height="150px" width="200px" alt="dashboard" />
          <h3>Production</h3>
        </div>
        <div
          onClick={() => {
            navigate("/CNF");
          }}
          className="align__img__and__heading"
        >
          <img src={cnfapproval} height="150px" width="200px" alt="dashboard" />
          <h3>CNF</h3>
        </div>
      </section>
    </div>
  );
}

export default Dashboard1;
