import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import "../..//CSS/UnJournals.css";
import LoadingScreen from "../../Components/LoadingScreen";
import { useSelector } from "react-redux";
import { selectUser } from "../../Components/features/userSlice";
import { ResizableBox } from "react-resizable";
import Modal from "react-modal";
import { BsInfoCircle } from "react-icons/bs";
import { IoIosCloseCircle } from "react-icons/io";
import Footer from "../../Components/Footer";
const mediaQueries = {
  mobile: "@media (max-width: 600px)",
};
// const customStyles = {
//   content: {
//     top: "50%",
//     left: "50%",
//     right: "auto",
//     bottom: "auto",
//     marginRight: "-50%",
//     transform: "translate(-50%, -50%)",
//     border: "solid 1px",

//     boxShadow:
//       "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset",
//     [mediaQueries.mobile]: {
//       position: "relative",
//     },
//   },
// };
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "solid 1px",
    // position: "initial",

    // Default boxShadow styles
    boxShadow:
      "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset",

    // Media query for mobile
    [mediaQueries.mobile]: {
      // position: "absolute",
      // Custom boxShadow styles for mobile
      boxShadow: "none",
    },

    // Media query for tablet
    [mediaQueries.tablet]: {
      // Custom styles for tablet
      /* ... */
    },

    // Media query for desktop
    [mediaQueries.desktop]: {
      // Custom styles for desktop
      /* ... */
    },
  },
};

// const responsiveCustomStyles = {
//   [mediaQueries.mobile]: {
//     content: {
//       position: "relative",
//     },
//   },
// };
// const finalCustomStyles = {
//   ...customStyles.content,
//   ...responsiveCustomStyles[mediaQueries.mobile],
// };

const UnJo = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const [unjo, setunjo] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const recordPerPage = 10;
  const [isloading, setisloading] = useState(true);
  const [savedDocno, setSavedDocno] = useState([]);
  const user = useSelector(selectUser);
  const email = user.email;
  // const [filterAccount, setfilterAccount] = useState("");
  const [filterDocNo, setfilterDocNo] = useState("");
  const [filterFromdate, setfilterFromdate] = useState("");
  const [filterTodate, setfilterTodate] = useState("");
  const [docData, setDocData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const authToken = window.sessionStorage.getItem("auth-token");
  const [numcount, setnumCount] = useState(true);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const ResizableModal = ({ isOpen, onRequestClose }) => {
    return (
      <div className="">
        <div className="Modal">
          <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Popup Window"
            style={customStyles}
          >
            <ResizableBox
              className=" style__width"
              // width={300}
              height={300}
              minConstraints={[100, 100]}
            >
              <div className="closed_c ircle">
                <IoIosCloseCircle size={40} onClick={onRequestClose} />
              </div>
              <div className="scroll">
                <table
                  style={{
                    textTransform: "uppercase",
                    position: "relative",
                  }}
                  className="Payment_data_header"
                >
                  <thead
                    className="thead "
                    style={{ position: "sticky", top: 0 }}
                  >
                    <tr className="Payment_tr">
                      <th
                        style={{
                          position: "sticky",
                          top: 0,
                        }}
                      >
                        Doc No
                      </th>
                      <th
                        style={{
                          paddingLeft: "24px",
                          position: "sticky",
                          top: 0,
                        }}
                      >
                        Date
                      </th>
                      <th
                        style={{
                          textAlign: "left",

                          position: "sticky",
                          top: 0,
                        }}
                      >
                        Account
                      </th>
                      <th
                        style={{
                          textAlign: "left",
                          paddingLeft: "50px",
                          position: "sticky",
                          top: 0,
                        }}
                      >
                        Amount
                      </th>
                      <th
                        style={{
                          textAlign: "left",
                          paddingLeft: "50px",
                          position: "sticky",
                          top: 0,
                        }}
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="Payments_tbody">
                    {docData.map((item) => (
                      <tr className="Payments_tbody_tr" key={item.DOCNO}>
                        <td className="td" style={{ paddingLeft: "27px" }}>
                          {item.DOCNO ? item.DOCNO : "N/A"}
                        </td>
                        <td
                          className="td"
                          style={{
                            textAlign: "center",
                            margin: "2px 2px",
                            padding: "20px 74px 20px 50px",
                          }}
                        >
                          {item.DATE
                            ? moment(item.DATE).format("DD/MM/YYYY")
                            : "N/A"}
                        </td>
                        <td
                          className="td"
                          style={{ width: "400px", paddingLeft: "18px" }}
                        >
                          {item.ACCOUNT ? item.ACCOUNT : "N/A"}
                        </td>
                        <td
                          className="td"
                          style={{
                            textAlign: "right",
                            margin: "2px 2px",
                            padding: "0px 33px 0px -1px",
                          }}
                        >
                          {item.AMOUNT
                            ? item.AMOUNT.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : "N/A"}
                        </td>
                        <td style={{ paddingLeft: "50px" }}>
                          <button
                            className="button"
                            type="button"
                            onClick={() => {
                              handleView(item?.DOCNO);
                            }}
                          >
                            View
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </ResizableBox>
          </Modal>
        </div>
      </div>
    );
  };

  useEffect(() => {
    getUnapprovedDetails();
  }, []);

  function saveDocNo(docno) {
    setSavedDocno([...savedDocno, { docno: docno }]);
  }

  function getDocNo(docno) {
    axios
      .post(
        `${BASEURL}/ViewDetailJournals`,
        {
          docno,
        },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((response) => {
        setDocData(response?.data);
      })
      .catch((err) => {
        console.log("error While fetching Detailed Journals", err);
      });
  }

  function Approve() {
    var errMsg = false;
    if (savedDocno.length === 0) {
      alert("Please select a Journal to approve");
    } else {
      const alertConfirm = window.confirm("Are you sure you want to Approve");
      if (alertConfirm === true) {
        for (var i = 0; i < savedDocno.length; i++) {
          axios
            .post(
              `${BASEURL}/ApproveJournals`,
              {
                data: savedDocno[i],
                mail: email.split("@")[0].toUpperCase(),
              },
              {
                headers: {
                  "auth-token": authToken,
                },
              }
            )
            .then((response) => {
              if (axios.AxiosError) {
                errMsg = true;
              }
            });
        }

        if (errMsg) {
          alert("An error Occured");
        } else if (!errMsg) {
          alert("Approved Successfully");
        }
        window.location.reload();
      }
    }
  }

  const getUnapprovedDetails = () => {
    axios
      .get(
        `${BASEURL}/UnJo`,

        {
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((response) => {
        setunjo(response?.data);
        setnumCount(false);
        setisloading(false);
      })
      .catch((err) => {
        setisloading(false);
        console.log("error While fetching Details of Unapproved Journals", err);
      });
  };
  const lastIndex = currentPage * recordPerPage;

  const firstIndex = lastIndex - recordPerPage;

  const records = unjo?.slice(firstIndex, lastIndex);

  function handleView(value) {
    navigate("/ViewJournals", { state: { value } });
  }

  if (isloading) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <div
        className="Unapp_payment"
        style={{
          display: "flex",
          justifyContent: "space-between",
          position: "sticky",
          top: "0",
          left: "0",
          zIndex: "500",
        }}
      >
        <h1 style={{ textAlign: "center" }} className="Titles">
          MarsTrack Dashboards
        </h1>
        <div className="nav_1">
          <div className="back_btn">
            {" "}
            <Link
              style={{ textDecoration: "none", color: "white" }}
              to={"/HomePage"}
            >
              <button className="View">Back</button>
            </Link>
          </div>
        </div>
      </div>

      {/* style={{ position: "relative" }} */}
      <div className="h2">
        <h2>Unapproved Journals</h2>
      </div>
      <div className="Filter">
        {/* style={{ position: "relative" }} */}
        {/* <div className="Vendor">
            <div>
              <label>Search by Account:</label>
            </div>
            <div>
              <input
                type="text"
                id="vendor"
                onChange={(e) => setfilterAccount(e.target.value)}
              />
            </div>
          </div> */}
        <div className="Doc_No_1">
          <div>
            <label>
              <b>Search By Documnet No :</b>
            </label>
          </div>
          <div>
            <input
              className="input_1"
              type="number"
              id="docno"
              style={{ width: "70px" }}
              onChange={(e) => setfilterDocNo(e.target.value)}
            />
          </div>
        </div>
        <div className="from_1">
          <div>
            <label>
              <b>From Date:</b>
            </label>
          </div>
          <div>
            <input
              className="input_1"
              type="date"
              id="from"
              style={{ width: "115px" }}
              onChange={(e) => setfilterFromdate(e.target.value)}
            />
          </div>
        </div>
        <div className="to_1">
          <div>
            <label>
              <b>To Date:</b>
            </label>
          </div>
          <div>
            <input
              className="input_1"
              type="date"
              id="to"
              style={{ width: "115px" }}
              onChange={(e) => setfilterTodate(e.target.value)}
            />
          </div>
        </div>
        <div>
          <button
            className="button"
            onClick={() => {
              // setfilterAccount("");
              setfilterDocNo("");
              setfilterFromdate("");
              setfilterTodate("");
              // document.getElementById("vendor").value = "";
              document.getElementById("docno").value = "";
              document.getElementById("from").value = "";
              document.getElementById("to").value = "";
            }}
          >
            Clear
          </button>
          <button
            className="button"
            onClick={(e) => {
              e.preventDefault();
              Approve();
            }}
          >
            Approve
          </button>
        </div>
      </div>
      <div className="scroll">
        <table
          style={{ textTransform: "uppercase" }}
          className="Payment_data_header"
        >
          <thead className="thead">
            {/* style={{ position: "sticky", top: 0 }} */}
            <tr className="Payment_tr">
              <th style={{ width: "20px" }}></th>
              <th style={{ padding: "0px 10px" }}>VOUCHER CODE</th>
              <th style={{ padding: "0px 46px" }}>DOC NO</th>
              <th style={{ paddingLeft: "40px" }}>DATE</th>
              <th
                style={{
                  textAlign: "left",
                  paddingLeft: "50px",
                }}
              >
                AMOUNT
              </th>
              <th
                style={{
                  textAlign: "left",
                  paddingLeft: "20px",
                }}
              >
                CURRENCY
              </th>
              <th
                style={{
                  textAlign: "left",
                  paddingLeft: "60px",
                }}
              >
                View
              </th>
              {/* <th style={{ width: "100px" }}>Action</th> */}
            </tr>
          </thead>

          <tbody className="Payments_tbody">
            {records
              // .filter((item) => {
              //   return filterAccount.toLowerCase() === ""
              //     ? item
              //     : item.ACCOUNT.toLowerCase().includes(filterAccount);
              // })
              .filter((item) => {
                return filterDocNo.toString() === ""
                  ? item
                  : item?.DOCNO.toString().includes(filterDocNo);
              })
              .filter((date) => {
                if (filterFromdate && filterTodate) {
                  return (
                    date?.DATE >= filterFromdate && date?.DATE <= filterTodate
                  );
                } else if (filterFromdate) {
                  return date?.DATE >= filterFromdate;
                } else if (date <= filterTodate) {
                  return date?.DATE <= filterTodate;
                } else {
                  return date;
                }
              })
              .map((jo) => {
                return (
                  <tr className="Payments_tbody_tr" key={jo.VOUCHERCODE}>
                    <td style={{ padding: "15px 0px 0px 0px" }}>
                      <input
                        type="checkbox"
                        className="checkbox"
                        onChange={() => {
                          saveDocNo(jo?.DOCNO);
                        }}
                      ></input>
                    </td>
                    <td
                      className="td"
                      style={{
                        margin: "2px 2px",

                        textAlign: "center",
                      }}
                    >
                      {jo.VCODE ? jo.VCODE : "N/A"}
                    </td>

                    <td className="td" style={{ paddingLeft: "70px" }}>
                      {jo.DOCNO ? jo.DOCNO : "N/A"}
                    </td>
                    <td
                      className="td"
                      style={{
                        textAlign: "center",
                        margin: "2px 2px",
                        padding: "20px 20px 20px 0px",
                      }}
                    >
                      {jo.DATE ? moment(jo.DATE).format("DD/MM/YYYY") : "N/A"}
                    </td>
                    <td
                      className="td"
                      style={{
                        textAlign: "right",
                        margin: "2px 2px",
                      }}
                    >
                      {jo.AMOUNT
                        ? jo.AMOUNT.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : "N/A"}
                    </td>

                    <td
                      className="td"
                      style={{
                        textAlign: "center",
                        margin: "4px 4px",
                      }}
                    >
                      {jo.CURRENCY ? jo.CURRENCY : "N/A"}
                    </td>
                    <td>
                      <div className="app">
                        <BsInfoCircle
                          size={30}
                          onClick={(e) => {
                            getDocNo(jo.DOCNO);
                            openModal();
                          }}
                        />
                        <div>
                          <ResizableModal
                            isOpen={isModalOpen}
                            onRequestClose={closeModal}
                          />
                        </div>
                      </div>
                    </td>
                    {/* <td>
                      <button className="button" type="button">
                        View
                      </button>
                    </td> */}
                  </tr>
                );
              })}
          </tbody>
        </table>
        {numcount ? (
          <div className="pagination">
            <div>
              <button
                style={
                  currentPage === 1
                    ? { visibility: "hidden" }
                    : { visibility: "visible" }
                }
                className="Violation_btn"
                onClick={() => {
                  setcurrentPage(Number(currentPage) - 1);

                  if (currentPage <= 1) {
                    setcurrentPage(1);
                  } else {
                    setcurrentPage(Number(currentPage) - 1);
                  }
                }}
              >
                &lt;
              </button>
            </div>

            {/* <input
          className="enter_no"
          type="number"
          placeholder="Go-to Page"
          onChange={(e) => {
            setPage(e.target.value);
          }}
        ></input> */}

            {/* <button
          className="View"
          onClick={() => {
            if (page <= 0) {
              setcurrentPage(1);
            } else if (page > Math.ceil(Number(unps.length / 20))) {
              setcurrentPage(Math.ceil(Number(unps.length / 20)));
            } else {
              setcurrentPage(page);
            }
          }}
        >
          Go-to
        // </button> */}

            <div>
              <button
                style={
                  currentPage === Math.ceil(Number(unjo.length / 20))
                    ? { visibility: "hidden" }
                    : { visibility: "visible" }
                }
                className="Violation_btn"
                onClick={() => {
                  setcurrentPage(Number(currentPage) + 1);

                  if (currentPage >= Math.ceil(Number(unjo.length / 20))) {
                    setcurrentPage(Math.ceil(Number(unjo.length / 20)));
                  } else {
                    setcurrentPage(Number(currentPage) + 1);
                  }
                }}
              >
                &gt;
              </button>
            </div>
          </div>
        ) : (
          <p></p>
        )}
      </div>

      <Footer />
    </div>
  );
};

export default UnJo;
