import { Link, useNavigate } from "react-router-dom";
import { logout } from "../../../../Components/features/userSlice";
import { useDispatch } from "react-redux";
import Footer from "../../../../Components/Footer";
import React, { useState } from "react";
import Select from "react-select";
import MonthwiseSales from "./MonthwiseSales";
import ClientwiseSales from "./ClientwiseSales";
import MonthwiseSalesforSelectedClient from "./MonthwiseSalesforSelectedClient";
import DivisionwiseSales from "./DivisionwiseSales";
import CategoryWiseSales from "./CategoryWiseSales";
import SubCategoryWiseSales from "./SubCategoryWiseSales";
import CategoryWiseSalesforSelectedCustomer from "./CategoryWiseSalesforSelectedCustomer";

//
import AnalyticsBasedOnAllCombinations from "./AnalyticsBasedOnAllCombinations";
import AnalyticsBasedOnClientAndCategory from "./AnalyticsBasedOnClientAndCategory";
import AnalyticsBasedOnClientAndItem from "./AnalyticsBasedOnClientAndItem";
import AnalyticsBasedOnItem from "./AnalyticsBasedOnItem";
import AnalyticsBasedOnQuantity from "./AnalyticsBasedOnQuantity";
import AnalyticsBasedonClientName from "./AnalyticsBasedonClientName";
import { useSelector } from "react-redux";
import { selectUser } from "../../../../Components/features/userSlice";
function SalesDashboard() {
  const user = useSelector(selectUser);
  const userEmail = user.email;
  const [selectedComponent, setSelectedComponent] = useState({
    value: "MonthwiseSales",
    label: "Month wise Sales",
  });
  const options = [
    {
      value: "MonthwiseSales",
      label: "Month wise Sales",
    },
    {
      value: "ClientwiseSales",
      label: "Client wise Sales",
    },
    {
      value: "MonthwiseSalesforSelectedClient",
      label: "Month wise Sales for Selected Client",
    },
    {
      value: "DivisionwiseSales",
      label: "Division wise Sales",
    },
    {
      value: "CategoryWiseSales",
      label: "Category Wise Sales",
    },
    {
      value: "SubCategoryWiseSales",
      label: "Sub Category Wise Sales",
    },
    {
      value: "CategoryWiseSalesforSelectedCustomer",
      label: "Category Wise Sales for Selected Customer",
    },
    // {
    //   value: "AnalyticsBasedOnClientAndCategory",
    //   label: "Analytics Based On Client And Category",
    // },
    // {
    //   value: "AnalyticsBasedOnClientAndItem",
    //   label: "Analytics Based On Client And Item",
    // },
    // { value: "AnalyticsBasedOnItem", label: "Analytics Based On Item" },
    // { value: "AnalyticsBasedOnQuantity", label: "Analytics Based On Quantity" },
    // {
    //   value: "AnalyticsBasedonClientName",
    //   label: "Analytics Based on Client Name",
    // },
  ];

  const handleChange = (selectedOption) => {
    setSelectedComponent(selectedOption);
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logoutOff = (e) => {
    e.preventDefault();
    dispatch(logout());
    navigate("/");
  };
  return (
    <div>
      <div
        className="bg-[#28405b] text-white shadow-l border-b-[1px] border-slate-400 py-1 mb-2"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          position: "sticky",
          top: "0",
          left: "0",
          zIndex: "500",
        }}
      >
        <h1 className="px-2 text-2xl  text-center">Tech23 Dashboards</h1>

        <div className="flex items-center gap-[50px]">
          <p className="text-white">
            Logged in as : {userEmail.split("@")[0].toUpperCase()}
          </p>
          <div className="back_btn">
            {" "}
            <Link
              style={{ textDecoration: "none", color: "white" }}
              to={"/AnalyticsDashboard"}
            >
              <button className="py-1 rounded bg-white  text-black w-[80px]">
                Back
              </button>
            </Link>
          </div>
        </div>
      </div>

      <header className="header__of__main lg:flex justify-between mx-2 items-end lg:px-3">
        <h1 className="header__of__page ">Sales Dashboard</h1>
        <div className="">
          <div className="lg:w-[400px] lg:mx-2 " style={{ zIndex: 999 }}>
            <Select
              value={selectedComponent}
              onChange={handleChange}
              options={options}
              placeholder="Select Component"
            />
          </div>
        </div>
      </header>

      <div>
        {selectedComponent && selectedComponent.value === "MonthwiseSales" && (
          <MonthwiseSales />
        )}
        {selectedComponent && selectedComponent.value === "ClientwiseSales" && (
          <ClientwiseSales />
        )}
        {selectedComponent &&
          selectedComponent.value === "MonthwiseSalesforSelectedClient" && (
            <MonthwiseSalesforSelectedClient />
          )}
        {selectedComponent &&
          selectedComponent.value === "DivisionwiseSales" && (
            <DivisionwiseSales />
          )}
        {selectedComponent &&
          selectedComponent.value === "CategoryWiseSales" && (
            <CategoryWiseSales />
          )}
        {selectedComponent &&
          selectedComponent.value === "SubCategoryWiseSales" && (
            <SubCategoryWiseSales />
          )}
        {selectedComponent &&
          selectedComponent.value ===
            "CategoryWiseSalesforSelectedCustomer" && (
            <CategoryWiseSalesforSelectedCustomer />
          )}
        {/* {selectedComponent &&
          selectedComponent.value === "AnalyticsBasedOnAllCombinations" && (
            <AnalyticsBasedOnAllCombinations />
          )}
        {selectedComponent &&
          selectedComponent.value === "AnalyticsBasedOnClientAndCategory" && (
            <AnalyticsBasedOnClientAndCategory />
          )}
        {selectedComponent &&
          selectedComponent.value === "AnalyticsBasedOnClientAndItem" && (
            <AnalyticsBasedOnClientAndItem />
          )}
        {selectedComponent &&
          selectedComponent.value === "AnalyticsBasedOnItem" && (
            <AnalyticsBasedOnItem />
          )}
        {selectedComponent &&
          selectedComponent.value === "AnalyticsBasedOnQuantity" && (
            <AnalyticsBasedOnQuantity />
          )}
        {selectedComponent &&
          selectedComponent.value === "AnalyticsBasedonClientName" && (
            <AnalyticsBasedonClientName />
          )} */}
      </div>
      {/* <div className="dashboard">
        <div
          className="card"
          onClick={() => {
            navigate("/AnalyticsBasedonClientName");
          }}
        >
          <div className="card-content">
            <div className="card-title"> Report By Client Name</div>
          </div>
        </div>

        <div
          className="card"
          onClick={() => {
            navigate("/AnalyticsBasedOnItem");
          }}
        >
          <div className="card-content">
            <div className="card-title">Report By Item</div>
          </div>
        </div>

        <div
          className="card"
          onClick={() => {
            navigate("/AnalyticsBasedOnQuantity");
          }}
        >
          <div className="card-content">
            <div className="card-title"> Report By Quantity Sold</div>
          </div>
        </div>

        <div
          className="card"
          onClick={() => {
            navigate("/AnalyticsBasedOnClientAndCategory");
          }}
        >
          <div className="card-content">
            <div className="card-title">
              Report Based on Client and Category
            </div>
          </div>
        </div>

        <div
          className="card"
          onClick={() => {
            navigate("/AnalyticsBasedOnClientAndItem");
          }}
        >
          <div className="card-content">
            <div className="card-title">Report Based on Client and Item</div>
          </div>
        </div>

        <div
          className="card"
          onClick={() => {
            navigate("/AnalyticsBasedOnAllCombinations");
          }}
        >
          <div className="card-content">
            <div className="card-title">
              Report Based on Client, Item, Category & Subcategory
            </div>
          </div>
        </div>
      </div> */}
      {/* <Footer /> */}
    </div>
  );
}

export default SalesDashboard;
