import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import "../../../../CSS/AnalyticsBasedOnPaymentmode.css";

import Select from "react-select";
import axios from "axios";
import moment from "moment";
import ReactApexChart from "react-apexcharts";
import LoadingScreen from "../../../../Components/LoadingScreen";

import "../../../../CSS/ListofVehicles.css";
import { useNavigate } from "react-router-dom";
import TransportDashboardsNavbar from "../../../../Components/TransportDashboardsNavbar";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../../../Components/features/userSlice";
import ClientWisePNLForSelectedClient from "./ClientWisePNLForSelectedClient";
import ClientWisePNL from "./ClientWisePNL";
import ExpenseTypeAnalysisMonthwiseBreakdown from "./ExpenseTypeAnalysisMonthwiseBreakdown";
import ExpenseTypeAnalysisSummarized from "./ExpenseTypeAnalysisSummarized";
import RouteWisePNL from "./RouteWisePNL";
import RouteWisePNLForSelectedRoute from "./RouteWisePNLForSelectedRoute";
import DestinationWisePNL from "./DestinationWisePNL";
import DestinationWisePNLforSelectedDestination from "./DestinationWisePNLforSelectedDestination";

const IncomeAndExpenses = () => {
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const userEmail = user.email;
  const [selectedComponent, setSelectedComponent] = useState({
    value: "ClientWisePNLForSelectedClient",
    label: "Client Wise PNL For Selected Client",
  });
  const options = [
    {
      value: "ClientWisePNLForSelectedClient",
      label: "Client Wise PNL For Selected Client",
    },
    {
      value: "ClientWisePNL",
      label: "Client Wise PNL",
    },
    {
      value: "ExpenseTypeAnalysisMonthwiseBreakdown",
      label: "Expense Type Analysis - Monthwise Breakdown",
    },
    {
      value: "ExpenseTypeAnalysisSummarized",
      label: "Expense Type Analysis - Summarized",
    },
    {
      value: "RouteWisePNL",
      label: "Route Wise PNL",
    },
    {
      value: "RouteWisePNLForSelectedRoute",
      label: "Route Wise PNL For Selected Route",
    },
    {
      value: "DestinationWisePNL",
      label: "Destination Wise PNL",
    },
    {
      value: "DestinationWisePNLforSelectedDestination",
      label: "Destination Wise PNL for Selected Destination",
    },
  ];

  const handleChange = (selectedOption) => {
    setSelectedComponent(selectedOption);
  };

  const [isloading, setisloading] = useState(true);

  useEffect(() => {
    setisloading(false);
  }, []);

  if (isloading) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <div
        className="Unapp_payment"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",

          position: "sticky",
          top: "0",
          left: "0",
          zIndex: 9999,
        }}
      >
        <h1 style={{ textAlign: "center" }} className="Titles">
          Tech23 Dashboards
        </h1>
        <div className="flex items-center gap-[50px]">
          <p className="text-white">
            Logged in as : {userEmail.split("@")[0].toUpperCase()}
          </p>
          <div className="back_btn">
            {" "}
            <Link
              style={{ textDecoration: "none", color: "white" }}
              to={"/CNF"}
            >
              <button className="View">Back</button>
            </Link>
          </div>
        </div>
      </div>

      <header className="header__of__main__dashboard flex justify-between items-end px-3">
        <h2 className="header__of__page">Income And Expenses</h2>

        <div className="w-[400px] mx-2 " style={{ zIndex: 999 }}>
          <Select
            value={selectedComponent}
            onChange={handleChange}
            options={options}
            placeholder="Select Component"
          />
        </div>
      </header>

      <div>
        {selectedComponent &&
          selectedComponent.value === "ClientWisePNLForSelectedClient" && (
            <ClientWisePNLForSelectedClient />
          )}
        {selectedComponent && selectedComponent.value === "ClientWisePNL" && (
          <ClientWisePNL />
        )}
        {selectedComponent &&
          selectedComponent.value ===
            "ExpenseTypeAnalysisMonthwiseBreakdown" && (
            <ExpenseTypeAnalysisMonthwiseBreakdown />
          )}
        {selectedComponent &&
          selectedComponent.value === "ExpenseTypeAnalysisSummarized" && (
            <ExpenseTypeAnalysisSummarized />
          )}
        {selectedComponent && selectedComponent.value === "RouteWisePNL" && (
          <RouteWisePNL />
        )}
        {selectedComponent &&
          selectedComponent.value === "RouteWisePNLForSelectedRoute" && (
            <RouteWisePNLForSelectedRoute />
          )}
        {selectedComponent &&
          selectedComponent.value === "DestinationWisePNL" && (
            <DestinationWisePNL />
          )}
        {selectedComponent &&
          selectedComponent.value ===
            "DestinationWisePNLforSelectedDestination" && (
            <DestinationWisePNLforSelectedDestination />
          )}
      </div>
    </div>
  );
};

export default IncomeAndExpenses;
