import React from "react";
import { Link } from "react-router-dom";
import "../../CSS/HomePage.css";
import { logout } from "../../Components/features/userSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Footer from "../../Components/Footer";

function Home() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logoutOff = (e) => {
    e.preventDefault();
    dispatch(logout());
    navigate("/");
  };

  return (
    <div>
      <div
        className="Unapp_payment"
        style={{
          display: "flex",
          justifyContent: "space-between",
          position: "sticky",
          top: "0",
          left: "0",
          zIndex: "500",
        }}
      >
        <h1 style={{ textAlign: "center" }} className="Titles">
          Tech23 Dashboards
        </h1>
        <div className="nav_1">
          <div className="back_btn">
            {" "}
            <Link
              style={{ textDecoration: "none", color: "white" }}
              to={"/Dashboard2"}
            >
              <button className="View">Back</button>
            </Link>
            <button className="View" onClick={(e) => logoutOff(e)}>
              Logout
            </button>
          </div>
        </div>
      </div>
      <div className="h2">
        <h2>Welcome To Trading Approval Platform</h2>
      </div>
      {/* <div style={{ display: "flex", marginLeft: "300px" }}>
        <hr
          style={{ height: "10px", backgroundColor: "black", width: "100px" }}
        ></hr>
        <hr
          style={{ height: "2px", backgroundColor: "black", width: "500px" }}
        ></hr>
      </div> */}
      {/* <div className="btn_link">
        <Link className="buttons" to={"/UnapprovedCreditNotes"} >
          Unapproved Credit notes
        </Link> */}
      <div className="btn_link">
        {/* <Link className="buttons">Unapproved Credit notes</Link> */}

        <Link className="buttons" to={"/UnapprovedPurchaseOrders"}>
          Unapproved Purchase Orders
        </Link>

        <Link className="buttons" to={"/UnapprovedPettyCashVouchers"}>
          Unapproved Petty Cash Vouchers
        </Link>

        <Link className="buttons" to={"/UnapprovedJournals"}>
          Unapproved Journals
        </Link>

        <Link className="buttons" to={"/UnapprovedPayments"}>
          Unapproved Payments
        </Link>
      </div>
      <Footer />
    </div>
  );
}
export default Home;
