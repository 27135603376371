import React from "react";
import { useNavigate } from "react-router-dom";

// import Footer from "./Components/Footer";

import workshop from "../../../Images/workshop.PNG";
import fuel from "../../../Images/fuel.PNG";
import Breakdowns from "../../../Images/Breakdowns.PNG";
import trip from "../../../Images/trip.PNG";
import costing from "../../../Images/costing.PNG";
import inventory from "../../../Images/inventory.PNG";

import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../../Components/features/userSlice";

function Production() {
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const userEmail = user.email;
  return (
    <div>
      <div
        className="Unapp_payment"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",

          position: "sticky",
          top: "0",
          left: "0",
          zIndex: "500",
        }}
      >
        <h1 style={{ textAlign: "center" }} className="Titles">
          Tech23 Dashboards
        </h1>
        <div className="flex items-center gap-[50px]">
          <p className="text-white">
            Logged in as : {userEmail.split("@")[0].toUpperCase()}
          </p>
          <div className="back_btn">
            {" "}
            <Link
              style={{ textDecoration: "none", color: "white" }}
              to={"/Dashboard1"}
            >
              <button className="View">Back</button>
            </Link>
          </div>
        </div>
      </div>
      <div className="h2">
        <h2>Welcome to Production Dashboards</h2>
      </div>

      <section
        className="for__main__dashboard flex flex-wrap "
        style={{ fontSize: "12px" }}
      >
        <div
          onClick={() => {
            navigate("/ProductionCost");
          }}
          className="align__img__and__heading"
        >
          <img src={workshop} height="150px" width="180px" alt="dashboard" />
          <h3>Production Cost</h3>
        </div>
        <div
          onClick={() => {
            navigate("/ProductionQuantities");
          }}
          className="align__img__and__heading"
        >
          <img src={costing} height="150px" width="180px" alt="dashboard" />
          <h3>Production Quantities </h3>
        </div>
      </section>
    </div>
  );
}

export default Production;
