import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../../CSS/AnalyticsBasedOnPaymentmode.css";
import Select from "react-select";
import axios from "axios";
import moment from "moment";
import ReactApexChart from "react-apexcharts";
import LoadingScreen from "../../../Components/LoadingScreen";
import Loading2 from "../../../Components/Loading2";
import "../../../CSS/ListofVehicles.css";
import { useNavigate } from "react-router-dom";
import MonthWisePurchaseReport from "./Inventory/MonthWisePurchaseReport";
import PurchasesPeriodWide from "./Inventory/PurchasesPeriodWide";
import PurchasesPurchaseTypewise from "./Inventory/PurchasesPurchaseTypewise";
import PurchasesSelectedSupplierPeriodWise from "./Inventory/PurchasesSelectedSupplierPeriodWise";
import PurchasesSupplierwisePeriodwise from "./Inventory/PurchasesSupplierwisePeriodwise";
import TransportDashboardsNavbar from "../../../Components/TransportDashboardsNavbar";
const InventoryAnalytics = () => {
  const [selectedComponent, setSelectedComponent] = useState({
    value: "PurchasesPeriodWide",
    label: "Purchases Period Wise",
  });
  const options = [
    {
      value: "PurchasesPeriodWide",
      label: "Purchases Period Wise",
    },
    {
      value: "PurchasesPurchaseTypewise",
      label: "Purchases Purchase Type wise",
    },
    {
      value: "PurchasesSupplierwisePeriodwise",
      label: "Purchases Supplier wise Period wise",
    },
    {
      value: "MonthWisePurchaseReport",
      label: "Month Wise Purchase Report",
    },
    {
      value: "PurchasesSelectedSupplierPeriodWise",
      label: "Purchases Selected Supplier Period Wise",
    },
  ];

  const handleChange = (selectedOption) => {
    setSelectedComponent(selectedOption);
  };
  const [isloading, setisloading] = useState(true);

  useEffect(() => {
    setisloading(false);
  }, []);

  if (isloading) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <TransportDashboardsNavbar />
      {/* <div
        className="Unapp_payment"
        style={{
          display: "flex",
          justifyContent: "space-between",
          position: "sticky",
          top: "0",
          left: "0",
          zIndex: 9999,
        }}
      >
        <h1 style={{ textAlign: "center" }} className="Titles">
          Tech23 Dashboards
        </h1>
        <div className="nav_1">
          <div className="back_btn">
            {" "}
            <Link
              style={{ textDecoration: "none", color: "white" }}
              to={"/TransportDashboard1"}
            >
              <button className="View">Back</button>
            </Link>
          </div>
        </div>
      </div> */}

      <header className="header__of__main__dashboard flex justify-between items-end px-3">
        <h2 className="header__of__page">Inventory Analytics</h2>

        <div className="w-[400px] mx-2 " style={{ zIndex: 999 }}>
          <Select
            value={selectedComponent}
            onChange={handleChange}
            options={options}
            placeholder="Select Component"
          />
        </div>
      </header>

      <div>
        {selectedComponent &&
          selectedComponent.value === "PurchasesPeriodWide" && (
            <PurchasesPeriodWide />
          )}
        {selectedComponent &&
          selectedComponent.value === "PurchasesPurchaseTypewise" && (
            <PurchasesPurchaseTypewise />
          )}
        {selectedComponent &&
          selectedComponent.value === "MonthWisePurchaseReport" && (
            <MonthWisePurchaseReport />
          )}
        {selectedComponent &&
          selectedComponent.value === "PurchasesSelectedSupplierPeriodWise" && (
            <PurchasesSelectedSupplierPeriodWise />
          )}

        {selectedComponent &&
          selectedComponent.value === "PurchasesSupplierwisePeriodwise" && (
            <PurchasesSupplierwisePeriodwise />
          )}
      </div>
    </div>
  );
};

export default InventoryAnalytics;
