import React, { useEffect, useState } from "react";
import Select from "react-select";
import "../../../../CSS/ItemSelectionWindow.css";
import { Link } from "react-router-dom";
import LoadingScreen from "../../../../Components/LoadingScreen";
import Loading2 from "../../../../Components/Loading2";
import axios from "axios";
import moment from "moment";
import ExcelJS from "exceljs";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { BsFileEarmarkPdf } from "react-icons/bs";
import { RiFileExcel2Line } from "react-icons/ri";
import Footer from "../../../../Components/Footer";
import { TbArrowsSort } from "react-icons/tb";

function ItemSelectionWindow() {
  const [fromdate, setFromdate] = useState(
    new Date(new Date().getFullYear(), 0, 2).toISOString()
  );
  const [todate, setTodate] = useState(new Date().toISOString());
  const [pstartdate, setPstartdate] = useState(
    new Date(new Date().getFullYear(), 0, 2).toISOString()
  );
  const [penddate, setPenddate] = useState(new Date().toISOString());
  const [sstartdate, setSstartdate] = useState(
    new Date(new Date().getFullYear(), 0, 2).toISOString()
  );
  const [senddate, setSenddate] = useState(new Date().toISOString());
  var [mainstockdata, setMainStockData] = useState([]);
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const [isloading, setisloading] = useState(true);
  const [isStockActivityVisible, setIsStockActivityVisible] = useState(false);
  const [isPurchaseHistoryVisible, setIsPurchaseHistoryVisible] =
    useState(false);
  const [isSaleshistoryVisible, setisSalesHistoryVisible] = useState(false);
  const [itemcode, setItemcode] = useState("");
  const [itemname, setItemname] = useState("");
  const [sellingprice, setSellingPrice] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [saleshistorydata, setSalesHistoryData] = useState([]);
  const [purchasehistorydata, setPurchaseHistoryData] = useState([]);
  const [stockactivitydata, setStockactivitydata] = useState([]);
  const [isLoading2, setLoading2] = useState(true);
  const [searchsh, setSearchSH] = useState("");
  const [searchph, setSearchPH] = useState("");
  const [searchsa, setSearchSA] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const [activeButton, setActiveButton] = useState(null);
  const [location, setLocation] = useState([]);
  const [sellocation, setSelLocation] = useState("");
  const [selsalocation, setSelsalocation] = useState("");
  const [sortByDateAscending, setSortByDateAscending] = useState(true);
  const [openbal, setOpenbal] = useState(0);
  let tqsh = 0;
  let tash = 0;
  let tssh = 0;
  let tqph = 0;
  let taph = 0;
  let tpph = 0;

  saleshistorydata.forEach((sh) => {
    tssh += sh.QUANITY * sh.AMOUNT_INC_VAT;
  });

  saleshistorydata.forEach((sh) => {
    tash += sh.AMOUNT_INC_VAT;
  });

  saleshistorydata.forEach((sh) => {
    tqsh += sh.QUANITY;
  });

  purchasehistorydata.forEach((ph) => {
    tqph += ph.QTY;
  });

  purchasehistorydata.forEach((ph) => {
    taph += ph.AMTINCVAT;
  });

  purchasehistorydata.forEach((ph) => {
    tpph += ph.QTY * ph.AMTINCVAT;
  });

  const handleButtonClick = (buttonName) => {
    setActiveButton(activeButton === buttonName ? null : buttonName);
  };

  useEffect(() => {
    getStockDetails();
  }, []);

  const getLocation = () => {
    axios
      .get(`${BASEURL}/Location`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        setLocation(response.data);
      })
      .catch((error) => {
        console.error("Error fetching itemcode:", error);
      });
  };

  const getStockDetails = () => {
    axios
      .get(`${BASEURL}/StockDetails`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        setisloading(false);
        setMainStockData(response.data);
      })
      .catch((error) => {
        console.log("Error fetching StockDetails : ", error);
      });
  };

  const displaySalesHistory = (fromdate, todate) => {
    getLocation();
    setLoading2(true);
    axios
      .post(
        `${BASEURL}/SalesHistory`,
        {
          Fromdate: moment(fromdate).format("DD/MM/YYYY"),
          Todate: moment(todate).format("DD/MM/YYYY"),
          itemcode: itemcode,
        },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((response) => {
        setLoading2(false);
        console.log(response.data);
        setSalesHistoryData(response.data);
      })
      .catch((error) => {
        setLoading2(false);
        console.log("Error Fetching Sales History :", error);
      });
  };
  const toggleSortOrder = () => {
    setSortByDateAscending(!sortByDateAscending);
  };
  const sortedSalesHistoryData = [...saleshistorydata].sort((a, b) => {
    const dateA = moment(a.DATE.slice(0, 10));
    const dateB = moment(b.DATE.slice(0, 10));
    return sortByDateAscending ? dateA - dateB : dateB - dateA;
  });

  const sortedPurchaseHistoryData = [...purchasehistorydata].sort((a, b) => {
    const dateA = moment(a.PODATE.slice(0, 10));
    const dateB = moment(b.PODATE.slice(0, 10));
    return sortByDateAscending ? dateA - dateB : dateB - dateA;
  });

  const sortedStockActivityData = [...stockactivitydata].sort((a, b) => {
    const dateA = moment(a.TRANSDATE.slice(0, 10));
    const dateB = moment(b.TRANSDATE.slice(0, 10));
    return sortByDateAscending ? dateA - dateB : dateB - dateA;
  });

  const displayPurchaseHistory = (pstartdate, penddate) => {
    setLoading2(true);
    console.log(pstartdate);
    axios
      .post(
        `${BASEURL}/PurchaseHistory`,
        {
          Fromdate: moment(pstartdate).format("DD/MM/YYYY"),
          Todate: moment(penddate).format("DD/MM/YYYY"),
          itemcode: itemcode,
        },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((response) => {
        setLoading2(false);
        console.log(response.data);
        setPurchaseHistoryData(response.data);
      })
      .catch((error) => {
        setLoading2(false);
        console.log("Error Fetching Purchase History :", error);
      });
  };

  const displayStockActivity = (sstartdate, senddate) => {
    getLocation();
    setLoading2(true);
    axios
      .post(
        `${BASEURL}/StockActivity`,
        {
          Fromdate: moment(sstartdate).format("DD/MM/YYYY"),
          Todate: moment(senddate).format("DD/MM/YYYY"),
          itemcode: itemcode,
        },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((response) => {
        setLoading2(false);
        setStockactivitydata(response.data);
        const quantities = response.data.map((item) => item.QTY);
        console.log(quantities);
        const sumOfQuantities = quantities.reduce(
          (sum, quantity) => sum + quantity,
          0
        );
        console.log(sumOfQuantities);

        // Update the state with the total quantity
        setOpenbal(sumOfQuantities);
      })
      .catch((error) => {
        setLoading2(false);
        console.log("Error Fetching Stock Activity :", error);
      });
  };

  const ExcelStockData = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("StockData");
    const rows = document.querySelectorAll(".table_1 tbody tr");

    const columnWidths = [15, 50, 30, 30, 15, 15]; // Adjust the values as needed

    // Set column widths for the worksheet
    columnWidths.forEach((width, index) => {
      worksheet.getColumn(index + 1).width = width;
    });

    // Add headers
    // worksheet.addRow(['ITEM CODE', 'ITEM NAME', 'CATEGORY', 'SUBCATEGORY', 'QTY', 'SELLING PRICE']);
    const headerRow = worksheet.addRow([
      "ITEM CODE",
      "ITEM NAME",
      "CATEGORY",
      "SUBCATEGORY",
      "QTY",
      "SELLING PRICE",
    ]);
    headerRow.eachCell((cell) => {
      cell.font = { bold: true };
    });

    // Add rows from the table
    rows.forEach((row) => {
      const rowData = [];
      row.querySelectorAll("td").forEach((cell) => {
        rowData.push(cell.textContent);
      });
      worksheet.addRow(rowData);
    });

    // Create a blob from the workbook
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "StockData.xlsx";
      link.click();
    });
  };

  const PdfStockData = () => {
    const doc = new jsPDF();

    doc.text("Stock Details", 10, 10);

    const columns = [
      "ITEM CODE",
      "ITEM NAME",
      "CATEGORY",
      "SUBCATEGORY",
      "QUANTITY",
      "SELLING PRICE",
    ];
    const data = mainstockdata.map((stock) => [
      stock.OM_ITEM_CODE,
      stock.OM_ITEM_DESCRIPTION,
      stock.MAINCATEGORY,
      stock.SUBCATEGORY,
      stock.STOCKQTY,
      stock.SELLINGPRICE,
    ]);

    doc.autoTable({
      head: [columns],
      body: data,
      startY: 20,
      showHead: "firstPage",
    });

    doc.save("stock_details.pdf");
  };

  // ExcelStockActivityData
  const ExcelStockActivityData = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("StockData");
    const rows = document.querySelectorAll(".table_2 tbody tr");

    const columnWidths = [20, 10, 15, 15, 50, 15, 10, 10, 10]; // Adjust the values as needed

    // Set column widths for the worksheet
    columnWidths.forEach((width, index) => {
      worksheet.getColumn(index + 1).width = width;
    });

    // Add headers
    const headerRow = worksheet.addRow([
      "TRANSACTION TYPE",
      "DOC NO",
      "DATE",
      "PART NO",
      "DESCRIPTION",
      "QUANTITY",
      "UOM",
      "BALANCE",
    ]);
    headerRow.eachCell((cell) => {
      cell.font = { bold: true };
    });

    // Add rows from the table
    rows.forEach((row) => {
      const rowData = [];
      row.querySelectorAll("td").forEach((cell) => {
        rowData.push(cell.textContent);
      });
      worksheet.addRow(rowData);
    });

    // Create a blob from the workbook
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "StockActivityData.xlsx";
      link.click();
    });
  };

  // PdfStockActivityData
  const PdfStockActivityData = () => {
    const doc = new jsPDF();

    doc.text("Stock Activity Details", 10, 10);

    const columns = [
      "TRANSACTION TYPE",
      "DOC NO",
      "DATE",
      "PART NO",
      "DESCRIPTION",
      "QUANTITY",
      "UOM",
      "BALANCE",
    ];
    const data = stockactivitydata.map((stock) => [
      stock.TRANSTYPE,
      stock.DOCNO,
      moment(stock.TRANSDATE).format("DD/MM/YYYY"),
      stock.ITEMCODE,
      stock["ITEM DESCRIPTION"],
      stock.QTY,
      stock.UOM,
      stock.COSTPRICE,
    ]);

    doc.autoTable({
      head: [columns],
      body: data,
      startY: 20,
      showHead: "firstPage",
    });

    doc.save("stock_activty_details.pdf");
  };

  // ExcelPurchaseHistoryData
  const ExcelPurchaseHistoryData = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("StockData");
    const rows = document.querySelectorAll(".table_3 tbody tr");

    const columnWidths = [20, 10, 15, 20, 10, 15, 10, 10, 10]; // Adjust the values as needed

    // Set column widths for the worksheet
    columnWidths.forEach((width, index) => {
      worksheet.getColumn(index + 1).width = width;
    });

    // Add headers
    const headerRow = worksheet.addRow([
      "Purchase Type",
      "GRN No",
      "Date",
      "Supplier Name",
      "Quantity",
      "Rate(Exc)",
      "Rate(Inc)",
      "Posted by",
    ]);
    headerRow.eachCell((cell) => {
      cell.font = { bold: true };
    });

    // Add rows from the table
    rows.forEach((row) => {
      const rowData = [];
      row.querySelectorAll("td").forEach((cell) => {
        rowData.push(cell.textContent);
      });
      worksheet.addRow(rowData);
    });

    // Create a blob from the workbook
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "PurchaseHistoryData.xlsx";
      link.click();
    });
  };

  // PdfPurchaseHistoryData
  const PdfPurchaseHistoryData = () => {
    const doc = new jsPDF();

    doc.text("Purchase History Details", 10, 10);

    const columns = [
      "Purchase Type",
      "GRN No",
      "Date",
      "Supplier Name",
      "Quantity",
      "Rate(Exc)",
      "Rate(Inc)",
      "Posted by",
    ];
    const data = purchasehistorydata.map((stock) => [
      stock.PURCHASESTYPE,
      stock.GRNNO,
      moment(stock.PODATE).format("DD/MM/YYYY"),
      stock.SUPPLIERNAME,
      stock.QTY,
      stock.AMOUNTEXCVAT,
      stock.AMTINCVAT,
      stock.CREATEDBY,
    ]);

    doc.autoTable({
      head: [columns],
      body: data,
      startY: 20,
      showHead: "firstPage",
    });

    doc.save("purchase_history_details.pdf");
  };

  // ExcelSalesHistoryData
  const ExcelSalesHistoryData = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("StockData");
    const rows = document.querySelectorAll(".table_4 tbody tr");

    const columnWidths = [20, 10, 15, 20, 20, 10, 10, 10, 10, 15, 20]; // Adjust the values as needed

    // Set column widths for the worksheet
    columnWidths.forEach((width, index) => {
      worksheet.getColumn(index + 1).width = width;
    });

    // Add headers
    const headerRow = worksheet.addRow([
      "Doc Type",
      "Doc No",
      "Doc Date",
      "Client Name",
      "Location",
      "Quantity",
      "Rate(Exc)",
      "Rate(Inc)",
      "Posted by",
      "Salesman",
      "Division",
    ]);
    headerRow.eachCell((cell) => {
      cell.font = { bold: true };
    });

    // Add rows from the table
    rows.forEach((row) => {
      const rowData = [];
      row.querySelectorAll("td").forEach((cell) => {
        rowData.push(cell.textContent);
      });
      worksheet.addRow(rowData);
    });

    // Create a blob from the workbook
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "SalesHistoryData.xlsx";
      link.click();
    });
  };

  // PdfSalesHistoryData
  const PdfSalesHistoryData = () => {
    const doc = new jsPDF();

    doc.text("Sales History Details", 10, 10);

    const columns = [
      "Doc Type",
      "Doc No",
      "Doc Date",
      "Client Name",
      "Location",
      "Quantity",
      "Rate(Exc)",
      "Rate(Inc)",
      "Posted by",
      "Salesman",
      "Division",
    ];
    const data = saleshistorydata.map((stock) => [
      stock.DOCUMENTTYPE,
      stock["DOCUMENT NO"],
      moment(stock.DATE).format("DD/MM/YYYY"),
      stock["CLIENT NAME"],
      stock.LOCATION,
      stock.QUANTITY,
      stock["AMOUNT EXC VAT"],
      stock.AMOUNT_INC_VAT,
      stock["CREATED BY"],
      stock.SALESMAN,
      stock["CLIENT DIVISION"],
    ]);

    doc.autoTable({
      head: [columns],
      body: data,
      startY: 20,
      showHead: "firstPage",
    });

    doc.save("purchase_history_details.pdf");
  };

  if (isloading) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <div
        className="Unapp_payment"
        style={{
          display: "flex",
          justifyContent: "space-between",
          position: "sticky",
          top: "0",
          left: "0",
          zIndex: "500",
        }}
      >
        <h1 style={{ textAlign: "center" }} className="Titles">
          Tech23 Dashboards
        </h1>
        <div className="nav_1">
          <div className="back_btn">
            {" "}
            <Link
              style={{ textDecoration: "none", color: "white" }}
              to={"/StockDashboard"}
            >
              <button className="View">Back</button>
            </Link>
          </div>
        </div>
      </div>
      <div className="box">
        <div>
          <section className="Line_1">
            <div className="Selected">
              <p>
                <b>Item Code :</b>
              </p>
              <input
                className="Selected_input"
                type="text"
                value={itemcode}
              ></input>
            </div>
            <div className="Description">
              <p>
                <b>Item Name:</b>
              </p>
              <input type="text" className="Desc_box" value={itemname}></input>
            </div>
          </section>
        </div>
        <div>
          <section className="Line_2">
            <div className="Selling_price">
              <p>
                <b>Selling Price :</b>
              </p>
              <input
                className="Sp_input"
                type="text"
                value={sellingprice}
              ></input>
            </div>
            <div className="Window_button1">
              <div>
                <div className="Like">
                  <p>
                    <b>Search:</b>
                  </p>
                  <input
                    className="input_Like"
                    type="text"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  ></input>
                </div>
              </div>
              <div className="Window_button">
                <button
                  className="Clear_btn"
                  onClick={() => {
                    setItemcode("");
                    setItemname("");
                    setSellingPrice("");
                  }}
                >
                  Clear..
                </button>
                {/* <div>
                <button className="View_Image">View Image</button>
              </div>
              <div>
                <button className="Enquiry">Enquiry</button>
              </div> */}
              </div>
            </div>
          </section>
        </div>
        <div></div>
      </div>
      <div className="stock_icons">
        {/* <div className="app">
                        <BsInfoCircle
                         
                          onClick={(e) => {
                            getDocNo(jo.DOCNO);
                            openModal();
                          }}
                        />
                        <div> */}
        <div>
          <RiFileExcel2Line onClick={ExcelStockData} size={35} />
        </div>
        <div>
          <BsFileEarmarkPdf onClick={PdfStockData} size={35} />
        </div>
      </div>
      <aside className="stock_table">
        <div
          style={{
            height: "250px",
            width: "95%",
            overflowY: "scroll",
          }}
        >
          <table className="table_1">
            <thead className="thead_stock">
              <tr className="tr_test1">
                <td style={{ width: "163px", paddingLeft: "11px" }}>
                  ITEM CODE
                </td>
                <td style={{ paddingLeft: "10px" }}>ITEM NAME</td>
                <td>CATEGORY</td>
                <td>SUBCATEGORY</td>
                <td className="header_quantity_stock">QUANTITY</td>
                <td className="stock_sp">SELLING PRICE</td>
              </tr>
            </thead>
            <tbody className="stock_tbody">
              {mainstockdata
                .filter((stock) => {
                  const searchKeywords = searchQuery.toLowerCase().split(" ");
                  return searchKeywords.every((keyword) =>
                    Object.keys(stock)
                      .filter((key) => key !== "SELLINGPRICE")
                      .map((key) => String(stock[key]).toLowerCase())
                      .join(" ")
                      .includes(keyword)
                  );
                })

                .map((stock, index) => {
                  return (
                    <tr
                      key={index}
                      className={`tr_test2 ${
                        selectedRow === index ? "selected-row" : ""
                      }`}
                      onClick={() => {
                        if (itemcode !== "") {
                          setSalesHistoryData([]);
                          setPurchaseHistoryData([]);
                          setStockactivitydata([]);
                          setSelectedRow(index);
                          setItemcode(stock.OM_ITEM_CODE);
                          setItemname(stock.OM_ITEM_DESCRIPTION);
                          setSellingPrice(stock.SELLINGPRICE);
                          setFromdate(
                            new Date(
                              new Date().getFullYear(),
                              0,
                              2
                            ).toISOString()
                          );
                          setTodate(new Date().toISOString());
                          setPstartdate(
                            new Date(
                              new Date().getFullYear(),
                              0,
                              2
                            ).toISOString()
                          );
                          setPenddate(new Date().toISOString());
                          setSstartdate(
                            new Date(
                              new Date().getFullYear(),
                              0,
                              2
                            ).toISOString()
                          );
                          setSenddate(new Date().toISOString());
                        } else {
                          setSelectedRow(index);
                          setItemcode(stock.OM_ITEM_CODE);
                          setItemname(stock.OM_ITEM_DESCRIPTION);
                          setSellingPrice(stock.SELLINGPRICE);
                        }
                      }}
                    >
                      <td style={{ textAlign: "right", paddingRight: "70px" }}>
                        {stock.OM_ITEM_CODE}
                      </td>
                      <td style={{ width: "390px", padding: "10px" }}>
                        {stock.OM_ITEM_DESCRIPTION}
                      </td>
                      <td style={{ width: "417px" }}>{stock.MAINCATEGORY}</td>
                      <td style={{ width: "324px" }}>{stock.SUBCATEGORY}</td>
                      <td className="stock_quantity">
                        {stock.STOCKQTY.toLocaleString()}
                      </td>
                      <td
                        style={{
                          width: "100px",
                          textAlign: "right",
                          paddingRight: "18px",
                        }}
                      >
                        {stock.SELLINGPRICE.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </aside>

      <div className="Window_button5">
        <button
          onClick={() => {
            if (itemcode === "") {
              alert("Please Select an Item to View Sales History");
            } else {
              handleButtonClick("sales");
              displaySalesHistory();
              setisSalesHistoryVisible(!isSaleshistoryVisible);
              setIsPurchaseHistoryVisible(false);
              setIsStockActivityVisible(false);
            }
          }}
          className={`Sales_history ${
            activeButton === "sales" ? "active" : ""
          }`}
        >
          Sales History
        </button>
        <button
          className={`btn_purchase ${
            activeButton === "purchase" ? "active" : ""
          }`}
          onClick={() => {
            if (itemcode === "") {
              alert("Please Select an Item to View Sales History");
            } else {
              handleButtonClick("purchase");
              displayPurchaseHistory();
              setIsPurchaseHistoryVisible(!isPurchaseHistoryVisible);
              setisSalesHistoryVisible(false);
              setIsStockActivityVisible(false);
            }
          }}
        >
          Purchase History
        </button>

        <button
          className={`btn_stock ${activeButton === "stock" ? "active" : ""}`}
          onClick={() => {
            if (itemcode === "") {
              alert("Please Select an Item to View Sales History");
            } else {
              handleButtonClick("stock");
              displayStockActivity();
              setIsStockActivityVisible(!isStockActivityVisible);
              setIsPurchaseHistoryVisible(false);
              setisSalesHistoryVisible(false);
            }
          }}
        >
          Stock Activity
        </button>
      </div>
      {isStockActivityVisible && (
        <div>
          <div>
            <div className="box_6">
              <div className="line_5">
                <div className="stock_start_date">
                  <p>
                    <b>Start Date</b>
                  </p>
                  <input
                    className="as_at"
                    value={sstartdate.split("T")[0]}
                    type="date"
                    onChange={(e) => {
                      if (e.target.value === "") {
                        alert("Please select a valid start date");
                      } else {
                        setSstartdate(e.target.value);
                        displayStockActivity(e.target.value, senddate);
                      }
                    }}
                  ></input>
                </div>
                <div className="stock_end_date">
                  <p>
                    <b>End Date</b>
                  </p>
                  <input
                    className="as_at"
                    value={senddate.split("T")[0]}
                    type="date"
                    onChange={(e) => {
                      if (e.target.value === "") {
                        alert("Please select a valid end date");
                      } else {
                        setSenddate(e.target.value);
                        displayStockActivity(sstartdate, e.target.value);
                      }
                    }}
                  ></input>
                </div>
                <div className="sales_history_loc">
                  <p>
                    <b>Location :</b>
                  </p>

                  <Select
                    className="Select_Category"
                    onChange={(selectedOption) => {
                      setSelsalocation(selectedOption.value);
                    }}
                    value={
                      selsalocation
                        ? { label: selsalocation, value: selsalocation }
                        : { label: "", value: "" }
                    }
                    options={[
                      { value: "", label: "" },
                      ...location.map((item) => ({
                        value: item.OM_LOC_NAME,
                        label: item.OM_LOC_NAME,
                      })),
                    ]}
                  />
                </div>
              </div>
              <div className="stock_act_second">
                <div className="sales_client">
                  <p>
                    <b>Search :</b>
                  </p>
                  <input
                    className="stock_search"
                    type="text"
                    value={searchsa}
                    onChange={(e) => {
                      setSearchSA(e.target.value);
                    }}
                  ></input>
                </div>

                <div className="Opening_balance">
                  <p>
                    <b>
                      Opening Balance :{" "}
                      {openbal.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </b>
                  </p>
                </div>
              </div>
            </div>

            {isLoading2 ? (
              <Loading2 />
            ) : (
              <>
                <div className="stock_icons">
                  <div>
                    <RiFileExcel2Line
                      onClick={ExcelStockActivityData}
                      size={35}
                    />
                  </div>
                  <div>
                    {" "}
                    <BsFileEarmarkPdf
                      onClick={PdfStockActivityData}
                      size={35}
                    />
                  </div>
                </div>
                <aside style={{ position: "relative", zIndex: "0" }}>
                  <div
                    style={{
                      height: "594px",
                      width: "95%",
                      overflowY: "scroll",
                      marginTop: "20px",
                      marginLeft: "20px",
                    }}
                  >
                    <table className="table_2">
                      <thead className="thead_stock">
                        <tr className="tr_test1">
                          <td style={{ width: "280px" }}>TRANSACTION TYPE</td>
                          <td>DOC NO</td>
                          <td>
                            <p className="header_date">DATE </p>
                            <TbArrowsSort size={15} onClick={toggleSortOrder} />
                          </td>
                          {/* <td>PART NO</td> */}
                          <td>
                            <p className="header_locat">LOCATION </p>
                          </td>
                          <td>QUANTITY</td>
                          <td>UOM</td>
                          <td style={{ width: "153px", paddingLeft: "58px" }}>
                            BALANCE
                          </td>
                        </tr>
                      </thead>
                      <tbody className="stock_tbody">
                        {sortedStockActivityData
                          .filter((sadata) => {
                            const searchKeywords = searchsa
                              .toLowerCase()
                              .split(" ");
                            return (
                              searchKeywords.every((keyword) =>
                                Object.values(sadata)
                                  .join(" ")
                                  .toLowerCase()
                                  .includes(keyword)
                              ) &&
                              (!selsalocation ||
                                sadata["LOCATION NO"] === selsalocation) // Filter by selected location
                            );
                          })
                          .map((sadata) => {
                            return (
                              <tr className="tr_test2">
                                <td style={{ padding: "10px" }}>
                                  {sadata.TRANSTYPE}
                                </td>
                                <td style={{ paddingLeft: "40px" }}>
                                  {sadata.DOCNO}
                                </td>
                                <td>
                                  {moment(sadata.TRANSDATE.slice(0, 10)).format(
                                    "DD/MM/YYYY"
                                  )}
                                </td>
                                {/* <td>{sadata.ITEMCODE}</td> */}
                                <td>{sadata["LOCATION NO"]}</td>
                                <td style={{ paddingLeft: "50px" }}>
                                  {sadata.QTY.toLocaleString()}
                                </td>
                                <td style={{ width: "70px" }}>{sadata.UOM}</td>
                                <td
                                  style={{
                                    textAlign: "right",
                                    paddingRight: "36px",
                                  }}
                                >
                                  {sadata.COSTPRICE.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </aside>
              </>
            )}
          </div>
        </div>
      )}
      {isPurchaseHistoryVisible && (
        <div>
          <div className="box8">
            <div className="purchase_history_first">
              <div className="purchase_start_date">
                <p>
                  <b>Start Date</b>
                </p>
                <input
                  className="as_at"
                  value={pstartdate.split("T")[0]}
                  type="date"
                  onChange={(e) => {
                    if (e.target.value === "") {
                      alert("Please select a valid start date");
                    } else {
                      setPstartdate(e.target.value);
                      displayPurchaseHistory(e.target.value, penddate);
                    }
                  }}
                ></input>
              </div>
              <div className="purchase_end_date">
                <p>
                  <b>End Date</b>
                </p>
                <input
                  className="as_at"
                  value={penddate.split("T")[0]}
                  type="date"
                  onChange={(e) => {
                    if (e.target.value === "") {
                      alert("Please select a valid end date");
                    } else {
                      setPenddate(e.target.value);
                      displayPurchaseHistory(pstartdate, e.target.value);
                    }
                  }}
                ></input>
              </div>
              <div className="sales_client">
                <p>
                  <b>Search :</b>
                </p>
                <input
                  className="stock_search"
                  type="text"
                  value={searchph}
                  onChange={(e) => {
                    setSearchPH(e.target.value);
                  }}
                ></input>
              </div>
            </div>

            <div className="purchase_history_total">
              <div className="ph_total_qty">
                <p>
                  <b>
                    Total Quantity :{""} {tqph.toLocaleString()}{" "}
                  </b>
                </p>
              </div>
              <div className="ph_total_amt">
                <p>
                  <b>
                    Total Amount :{""}
                    {taph.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </b>
                </p>
              </div>
              <div className="ph_total_sales">
                <p>
                  <b>
                    Total Sales :{""}
                    {tpph.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </b>
                </p>
              </div>
            </div>
          </div>
          {isLoading2 ? (
            <Loading2 />
          ) : (
            <>
              <div className="stock_icons">
                <div>
                  <RiFileExcel2Line
                    onClick={ExcelPurchaseHistoryData}
                    size={35}
                  />
                </div>
                <div>
                  <BsFileEarmarkPdf
                    onClick={PdfPurchaseHistoryData}
                    size={35}
                  />
                </div>
              </div>
              <aside>
                <div
                  style={{
                    height: "594px",
                    width: "95%",
                    overflowY: "scroll",
                    marginTop: "20px",
                    marginLeft: "20px",
                  }}
                >
                  <table className="table_3">
                    <thead className="thead_stock">
                      <tr className="tr_test1">
                        <td>
                          <p className="header_purchase_type">PURCHASE TYPE</p>
                        </td>
                        <td>
                          <p className="header_purchase_grn">GRN NO</p>
                        </td>
                        <td>
                          <p className="header_purchase_date">DATE </p>
                          <TbArrowsSort size={15} onClick={toggleSortOrder} />
                        </td>
                        <td>
                          {" "}
                          <p className="header_purchase_supp_name">
                            SUPPLIER NAME
                          </p>{" "}
                        </td>
                        <td>
                          <p className="header_purchase_qty">QUANTITY</p>
                        </td>
                        <td>
                          <p className="header_purchase_rate_exc">RATE(EXC)</p>
                        </td>
                        <td>
                          <p className="header_purchase_rate_inc">RATE(INC)</p>
                        </td>
                        <td>
                          <p className="posted_by">POSTED BY</p>
                        </td>
                      </tr>
                    </thead>
                    <tbody className="stock_tbody">
                      {sortedPurchaseHistoryData
                        .filter((phdata) => {
                          const searchKeywords = searchph
                            .toLowerCase()
                            .split(" ");
                          return searchKeywords.every((keyword) =>
                            [
                              "PURCHASESTYPE",
                              "GRNNO",
                              "PODATE",
                              "SUPPLIERNAME",
                              "QTY",
                              "CREATEDBY",
                            ]
                              .map((key) => String(phdata[key]).toLowerCase())
                              .join(" ")
                              .includes(keyword)
                          );
                        })
                        .map((phdata) => {
                          return (
                            <tr className="tr_test2">
                              <td style={{ padding: "10px" }}>
                                {phdata.PURCHASESTYPE}
                              </td>
                              <td style={{ paddingLeft: "45px" }}>
                                {phdata.GRNNO}
                              </td>
                              <td>
                                {moment(phdata.PODATE.slice(0, 10)).format(
                                  "DD/MM/YYYY"
                                )}
                              </td>
                              <td className="value_supp_name">
                                {phdata.SUPPLIERNAME}
                              </td>
                              <td className="value_qty">
                                {phdata.QTY.toLocaleString()}
                              </td>
                              <td className="value_ph_amt_exc">
                                {phdata.AMOUNTEXCVAT.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </td>
                              <td className="value_ph_amt_inv">
                                {phdata.AMTINCVAT.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </td>
                              <td style={{ textTransform: "uppercase" }}>
                                {phdata.CREATEDBY}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </aside>
            </>
          )}
        </div>
      )}

      {isSaleshistoryVisible && (
        <div>
          <div className="box9">
            <div className="sales_history_first">
              <div className="sales_from_date">
                <p>
                  <b>Start Date</b>
                </p>
                <input
                  className="as_at"
                  value={fromdate.split("T")[0]}
                  type="date"
                  onChange={(e) => {
                    if (e.target.value === "") {
                      alert("Please select a valid fromdate");
                    } else {
                      setFromdate(e.target.value);
                      displaySalesHistory(e.target.value, todate);
                    }
                  }}
                ></input>
              </div>
              <div className="sales_to_date">
                <p>
                  <b>End Date</b>
                </p>
                <input
                  className="as_at"
                  value={todate.split("T")[0]}
                  type="date"
                  onChange={(e) => {
                    if (e.target.value === "") {
                      alert("Please select a valid Todate");
                    } else {
                      setTodate(e.target.value);
                      displaySalesHistory(fromdate, e.target.value);
                    }
                  }}
                ></input>
              </div>
              <div className="sales_history_loc">
                <p>
                  <b>Location :</b>
                </p>

                <Select
                  className="Select_Category"
                  onChange={(selectedOption) => {
                    setSelLocation(selectedOption.value);
                  }}
                  value={
                    sellocation
                      ? { label: sellocation, value: sellocation }
                      : { label: "", value: "" }
                  }
                  options={[
                    { value: "", label: "" },
                    ...location.map((item) => ({
                      value: item.OM_LOC_NAME,
                      label: item.OM_LOC_NAME,
                    })),
                  ]}
                />
              </div>
            </div>
            <div className="sales_history_second">
              <div className="sales_client">
                <p>
                  <b>Search:</b>
                </p>
                <input
                  className="sh_search"
                  type="text"
                  value={searchsh}
                  onChange={(e) => {
                    setSearchSH(e.target.value);
                  }}
                ></input>
              </div>
              <div className="sales_history_total">
                <div className="sh_total_qty">
                  <p>
                    <b>Total Quantity : {tqsh.toLocaleString()}</b>
                  </p>
                </div>
                <div className="sh_total_amt">
                  <p>
                    <b>
                      Total Amount :{" "}
                      {tash.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </b>
                  </p>
                </div>
                <div className="sh_total_sales">
                  <p>
                    <b>
                      Total Sales :{" "}
                      {tssh.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 3,
                      })}
                    </b>
                  </p>
                </div>
              </div>
            </div>
          </div>
          {isLoading2 ? (
            <Loading2 />
          ) : (
            <>
              <div className="stock_icons">
                <div>
                  <RiFileExcel2Line onClick={ExcelSalesHistoryData} size={35} />
                </div>
                <div>
                  <BsFileEarmarkPdf onClick={PdfSalesHistoryData} size={35} />
                </div>
              </div>
              <aside style={{ position: "relative", zIndex: "0" }}>
                <div
                  style={{
                    height: "594px",
                    width: "95%",
                    overflowY: "scroll",
                    marginTop: "20px",
                    marginLeft: "20px",
                  }}
                >
                  <table className="table_4">
                    <thead className="thead_stock">
                      <tr className="tr_test1">
                        <td>
                          <p className="header_doc_type">DOC TYPE</p>
                        </td>
                        <td
                          style={{
                            paddingLeft: "27px",
                          }}
                        >
                          <p className="header_doc_no">DOC NO</p>
                        </td>
                        <td>
                          <p className="header_doc_date">DOC DATE </p>
                          <TbArrowsSort size={15} onClick={toggleSortOrder} />
                        </td>

                        <td>
                          <p className="header_client_name">CLIENT NAME</p>
                        </td>
                        <td>
                          <p className="header_loc">LOCATION</p>
                        </td>
                        <td style={{ paddingRight: "25px" }}>
                          <p className="header_qty">QUANTITY</p>
                        </td>
                        <td>
                          <p className="header_rate_exc">RATE(EXC)</p>
                        </td>
                        <td style={{ width: "103px" }}>
                          <p className="header_rate_inc">RATE(INC)</p>
                        </td>
                        <td>
                          <p className="header_posted_by1">POSTED BY</p>
                        </td>
                        {/* <td>Salesman</td> */}
                        <td>
                          <p className="hedaer_division">DIVISION</p>
                        </td>
                      </tr>
                    </thead>
                    <tbody className="stock_tbody">
                      {sortedSalesHistoryData
                        .filter((shdata) => {
                          const searchKeywords = searchsh
                            .toLowerCase()
                            .split(" ");
                          return (
                            searchKeywords.every((keyword) =>
                              [
                                "DOCUMENTTYPE",
                                "DOCUMENT NO",
                                "DATE",
                                "CLIENT NAME",
                                "LOCATION",
                                "QUANITY",
                                "CREATED BY",
                                "CLIENT DIVISION",
                              ]
                                .map((key) => String(shdata[key]).toLowerCase())
                                .join(" ")
                                .includes(keyword)
                            ) &&
                            (!sellocation || shdata.LOCATION === sellocation) // Filter by selected location
                          );
                        })
                        .map((shdata) => {
                          return (
                            <tr className="tr_test2">
                              <td style={{ width: "140px", padding: "10px" }}>
                                {shdata.DOCUMENTTYPE}
                              </td>
                              <td
                                style={{
                                  width: "138px",
                                  textAlign: "right",
                                  paddingRight: "55px",
                                }}
                              >
                                {shdata["DOCUMENT NO"]}
                              </td>
                              <td style={{ width: "124px" }}>
                                {moment(shdata.DATE.slice(0, 10)).format(
                                  "DD/MM/YYYY"
                                )}
                              </td>
                              <td style={{ width: "165px" }}>
                                {shdata["CLIENT NAME"]}
                              </td>
                              <td style={{ width: "167px" }}>
                                {shdata.LOCATION}
                              </td>
                              <td
                                style={{
                                  textAlign: "right",
                                  paddingRight: "27px",
                                }}
                              >
                                {shdata.QUANITY.toLocaleString()}
                              </td>
                              <td
                                style={{
                                  width: "100px",
                                  textAlign: "right",
                                  paddingRight: "40px",
                                }}
                              >
                                {shdata["AMOUNT EXC VAT"].toLocaleString(
                                  undefined,
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )}
                              </td>
                              <td
                                style={{
                                  textAlign: "right",
                                  paddingRight: "40px",
                                }}
                              >
                                {shdata.AMOUNT_INC_VAT.toLocaleString(
                                  undefined,
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )}
                              </td>
                              <td>{shdata["CREATED BY"]}</td>
                              {/* <td>{shdata.SALESMAN}</td> */}
                              <td style={{ width: "130px" }}>
                                {shdata["CLIENT DIVISION"]}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </aside>
            </>
          )}
        </div>
      )}
      <Footer />
    </div>
  );
}

export default ItemSelectionWindow;
