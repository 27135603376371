import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  email: "",
  usercode: "",
  loggedIn: false,
  superUserAccounts: "",
  exclusiveRights: [],
  selectedDepartment: "",
  dataFetched: false, // Flag to track if data has been fetched
};
export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    login: (state, action) => {
      state.email = action.payload.email;
      state.usercode = action.payload.usercode;
      state.loggedIn = action.payload.loggedIn;
    },
    logout: (state) => {
      state.email = "";
      state.usercode = "";
      state.loggedIn = false;
    },
    setSuperUserAccounts: (state, action) => {
      state.superUserAccounts = action.payload;
    },
    setExclusiveRights: (state, action) => {
      state.exclusiveRights = action.payload;
    },
    setSelectedDepartment: (state, action) => {
      state.selectedDepartment = action.payload;
    },
    setDataFetched: (state) => {
      state.dataFetched = true;
    },
    resetData: (state) => {
      state.superUserAccounts = "";
      state.exclusiveRights = [];
      state.dataFetched = false; // Reset dataFetched flag
    },
  },
});

export const {
  login,
  logout,
  setSuperUserAccounts,
  setExclusiveRights,
  setSelectedDepartment,
  setDataFetched,
  resetData,
} = userSlice.actions;

export const selectUser = (state) => state.user;

export default userSlice.reducer;
