import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUser } from "../../Components/features/userSlice";
import LoadingScreen from "../../Components/LoadingScreen";
import Footer from "../../Components/Footer";
import moment from "moment";

const ViewDetails = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const [viewPayments, setViewPayments] = useState([]);
  const location = useLocation();
  const propValue = location.state.value;
  const user = useSelector(selectUser);
  const email = user.email;
  const navigate = useNavigate();
  const [isloading, setisloading] = useState(true);
  const authToken = window.sessionStorage.getItem("auth-token");

  let totalCRAmount = 0;
  viewPayments.forEach((pd) => {
    if (pd?.CRDR === "CR") {
      totalCRAmount += pd?.AMOUNT;
    }
  });

  function getViewDetails() {
    axios
      .post(
        `${BASEURL}/ViewPayments`,
        {
          docno: propValue,
        },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((res) => {
        setViewPayments(res?.data);
        setisloading(false);
      })
      .catch((err) => {
        setisloading(false);
        console.log("error while fetching detailed view of payments", err);
      });
  }
  useEffect(() => {
    getViewDetails();
  }, []);

  function Approve() {
    const alertConfirm = window.confirm("Are you sure you want to Approve");
    if (alertConfirm === true) {
      axios
        .post(
          `${BASEURL}/ApprovePayments`,
          {
            data: { docno: propValue },
            mail: email.split("@")[0].toUpperCase(),
          },
          {
            headers: {
              "auth-token": authToken,
            },
          }
        )
        .then((response) => {
          alert("Approved Succesfully !");
          navigate("/UnapprovedPayments");
        });
      // window.location.reload();
    }
  }

  if (isloading) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          position: "sticky",
          top: "0",
          left: "0",
          zIndex: "500",
        }}
        className="Unapp_payment"
      >
        <h1 style={{ textAlign: "center" }} className="Titles">
          Tech23 Dashboards
        </h1>
        <div className="nav_1">
          {/* <div className="Approve_4">
            <button
              className="View"
              style={{ width: "110px" }}
              onClick={(e) => {
                e.preventDefault();
                Approve();
              }}
            >
              Approve
            </button>
          </div> */}
          <div className="back_btn">
            {" "}
            <Link
              style={{ textDecoration: "none", color: "white" }}
              to={"/UnapprovedPayments"}
            >
              <button className="View">Back</button>
            </Link>
          </div>
        </div>
      </div>

      <div>
        <div className="h2">
          <h2>Unapproved Payment Details</h2>
        </div>
        <div className="payments_view_docno">
          <div>
            <label>DOCNO</label>
            <span> : </span>
            <label>
              {viewPayments[0]?.PAYMENTNO ? viewPayments[0].PAYMENTNO : ""}
            </label>
          </div>

          <div>
            <label>DATE</label>
            <span> : </span>
            <label>
              {/* moment(ps.DATE).format("DD/MM/YYYY") */}
              {viewPayments[0]?.PAYMENTDATE
                ? moment(viewPayments[0].PAYMENTDATE).format("DD/MM/YYYY")
                : ""}
            </label>
          </div>

          <div>
            <label>PROCESSED BY</label>
            <span> : </span>
            <label>
              {viewPayments[0]?.PROCESSEDBY ? viewPayments[0].PROCESSEDBY : ""}
            </label>
          </div>
        </div>

        <div className="payments_narration_view">
          <div style={{ display: "flex" }}>
            <label>
              <span>NARRATION</span>
            </label>
            <span> : </span>
            <textarea className="Narration_1">
              {viewPayments[0]?.NARRATION ? viewPayments[0].NARRATION : ""}
            </textarea>
          </div>

          <div>
            <button
              className="button_2"
              onClick={(e) => {
                e.preventDefault();
                Approve();
              }}
            >
              Approve
            </button>
          </div>
        </div>
      </div>

      {/* table */}
      <div className="scroll">
        <table
          style={{ textTransform: "uppercase" }}
          className="Payment_data_header"
        >
          <thead className="thead">
            <tr className="Payment_tr_3">
              <th style={{ paddingLeft: "10px" }}>ACCOUNT NAME</th>
              <th className="view_header_cr_dr">DR/CR</th>
              <th className="view_header_amount">AMOUNT</th>
              <th className="view_header_curr">CURRENCY</th>
              <th className="view_header_conv_rate">CONVERSION RATE</th>
            </tr>
          </thead>

          <tbody className="Payments_tbody">
            {viewPayments.map((pd) => {
              return (
                <tr className="Payments_tbody_tr" key={pd.PAYMENTNO}>
                  <td
                    className="td"
                    style={{
                      width: "316px",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      paddingLeft: "10px",
                    }}
                  >
                    {pd.ACCOUNTNAME ? pd.ACCOUNTNAME : "N/A"}
                  </td>
                  <td
                    className="td view_payments_cr_dr"
                    // style={{ textAlign: "left", paddingLeft: "65px" }}
                  >
                    {pd.CRDR ? pd.CRDR : "N/A"}
                  </td>
                  <td className="td view_payments_amount">
                    {pd.AMOUNT
                      ? pd.AMOUNT.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : "N/A"}
                  </td>
                  <td className="view_payments_curr">
                    {pd.CURRENCY ? pd.CURRENCY : "N/A"}
                  </td>
                  <td
                    className="td view_payments_conv_rate"
                    // style={{
                    //   textAlign: "right",
                    //   margin: "4px 4px",
                    //   padding: "0px 20px 0px 0px",
                    // }}
                  >
                    {pd.CONVRATE ? pd.CONVRATE : "N/A"}
                  </td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <td
                colSpan="2"
                style={{
                  textAlign: "right",
                  paddingRight: "35px",
                  paddingBlock: "15px",
                }}
              >
                Total CR Amount:
              </td>
              <td className="total_payments_cr_dr_amount">
                {totalCRAmount
                  ? totalCRAmount.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : "N/A"}
              </td>
              {/* <td colSpan="2"></td> */}
            </tr>
          </tfoot>
        </table>
      </div>
      <Footer />
    </div>
  );
};

export default ViewDetails;
