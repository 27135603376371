import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import "../../../../CSS/AnalyticsBasedOnPaymentmode.css";

import Select from "react-select";
import axios from "axios";
import moment from "moment";
import ReactApexChart from "react-apexcharts";
import LoadingScreen from "../../../../Components/LoadingScreen";

import "../../../../CSS/ListofVehicles.css";
import { useNavigate } from "react-router-dom";
import TransportDashboardsNavbar from "../../../../Components/TransportDashboardsNavbar";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../../../Components/features/userSlice";
import NoOfJobsCarriedOutForThePeriod from "./NoOfJobsCarriedOutForThePeriod";
import JobCostingReport from "./JobCostingReport";
import NoOfJobCarriedOutClientWise from "./NoOfJobCarriedOutClientWise";
import NoOfJobsCarriedOutForSelectedClient from "./NoOfJobsCarriedOutForSelectedClient";
const ProductionCost = () => {
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const userEmail = user.email;
  const [selectedComponent, setSelectedComponent] = useState({
    value: "NoOfJobsCarriedOutForThePeriod",
    label: "No Of Jobs Carried Out For The Period",
  });
  const options = [
    {
      value: "NoOfJobsCarriedOutForThePeriod",
      label: "No Of Jobs Carried Out For The Period",
    },
    {
      value: "JobCostingReport",
      label: "Job Costing Report",
    },
    {
      value: "NoOfJobCarriedOutClientWise",
      label: "No Of Job Carried Out Client Wise",
    },
    {
      value: "NoOfJobsCarriedOutForSelectedClient",
      label: "No Of Jobs Carried Out For Selected Client",
    },
  ];

  const handleChange = (selectedOption) => {
    setSelectedComponent(selectedOption);
  };

  const [isloading, setisloading] = useState(true);

  useEffect(() => {
    setisloading(false);
  }, []);

  if (isloading) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <div
        className="Unapp_payment"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",

          position: "sticky",
          top: "0",
          left: "0",
          zIndex: "500",
        }}
      >
        <h1 style={{ textAlign: "center" }} className="Titles">
          Tech23 Dashboards
        </h1>
        <div className="flex items-center gap-[50px]">
          <p className="text-white">
            Logged in as : {userEmail.split("@")[0].toUpperCase()}
          </p>
          <div className="back_btn">
            {" "}
            <Link
              style={{ textDecoration: "none", color: "white" }}
              to={"/Production"}
            >
              <button className="View">Back</button>
            </Link>
          </div>
        </div>
      </div>

      <header className="header__of__main__dashboard flex justify-between items-end px-3">
        <h2 className="header__of__page">Production Cost</h2>

        <div className="w-[400px] mx-2 " style={{ zIndex: 999 }}>
          <Select
            value={selectedComponent}
            onChange={handleChange}
            options={options}
            placeholder="Select Component"
          />
        </div>
      </header>

      <div>
        {selectedComponent &&
          selectedComponent.value === "NoOfJobsCarriedOutForThePeriod" && (
            <NoOfJobsCarriedOutForThePeriod />
          )}

        {selectedComponent &&
          selectedComponent.value === "JobCostingReport" && (
            <JobCostingReport />
          )}
        {selectedComponent &&
          selectedComponent.value === "NoOfJobCarriedOutClientWise" && (
            <NoOfJobCarriedOutClientWise />
          )}
        {selectedComponent &&
          selectedComponent.value === "NoOfJobsCarriedOutForSelectedClient" && (
            <NoOfJobsCarriedOutForSelectedClient />
          )}
      </div>
    </div>
  );
};

export default ProductionCost;
