import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import LoadingScreen from "../../Components/LoadingScreen";
import "../../CSS/UnPurchaseOrders.css";
import { useSelector } from "react-redux";
import Footer from "../../Components/Footer";
import { selectUser } from "../../Components/features/userSlice";

const UnPuOr = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const [unpuor, setunpuor] = useState([]);
  const [isloading, setisloading] = useState(true);
  const [currentPage, setcurrentPage] = useState(1);
  const recordPerPage = 10;
  const [filterSupplier, setfilterSupplier] = useState("");
  const [filterSupplierCode, setfilterSupplierCode] = useState("");
  const [filterPoNo, setfilterPoNo] = useState("");
  const [filterFromdate, setfilterFromdate] = useState("");
  const [filterTodate, setfilterTodate] = useState("");
  const [savedDocno, setSavedDocno] = useState([]);
  const user = useSelector(selectUser);
  const email = user.email;
  const navigate = useNavigate();
  const authToken = window.sessionStorage.getItem("auth-token");

  useEffect(() => {
    getUnapprovedDetails();
  }, []);

  function Approve() {
    var errMsg = false;
    if (savedDocno.length === 0) {
      alert("Please select a payment to approve");
    } else {
      const alertConfirm = window.confirm("Are you sure you want to Approve");
      if (alertConfirm === true) {
        for (var i = 0; i < savedDocno.length; i++) {
          axios
            .post(
              `${BASEURL}/ApprovePurchaseOrders`,
              {
                data: savedDocno[i],
                mail: email.split("@")[0].toUpperCase(),
              },
              {
                headers: {
                  "auth-token": authToken,
                },
              }
            )
            .then((response) => {
              if (axios.AxiosError) {
                errMsg = true;
              }
            });
        }
        if (errMsg) {
          alert("An error Occured");
        } else if (!errMsg) {
          alert("Approved Successfully");
        }
        window.location.reload();
      }
    }
  }

  function saveDocNo(docno) {
    setSavedDocno([...savedDocno, { docno: docno }]);
  }

  function handleView(value) {
    navigate("/ViewPurchaseOrder", { state: { value } });
  }

  const getUnapprovedDetails = () => {
    axios
      .get(`${BASEURL}/UnPuOr`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        setunpuor(response?.data);
        setisloading(false);
      })
      .catch((err) => {
        setisloading(false);
        console.log("error while fetching Purchase Order Details", err);
      });
  };
  const lastIndex = currentPage * recordPerPage;

  const firstIndex = lastIndex - recordPerPage;
  if (isloading) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <div
        className="Unapp_payment"
        style={{
          display: "flex",
          justifyContent: "space-between",
          position: "sticky",
          top: "0",
          left: "0",
          zIndex: "500",
        }}
      >
        <h1 style={{ textAlign: "center" }} className="Titles">
          Tech23 Dashboards
        </h1>
        <div className="nav_1">
          {/* <div className="Approve">
            <button
              className="View"
              style={{ width: "110px", marginLeft: "625px" }}
              onClick={(e) => {
                e.preventDefault();
                Approve();
              }}
            >
              Approve
            </button>
          </div> */}
        </div>
        <div className="back_btn">
          {" "}
          <Link
            style={{ textDecoration: "none", color: "white" }}
            to={"/HomePage"}
          >
            <button className="View">Back</button>{" "}
          </Link>
        </div>
      </div>

      {/* Unapproved Purchase Orders */}

      <div className="media_padding">
        <div className="h2">
          <h2>Unapproved Purchase Orders</h2>
        </div>
        <div className="first_line">
          <div className="Sup_Name">
            <div>
              <label>
                <b>Search By Supplier Name:</b>
              </label>
            </div>
            <div>
              <input
                className="input_1"
                type="text"
                id="vendor"
                onChange={(e) => setfilterSupplier(e.target.value)}
              />
            </div>
          </div>
          <div className="Sup_code">
            <div>
              <label>
                <b>Search By Supplier Code:</b>
              </label>
            </div>
            <div>
              <input
                className="input_1"
                type="number"
                id="docno"
                style={{ width: "70px" }}
                onChange={(e) => setfilterSupplierCode(e.target.value)}
              />
            </div>
          </div>

          <div className="Po_No">
            <div>
              <label>
                <b>Search By PoNo:</b>
              </label>
            </div>
            <div>
              <input
                className="input_1"
                type="number"
                id="pono"
                style={{ width: "70px" }}
                onChange={(e) => setfilterPoNo(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="from_unp_date">
          <div className="from_Date">
            <div className="fr_dt">
              <label>
                <b>From Date:</b>
              </label>
            </div>
            <div>
              <input
                className="input_1"
                type="date"
                id="from"
                style={{ width: "115px" }}
                onChange={(e) => setfilterFromdate(e.target.value)}
              />
            </div>
          </div>
          <div className="to_Date">
            <div>
              <label>
                <b>To Date:</b>
              </label>
            </div>
            <div>
              <input
                className="input_1"
                type="date"
                id="to"
                style={{ width: "115px" }}
                onChange={(e) => setfilterTodate(e.target.value)}
              />
            </div>
          </div>
          <div className="btns">
            <button
              className="button"
              onClick={() => {
                setfilterSupplier("");
                setfilterSupplierCode("");
                setfilterFromdate("");
                setfilterTodate("");
                setfilterPoNo("");
                document.getElementById("vendor").value = "";
                document.getElementById("docno").value = "";
                document.getElementById("from").value = "";
                document.getElementById("to").value = "";
                document.getElementById("pono").value = "";
              }}
            >
              Clear
            </button>
            <button
              className="button"
              onClick={(e) => {
                e.preventDefault();
                Approve();
              }}
            >
              Approve
            </button>
          </div>
        </div>
      </div>
      <div className="scroll">
        <table
          style={{ textTransform: "uppercase" }}
          className="Payment_data_header"
        >
          <thead className="thead">
            <tr className="Payment_tr">
              <th style={{ width: "20px" }}></th>
              <th className="header_purchase_pono">PO NO</th>
              <th className="header_purchase_po_date">PO DATE</th>
              <th className="header_purchase_supp_code">SUPPLIER CODE</th>
              <th className="header_purchase_supp_name">SUPPLIER NAME</th>
              <th className="header_purchase_days">DAYS</th>
              <th className="header_purchase_created_by">CREATED BY</th>
              <th className="header_purchase_action">Action</th>
            </tr>
          </thead>

          <tbody className="Payments_tbody">
            {unpuor
              .filter((item) => {
                return filterSupplier.toLowerCase() === ""
                  ? item
                  : item?.SUPPLIERNAME.toLowerCase().includes(filterSupplier);
              })
              .filter((item) => {
                return filterSupplierCode.toString() === ""
                  ? item
                  : item?.SUPPLIERCODE.toString().includes(filterSupplierCode);
              })
              .filter((item) => {
                return filterPoNo.toString() === ""
                  ? item
                  : item?.PONO.toString().includes(filterPoNo);
              })
              .filter((date) => {
                if (filterFromdate && filterTodate) {
                  return (
                    date?.DATE >= filterFromdate && date?.DATE <= filterTodate
                  );
                } else if (filterFromdate) {
                  return date?.DATE >= filterFromdate;
                } else if (date <= filterTodate) {
                  return date?.DATE <= filterTodate;
                } else {
                  return date;
                }
              })
              .map((or) => {
                return (
                  <tr className="Payments_tbody_tr" key={or.PONO}>
                    <td style={{ padding: "15px 0px 0px 0px" }}>
                      <input
                        type="checkbox"
                        className="checkbox"
                        onChange={() => {
                          saveDocNo(or?.PONO);
                        }}
                      />{" "}
                    </td>
                    <td className="td value_purchase_pono">
                      {" "}
                      {or.PONO ? or.PONO : "N/A"}
                    </td>
                    <td className="td">
                      {or.PODATE
                        ? moment(or.PODATE).format("DD/MM/YYYY")
                        : "N/A"}
                    </td>
                    <td className="td or_supp_code">
                      {or.SUPPLIERCODE ? or.SUPPLIERCODE : "N/A"}
                    </td>

                    <td className="td or_supp_name">
                      {or.SUPPLIERNAME ? or.SUPPLIERNAME : "N/A"}
                    </td>
                    <td className="or_value_days">
                      {or.DAYS
                        ? or.DAYS.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                          })
                        : "N/A"}
                    </td>
                    <td
                      className="or_created_by"
                      // style={{
                      //   textAlign: "right",
                      //   margin: "2px 2px",
                      //   padding: "0px 74px 0px 0px",
                      // }}
                    >
                      {or.CREATEDBY ? or.CREATEDBY : "N/A"}
                    </td>
                    <td style={{ paddingLeft: "5px" }}>
                      <button
                        className="button"
                        type="button"
                        onClick={() => {
                          handleView(or?.PONO);
                        }}
                      >
                        View
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>

        <div className="pagination">
          <div>
            <button
              style={
                currentPage === 1
                  ? { visibility: "hidden" }
                  : { visibility: "visible" }
              }
              className="Violation_btn"
              onClick={() => {
                setcurrentPage(Number(currentPage) - 1);

                if (currentPage <= 1) {
                  setcurrentPage(1);
                } else {
                  setcurrentPage(Number(currentPage) - 1);
                }
              }}
            >
              &lt;
            </button>
          </div>

          {/* <input
          className="enter_no"
          type="number"
          placeholder="Go-to Page"
          onChange={(e) => {
            setPage(e.target.value);
          }}
        ></input> */}

          {/* <button
          className="View"
          onClick={() => {
            if (page <= 0) {
              setcurrentPage(1);
            } else if (page > Math.ceil(Number(unps.length / 20))) {
              setcurrentPage(Math.ceil(Number(unps.length / 20)));
            } else {
              setcurrentPage(page);
            }
          }}
        >
          Go-to
        // </button> */}

          <div>
            <button
              style={
                currentPage === Math.ceil(Number(unpuor.length / 20))
                  ? { visibility: "hidden" }
                  : { visibility: "visible" }
              }
              className="Violation_btn"
              onClick={() => {
                setcurrentPage(Number(currentPage) + 1);

                if (currentPage >= Math.ceil(Number(unpuor.length / 20))) {
                  setcurrentPage(Math.ceil(Number(unpuor.length / 20)));
                } else {
                  setcurrentPage(Number(currentPage) + 1);
                }
              }}
            >
              &gt;
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default UnPuOr;
