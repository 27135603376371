import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import "../../CSS/UnPayments.css";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import LoadingScreen from "../../Components/LoadingScreen";
import "./UnPayments";
import { useSelector } from "react-redux";
import { selectUser } from "../../Components/features/userSlice";
import Footer from "../../Components/Footer";

const UnPs = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const [unps, setunps] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const recordPerPage = 20;
  const [isloading, setisloading] = useState(true);
  const [filterVendor, setfilterVendor] = useState("");
  const [filterDocNo, setfilterDocNo] = useState("");
  const [filterFromdate, setfilterFromdate] = useState("");
  const [filterTodate, setfilterTodate] = useState("");
  const [savedDocno, setSavedDocno] = useState([]);
  const user = useSelector(selectUser);
  const email = user.email;
  const navigate = useNavigate();
  const authToken = window.sessionStorage.getItem("auth-token");
  const [numcount, setnumCount] = useState(false);

  function Approve() {
    var errMsg = false;
    if (savedDocno.length === 0) {
      alert("Please select a payment to approve");
    } else {
      const alertConfirm = window.confirm("Are you sure you want to Approve");
      if (alertConfirm === true) {
        for (var i = 0; i < savedDocno.length; i++) {
          axios
            .post(
              `${BASEURL}/ApprovePayments`,
              {
                data: savedDocno[i],
                mail: email.split("@")[0].toUpperCase(),
              },
              {
                headers: {
                  "auth-token": authToken,
                },
              }
            )
            .then((response) => {
              if (axios.AxiosError) {
                errMsg = true;
              }
            });
        }
        if (errMsg) {
          alert("An error Occured");
        } else if (!errMsg) {
          alert("Approved Successfully");
        }
        window.location.reload();
      }
    }
  }

  useEffect(() => {
    getUnapprovedDetails();
  }, []);

  const getUnapprovedDetails = async () => {
    axios
      .get(`${BASEURL}/UnPs`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        setunps(response?.data);
        setnumCount(false);
        setisloading(false);
      })
      .catch((err) => {
        setisloading(false);
        setnumCount(false);
        console.log("error while fetching unapproved Payments", err);
      });
  };
  const lastIndex = currentPage * recordPerPage;
  const firstIndex = lastIndex - recordPerPage;
  const records = unps?.slice(firstIndex, lastIndex);

  function saveDocNo(docno) {
    setSavedDocno([...savedDocno, { docno: docno }]);
  }

  function handleView(value) {
    navigate("/viewdetails", { state: { value } });
  }

  if (isloading) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          position: "sticky",
          top: "0",
          left: "0",
          zIndex: "500",
        }}
        className="Unapp_payment"
      >
        <h1 style={{ textAlign: "center" }} className="Titles">
          Tech23 Dashboards
        </h1>
        <div className="nav_1">
          {/* <div className="Approve">
            <button
              className="View"
              style={{ width: "110px" }}
              onClick={(e) => {
                e.preventDefault();
                Approve();
              }}
            >
              Approve
            </button>
          </div> */}
          <div className="back_btn">
            {" "}
            <Link
              style={{ textDecoration: "none", color: "white" }}
              to={"/HomePage"}
            >
              <button className="View">Back</button>
            </Link>
          </div>
        </div>
      </div>

      <div className="h2">
        <h2>Unapproved Payment Details</h2>
      </div>

      <div className="Vend_unp">
        <div className="Vendor">
          <div>
            <label>
              <b>Search By Vendor :</b>
            </label>
          </div>
          <div>
            <input
              className="input_1"
              type="text"
              id="vendor"
              onChange={(e) => setfilterVendor(e.target.value)}
            />
          </div>
        </div>
        <div className="Doc_No">
          <div>
            <label>
              <b>Search By DocNo :</b>
            </label>
          </div>
          <div>
            <input
              className="input_1"
              type="number"
              id="docno"
              style={{ width: "70px" }}
              onChange={(e) => setfilterDocNo(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="from_unp">
        <div className="from">
          <div>
            <label>
              <b>From Date:</b>
            </label>
          </div>
          <div>
            <input
              className="input_1"
              type="date"
              id="from"
              style={{ width: "115px" }}
              onChange={(e) => setfilterFromdate(e.target.value)}
            />
          </div>
        </div>
        <div className="to">
          <div>
            <label>
              <b>To Date:</b>
            </label>
          </div>
          <div>
            <input
              className="input_1"
              type="date"
              id="to"
              style={{ width: "115px" }}
              onChange={(e) => setfilterTodate(e.target.value)}
            />
          </div>
        </div>
        <div className="btns">
          <button
            className="button"
            onClick={() => {
              setfilterVendor("");
              setfilterDocNo("");
              setfilterFromdate("");
              setfilterTodate("");
              document.getElementById("vendor").value = "";
              document.getElementById("docno").value = "";
              document.getElementById("from").value = "";
              document.getElementById("to").value = "";
            }}
          >
            Clear
          </button>
          <button
            className="button"
            onClick={(e) => {
              e.preventDefault();
              Approve();
            }}
          >
            Approve
          </button>
        </div>
      </div>
      <div className="scroll">
        <table
          style={{ textTransform: "uppercase" }}
          className="Payment_data_header"
        >
          <thead className="thead">
            <tr className="Payment_tr">
              <th style={{ width: "20px" }}></th>
              <th className="header_payments_docno">DOC NO</th>
              <th className="header_payments_date">DATE</th>
              <th className="header_payments_vendor">VENDOR</th>
              <th className="header_payments_amount">AMOUNT</th>
              <th className="header_payments_curr">CURRENCY</th>
              <th className="header_payments_action" style={{ width: "100px" }}>
                Action
              </th>
            </tr>
          </thead>
          <tbody className="Payments_tbody">
            {records
              .filter((item) => {
                return filterVendor.toLowerCase() === ""
                  ? item
                  : item?.VENDOR.toLowerCase().includes(filterVendor);
              })
              .filter((item) => {
                return filterDocNo.toString() === ""
                  ? item
                  : item?.DOCNO.toString().includes(filterDocNo);
              })
              .filter((date) => {
                if (filterFromdate && filterTodate) {
                  return (
                    date?.DATE >= filterFromdate && date?.DATE <= filterTodate
                  );
                } else if (filterFromdate) {
                  return date?.DATE >= filterFromdate;
                } else if (date <= filterTodate) {
                  return date?.DATE <= filterTodate;
                } else {
                  return date;
                }
              })
              .map((ps) => {
                return (
                  <tr className="Payments_tbody_tr" key={ps.DOCNO}>
                    <td style={{ padding: "15px 0px 0px 0px" }}>
                      <input
                        type="checkbox"
                        className="checkbox"
                        onChange={() => {
                          saveDocNo(ps?.DOCNO);
                        }}
                      ></input>
                    </td>
                    <td className="td value_payments_docno">
                      {ps.DOCNO ? ps.DOCNO : "N/A"}
                    </td>

                    <td className="td value_payments_date">
                      {ps.DATE ? moment(ps.DATE).format("DD/MM/YYYY") : "N/A"}
                    </td>
                    <td className="td value_payments_vendor">
                      {ps.VENDOR ? ps.VENDOR : "N/A"}
                    </td>
                    <td className="td value_payments_amount">
                      {ps.AMOUNT
                        ? ps.AMOUNT.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : "N/A"}
                    </td>
                    <td className="td value_payments_curr">
                      {ps.CURRENCY ? ps.CURRENCY : "N/A"}
                    </td>
                    <td style={{ paddingLeft: "13px" }}>
                      <button
                        className="button"
                        type="button"
                        onClick={() => {
                          handleView(ps?.DOCNO);
                        }}
                      >
                        View
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>

        {numcount ? (
          <div className="pagination">
            <div>
              <button
                style={
                  currentPage === 1
                    ? { visibility: "hidden" }
                    : { visibility: "visible" }
                }
                className="Violation_btn"
                onClick={() => {
                  setcurrentPage(Number(currentPage) - 1);

                  if (currentPage <= 1) {
                    setcurrentPage(1);
                  } else {
                    setcurrentPage(Number(currentPage) - 1);
                  }
                }}
              >
                &lt;
              </button>
            </div>

            {/* <input
          className="enter_no"
          type="number"
          placeholder="Go-to Page"
          onChange={(e) => {
            setPage(e.target.value);
          }}
        ></input> */}

            {/* <button
          className="View"
          onClick={() => {
            if (page <= 0) {
              setcurrentPage(1);
            } else if (page > Math.ceil(Number(unps.length / 20))) {
              setcurrentPage(Math.ceil(Number(unps.length / 20)));
            } else {
              setcurrentPage(page);
            }
          }}
        >
          Go-to
        // </button> */}

            <div>
              <button
                style={
                  currentPage === Math.ceil(Number(unps.length / 20))
                    ? { visibility: "hidden" }
                    : { visibility: "visible" }
                }
                className="Violation_btn"
                onClick={() => {
                  setcurrentPage(Number(currentPage) + 1);

                  if (currentPage >= Math.ceil(Number(unps.length / 20))) {
                    setcurrentPage(Math.ceil(Number(unps.length / 20)));
                  } else {
                    setcurrentPage(Number(currentPage) + 1);
                  }
                }}
              >
                &gt;
              </button>
            </div>
          </div>
        ) : (
          <p></p>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default UnPs;
