import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import ReactApexChart from "react-apexcharts";
import moment from "moment";
import Loading2 from "../../../../Components/Loading2";
import Select from "react-select";
import { Link } from "react-router-dom";
import { FaChartPie } from "react-icons/fa";
import { IoBarChartOutline } from "react-icons/io5";
import { BiScatterChart } from "react-icons/bi";
import { BsCalendar2Date } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import LoaderSmall from "../../../../Components/LoadingSmall";
// import Loading2 from "../../../../Components/Loading2";
import LoadingScreen from "../../../../Components/LoadingScreen";
import { FaChartArea } from "react-icons/fa";
const ClientWiseIncomeSelectedClient = () => {
  const navigate = useNavigate();
  const [fromdate, setFromdate] = useState(
    new Date(new Date().getFullYear(), 0, 2).toISOString()
  );
  const [isLoading3, setLoading3] = useState(false);
  const [todate, setTodate] = useState(new Date().toISOString());
  const [ClientList, setClientList] = useState([]);
  var [selClientList, setSelClientList] = useState("");
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const [data, setData] = useState([]);
  const [datawithClientList, setDataWithClientList] = useState([]);
  const [isloading, setisloading] = useState(true);
  const [isLoading2, setLoading2] = useState(false);
  const [driver, setDriver] = useState([]);
  var [seldrivecode, setselDriveCode] = useState("");
  const [analyticsData, setanalyticsData] = useState([]);
  var [seldrivername, setseldrivername] = useState("");
  const [datawithvehinoanddrivercode, setdatawithvehinoanddrivercode] =
    useState([]);
  const [datawithdrivercode, setDatawithdrivercode] = useState([]);

  useEffect(() => {
    AnalyzeBasedOnClientList();
    setisloading(false);
  }, [selClientList, fromdate, todate, seldrivecode, seldrivername]);
  useEffect(() => {
    getClientListAll();
  }, []);

  const getClientListAll = () => {
    setLoading3(true);
    axios
      .get(`${BASEURL}/getClientList`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        setClientList(response.data);
        console.log(response.data, "getClientList");
        setLoading3(false);
      })
      .catch((error) => {
        console.error("Error fetching ClientList:", error);
        setLoading3(false);
      });
  };

  const AnalyzeBasedOnClientList = () => {
    const stertDatePass =
      fromdate.slice(8, 10) +
      "/" +
      fromdate.slice(5, 7) +
      "/" +
      fromdate.slice(0, 4);
    const endDatePass =
      todate.slice(8, 10) + "/" + todate.slice(5, 7) + "/" + todate.slice(0, 4);
    console.log(
      "stertDatePass",
      stertDatePass,
      "endDatePass",
      endDatePass,
      "selClientList",
      selClientList
    );
    if (stertDatePass && endDatePass && selClientList) {
      setLoading2(true);
      axios
        .post(
          `${BASEURL}/clientwiseincomeforselectedclient `,
          {
            STARTDATE: stertDatePass,
            ENDDATE: endDatePass,
            CLIENT: selClientList,
          },
          {
            headers: {
              "auth-token": authToken,
            },
          }
        )
        .then((response) => {
          setLoading2(false);
          setanalyticsData(response.data);
          console.log(response.data);
        })
        .catch((error) => {
          setLoading2(false);
          console.log(error);
        });
    }
  };
  const [chartType, setChartType] = useState("bar");

  const handleButtonClick = (type) => {
    setChartType(type);
  };

  const [activeDates, setActiveDates] = useState(false);
  const handleDateFilterChange = (filter) => {
    const currentDate = new Date();
    let startDate = null;
    let endDate = null;
    setActiveDates(false);

    switch (filter) {
      case "last7days":
        startDate = new Date(
          currentDate.getTime() - 7 * 24 * 60 * 60 * 1000
        ).toISOString();
        endDate = currentDate.toISOString();
        break;
      case "last3days":
        startDate = new Date(
          currentDate.getTime() - 3 * 24 * 60 * 60 * 1000
        ).toISOString();
        endDate = currentDate.toISOString();
        break;
      case "last30days":
        startDate = new Date(
          currentDate.getTime() - 30 * 24 * 60 * 60 * 1000
        ).toISOString();
        endDate = currentDate.toISOString();
        break;
      case "lastMonth":
        startDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() - 1,
          2
        ).toISOString();
        endDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
        ).toISOString();
        break;
      case "lastQuarter":
        startDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() - 3,
          2
        ).toISOString();
        endDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
        ).toISOString();
        break;
      case "lastYear":
        const previousYear = currentDate.getFullYear() - 1;
        startDate = new Date(previousYear, 0, 2).toISOString(); // January 1st of the previous year
        endDate = new Date(previousYear, 12, 1).toISOString(); // December 31st of the previous year
        break;

      default:
        break;
    }

    setFromdate(startDate);
    setTodate(endDate);
  };

  const filterRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (filterRef.current && !filterRef.current.contains(event.target)) {
      setActiveDates(false);
    }
  };
  if (isloading) {
    return <LoadingScreen />;
  }

  return (
    <div ref={filterRef}>
      <div className="align__date_1">
        <div className="cursor-pointer lg:flex items-center gap-2 w-">
          <div
            className="flex gap-3"
            onClick={() => setActiveDates(!activeDates)}
          >
            <h2 className="text-2xl">
              {" "}
              <BsCalendar2Date />
            </h2>
            <div>
              <span className="bg-white inline-block px-1 rounded border-1px border-solid">
                {fromdate.slice(8, 10) +
                  "-" +
                  fromdate.slice(5, 7) +
                  "-" +
                  fromdate.slice(0, 4)}
              </span>{" "}
              -{" "}
              <span className="bg-white inline-block px-1 rounded">
                {todate.slice(8, 10) +
                  "-" +
                  todate.slice(5, 7) +
                  "-" +
                  todate.slice(0, 4)}
              </span>
            </div>
          </div>
          <div
            className="lg:flex items-center lg:ml-10"
            style={{ zIndex: "100" }}
          >
            <aside>
              <p className="mt-2  lg:mt-0 lg:ml-[100px] lg:mx-1">Client </p>
            </aside>
            {isLoading3 === true ? (
              <LoaderSmall />
            ) : (
              <Select
                className="mx-1 text-sm lg:w-[450px]"
                onChange={(e) => {
                  setSelClientList(e.value);
                }}
                value={
                  selClientList
                    ? { label: selClientList, value: selClientList }
                    : { label: "Select Client", value: "" }
                }
                options={[
                  { value: "", label: "" },
                  ...ClientList.map((item) => ({
                    value: item?.["CLIENT NAME"],
                    label: item?.["CLIENT NAME"],
                  })),
                ]}
              />
            )}
          </div>
        </div>

        {activeDates && (
          <div className="date__custom">
            {/* Date filter buttons */}
            <div className="flex justify-between">
              <h1>Recommended</h1>
            </div>
            <button onClick={() => handleDateFilterChange("last3days")}>
              Last 3 Days
            </button>
            <button onClick={() => handleDateFilterChange("last7days")}>
              Last 7 Days
            </button>

            <button onClick={() => handleDateFilterChange("last30days")}>
              Last 30 Days
            </button>
            <h1>Calendar months</h1>
            <button onClick={() => handleDateFilterChange("lastMonth")}>
              Last Month
            </button>
            <button onClick={() => handleDateFilterChange("lastQuarter")}>
              Last Quarter
            </button>
            <button onClick={() => handleDateFilterChange("lastYear")}>
              Last Year
            </button>
            <h1>Custom date</h1>
            <main className="flex mx-1 gap-3">
              <div className="">
                <div>
                  <p>Start Date :</p>
                </div>
                <div>
                  <input
                    value={fromdate.split("T")[0]}
                    type="date"
                    onClick={() => setActiveDates(true)}
                    onChange={(e) => {
                      setFromdate(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="">
                <div>
                  <p>End Date :</p>
                </div>
                <div>
                  <input
                    value={todate.split("T")[0]}
                    type="date"
                    onClick={() => setActiveDates(true)}
                    onChange={(e) => {
                      if (e.target.value === "") {
                        alert("Please select a valid todate");
                      } else {
                        setTodate(e.target.value);
                      }
                    }}
                  />
                </div>
              </div>
            </main>
          </div>
        )}
      </div>

      <div style={{ width: "95%", margin: "0 auto" }}>
        {isLoading2 ? (
          <Loading2 />
        ) : (
          <>
            <div className="chart__active__buttons">
              <button
                className={
                  chartType === "bar" ? "active__chart" : "not__active__chart"
                }
                onClick={() => handleButtonClick("bar")}
              >
                <IoBarChartOutline />
              </button>
              <button
                className={
                  chartType === "pie" ? "active__chart" : "not__active__chart"
                }
                onClick={() => handleButtonClick("pie")}
              >
                <FaChartPie />
              </button>

              <button
                className={
                  chartType === "scatter"
                    ? "active__chart"
                    : "not__active__chart"
                }
                onClick={() => handleButtonClick("scatter")}
              >
                <BiScatterChart />
              </button>
              <button
                className={
                  chartType === "area"
                    ? "active__chart "
                    : "not__active__chart  "
                }
                onClick={() => handleButtonClick("area")}
              >
                <FaChartArea />
              </button>
            </div>{" "}
            {analyticsData.length > 0 ? (
              <div>
                {chartType === "bar" && (
                  <ReactApexChart
                    options={{
                      chart: {
                        id: "bar-chart", // Change the ID for the bar chart
                      },
                      xaxis: {
                        categories: analyticsData.map((item) =>
                          moment(item?.PERIOD).format(" MMM YYYY")
                        ),
                        title: {
                          text: "PERIOD", // X-axis label
                        },
                      },
                      yaxis: [
                        {
                          title: {
                            text: "AMOUNT", // Y-axis label
                          },
                        },
                      ],
                      dataLabels: {
                        enabled: false, // Disable the data labels for bar chart
                      },
                    }}
                    series={[
                      {
                        name: "AMOUNT",
                        data: analyticsData.map((item) => item?.AMOUNT),
                      },
                    ]}
                    type="bar" // Change chart type to "bar"
                    height={300}
                  />
                )}
                {chartType === "area" && (
                  <ReactApexChart
                    options={{
                      chart: {
                        id: "area-chart", // Change the ID for the area chart
                      },
                      xaxis: {
                        categories: analyticsData.map((item) =>
                          moment(item?.PERIOD).format("MMM YYYY")
                        ),
                        title: {
                          text: "PERIOD", // X-axis label
                        },
                      },
                      yaxis: [
                        {
                          title: {
                            text: "AMOUNT", // Y-axis label
                          },
                        },
                      ],
                      dataLabels: {
                        enabled: false, // Disable the data labels for area chart
                      },
                    }}
                    series={[
                      {
                        name: "AMOUNT",
                        data: analyticsData.map((item) => item?.AMOUNT),
                      },
                    ]}
                    type="area" // Change chart type to "area"
                    height={300}
                  />
                )}

                {chartType === "pie" && (
                  <div className="flex__pai">
                    <aside className="for__single__pai">
                      <ReactApexChart
                        options={{
                          labels: analyticsData.map((item) =>
                            moment(item?.PERIOD).format(" MMM YYYY")
                          ),
                          title: {
                            text: "PERIOD", // Title of the pie chart
                          },
                          dataLabels: {
                            enabled: true, // Enable data labels for pie chart
                          },
                          tooltip: {
                            enabled: true,
                            y: {
                              formatter: function (val, opts) {
                                let index = opts.dataPointIndex;
                                let name = "AMOUNT";
                                return name + ": " + val;
                              },
                            },
                          },
                        }}
                        series={analyticsData.map((item) =>
                          Number(item?.AMOUNT)
                        )}
                        type="pie" // Set chart type to "pie"
                        height={300}
                      />
                    </aside>
                  </div>
                )}

                {chartType === "scatter" && (
                  <ReactApexChart
                    options={{
                      chart: {
                        id: "scatter-chart", // Change the ID for the scatter chart
                      },
                      xaxis: {
                        type: "datetime",
                        categories: analyticsData.map((item) =>
                          moment(item?.PERIOD).format(" MMM YYYY")
                        ), // Convert to milliseconds
                        title: {
                          text: "PERIOD", // X-axis label
                        },
                      },
                      yaxis: [
                        {
                          title: {
                            text: "AMOUNT", // Y-axis label
                          },
                        },
                      ],
                      title: {
                        text: "Scatter Chart",
                      },
                    }}
                    series={[
                      {
                        name: "AMOUNT", // Y-axis label
                        data: analyticsData.map((item) => ({
                          x: new Date(item?.PERIOD).getTime(), // Convert to milliseconds
                          y: item?.AMOUNT,
                        })),
                      },
                    ]}
                    type="scatter" // Set chart type to "scatter"
                    height={300}
                  />
                )}
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "20px",
                  marginTop: "50px",
                  textDecoration: "underline",
                }}
              >
                {selClientList && <p> No Data Available</p>}
                {!selClientList && <p> Select Client</p>}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ClientWiseIncomeSelectedClient;
