import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import "../../../../CSS/AnalyticsBasedOnPaymentmode.css";
import Select from "react-select";
import axios from "axios";
import moment from "moment";
import ReactApexChart from "react-apexcharts";
import LoadingScreen from "../../../../Components/LoadingScreen";
import DatewiseDeliveryforRouteSelected from "./DatewiseDeliveryforRouteSelected";
import DatewiseDeliveryforRoutenotSelected from "./DatewiseDeliveryforRoutenotSelected";
import DriverwiseDelivery from "./DriverwiseDelivery";
import DriverwiseDeliveryforselectedDriver from "./DriverwiseDeliveryforselectedDriver";
import MonthwiseDelivery from "./MonthwiseDelivery";
import VehiclewiseDelivery from "./VehiclewiseDelivery";
import VehiclewiseDeliveryforselectedVehicle from "./VehiclewiseDeliveryforselectedVehicle";
import "../../../../CSS/ListofVehicles.css";
import { useNavigate } from "react-router-dom";
import TransportDashboardsNavbar from "../../../../Components/TransportDashboardsNavbar";

const TransportAnalyticsMAIN = () => {
  const [selectedComponent, setSelectedComponent] = useState({
    value: "MonthwiseDelivery",
    label: "Month wise Delivery",
  });
  const options = [
    {
      value: "MonthwiseDelivery",
      label: "Month wise Delivery",
    },
    {
      value: "DatewiseDeliveryforRoutenotSelected",
      label: "Datewise Delivery for Route not Selected",
    },
    {
      value: "DatewiseDeliveryforRouteSelected",
      label: "Date wise Delivery for Route Selected",
    },
    {
      value: "VehiclewiseDelivery",
      label: "Vehicle wise Delivery",
    },
    {
      value: "VehiclewiseDeliveryforselectedVehicle",
      label: "Vehicle wise Delivery for selected Vehicle",
    },
    {
      value: "DriverwiseDelivery",
      label: "Driver wise Delivery",
    },
    {
      value: "DriverwiseDeliveryforselectedDriver",
      label: "Driver wise Delivery for selected Driver",
    },
  ];

  const handleChange = (selectedOption) => {
    setSelectedComponent(selectedOption);
  };

  const [isloading, setisloading] = useState(true);

  useEffect(() => {
    setisloading(false);
  }, []);

  if (isloading) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <TransportDashboardsNavbar />

      <header className="header__of__main__dashboard flex justify-between items-end px-3">
        <h2 className="header__of__page">Transport Analytics</h2>

        <div className="w-[400px] mx-2 " style={{ zIndex: 999 }}>
          <Select
            value={selectedComponent}
            onChange={handleChange}
            options={options}
            placeholder="Select Component"
          />
        </div>
      </header>

      <div>
        {selectedComponent &&
          selectedComponent.value === "MonthwiseDelivery" && (
            <MonthwiseDelivery />
          )}
        {selectedComponent &&
          selectedComponent.value === "DatewiseDeliveryforRoutenotSelected" && (
            <DatewiseDeliveryforRoutenotSelected />
          )}
        {selectedComponent &&
          selectedComponent.value === "DatewiseDeliveryforRouteSelected" && (
            <DatewiseDeliveryforRouteSelected />
          )}
        {selectedComponent &&
          selectedComponent.value === "VehiclewiseDelivery" && (
            <VehiclewiseDelivery />
          )}
        {selectedComponent &&
          selectedComponent.value ===
            "VehiclewiseDeliveryforselectedVehicle" && (
            <VehiclewiseDeliveryforselectedVehicle />
          )}
        {selectedComponent &&
          selectedComponent.value === "DriverwiseDelivery" && (
            <DriverwiseDelivery />
          )}
        {selectedComponent &&
          selectedComponent.value === "DriverwiseDeliveryforselectedDriver" && (
            <DriverwiseDeliveryforselectedDriver />
          )}
      </div>
    </div>
  );
};

export default TransportAnalyticsMAIN;
