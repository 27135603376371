import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Select from "react-select";
import moment from "moment";
import ReactApexChart from "react-apexcharts";
import LoadingScreen from "../../../../Components/LoadingScreen";
import Loading2 from "../../../../Components/Loading2";
import Footer from "../../../../Components/Footer";

const AnalyticsBasedOnSubCategoryandQuantity = () => {
  const [date, setDate] = useState(new Date().toISOString());
  var [selsubcategory, setSelsubcategory] = useState("");
  const [subcategory, setSubcategory] = useState([]);
  const BASEURL = process.env.REACT_APP_BASEURL;
  const [isloading, setisloading] = useState(true);
  const [isLoading2, setLoading2] = useState(true);
  const authToken = window.sessionStorage.getItem("auth-token");
  const [data, setData] = useState([]);
  const [datawithSubCategory, setDataWithSubCategory] = useState([]);

  useEffect(() => {
    getSubCategory();

    AnalyticsBasedOnSubCategory();
    setisloading(false);
  }, [selsubcategory, date]);

  const getSubCategory = () => {
    axios
      .get(`${BASEURL}/SubCategory`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        setSubcategory(response.data);
      })
      .catch((error) => {
        console.error("Error fetching itemcode:", error);
      });
  };

  const AnalyticsBasedOnSubCategory = () => {
    if (selsubcategory === "") {
      selsubcategory = "null";
      setLoading2(true);
      axios
        .post(
          `${BASEURL}/subcategorybasedonquantityanddate/${selsubcategory}`,
          {
            date: moment(date).format("DD/MM/YYYY"),
          },
          {
            headers: {
              "auth-token": authToken,
            },
          }
        )
        .then((response) => {
          setLoading2(false);
          setData(response.data);
          console.log(response.data);
        })
        .catch((error) => {
          setLoading2(false);
          console.log(error);
        });
    } else {
      setLoading2(true);
      axios
        .post(
          `${BASEURL}/categorybasedonquantityanddate/${selsubcategory}`,
          {
            date: moment(date).format("DD/MM/YYYY"),
          },
          {
            headers: {
              "auth-token": authToken,
            },
          }
        )
        .then((response) => {
          setLoading2(false);
          setDataWithSubCategory(response.data);

          console.log(response.data);
        })
        .catch((error) => {
          setLoading2(false);
          console.log(error);
        });
    }
  };
  if (isloading) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <div
        className="Unapp_payment"
        style={{
          display: "flex",
          justifyContent: "space-between",
          position: "sticky",
          top: "0",
          left: "0",
          zIndex: "500",
        }}
      >
        <h1 style={{ textAlign: "center" }} className="Titles">
          Tech23 Dashboards
        </h1>
        <div className="nav_1">
          <div className="back_btn">
            {" "}
            <Link
              style={{ textDecoration: "none", color: "white" }}
              to={"/StockDashboard"}
            >
              <button className="View">Back</button>
            </Link>
          </div>
        </div>
      </div>
      <div className="h2">
        <h2>Analytics Based on SubCategory and Quantity</h2>
      </div>
      <div className="align1_date">
        <div className="">
          <div>
            <p> Date :</p>
          </div>
          <div>
            <input
              className="as_at"
              value={date.split("T")[0]}
              type="date"
              onChange={(e) => {
                if (e.target.value === "") {
                  alert("Please select a date");
                } else {
                  setDate(e.target.value);
                }
              }}
            />
          </div>
        </div>

        <main>
          <div className="Stock_category">
            <div className="lbl_SubCategory">
              <aside className="lbl_sub_category">
                <p>Sub Category :</p>
              </aside>
            </div>
            <div>
              <Select
                className="Select_Category"
                onChange={(selectedOption) => {
                  if (data.length <= 0) {
                    alert("There is no data to sort!");
                  } else {
                    setSelsubcategory(selectedOption.value);
                  }
                }}
                value={
                  selsubcategory
                    ? { label: selsubcategory, value: selsubcategory }
                    : { label: "", value: "" }
                }
                options={[
                  { value: "", label: "" },
                  ...subcategory.map((item) => ({
                    value: item.CATEGORY,
                    label: item.CATEGORY,
                  })),
                ]}
              />
            </div>
          </div>
        </main>
      </div>
      <div style={{ width: "90%", margin: "0 auto", marginTop: "15px" }}>
        {/* <h2 style={{ fontWeight: "100" }}>REVENUE ANALYTICS</h2> */}
        {isLoading2 ? (
          <Loading2 />
        ) : (
          <>
            {selsubcategory && datawithSubCategory.length > 0 ? (
              <ReactApexChart
                options={{
                  chart: {
                    id: "line-chart", // Change the ID for the line chart
                  },
                  xaxis: {
                    categories: datawithSubCategory.map((item) =>
                      moment(item.MONTHNAME).format("MMM-YYYY")
                    ),
                    title: {
                      text: "Months", // X-axis label
                    },
                  },
                  yaxis: {
                    title: {
                      text: "Quantity", // Y-axis label
                    },
                  },
                  dataLabels: {
                    enabled: false, // Disable the data labels for line chart
                  },
                }}
                series={[
                  {
                    name: "Quantity",
                    data: datawithSubCategory.map((item) => item.QTY),
                  },
                ]}
                type="line" // Change chart type to "line"
                height={600}
              />
            ) : !selsubcategory && data.length > 0 ? (
              <ReactApexChart
                options={{
                  chart: {
                    id: "line-chart", // Change the ID for the line chart
                  },
                  xaxis: {
                    categories: data.map((item) => item.OM_ITEM_SUB_CATEGORY),
                    title: {
                      text: "Category", // X-axis label
                    },
                  },
                  yaxis: {
                    title: {
                      text: "Quantity", // Y-axis label
                    },
                  },
                  dataLabels: {
                    enabled: false, // Disable the data labels for line chart
                  },
                }}
                series={[
                  {
                    name: "Quantity",
                    data: data.map((item) => item.QTY),
                  },
                ]}
                type="line" // Change chart type to "line"
                height={600}
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "20px",
                  marginTop: "50px",
                  textDecoration: "underline",
                }}
              >
                NO RESULTS FOUND
              </div>
            )}
          </>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default AnalyticsBasedOnSubCategoryandQuantity;
