import React from "react";
import "./CSS/Dashboards.css";
import { useNavigate } from "react-router-dom";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import Chart from "./Images/chart.PNG";
import Approved from "./Images/approved.PNG";

function Dashboards() {
  const navigate = useNavigate();
  return (
    <div>
      <Navbar />
      <div className="h2">
        <h2>Welcome to Tech23 Dashboards</h2>
      </div>

      <section className="for__main__dashboard">
        <div
          onClick={() => {
            navigate("/Dashboard2");
          }}
          className="align__img__and__heading"
        >
          <img src={Approved} height="150px" width="180px" alt="dashboard" />
          <h3>Unapproved Documents</h3>
        </div>
        <div
          onClick={() => {
            navigate("/Dashboard1");
          }}
          className="align__img__and__heading"
        >
          <img src={Chart} height="150px" width="200px" alt="dashboard" />
          <h3>Analytics</h3>
        </div>
      </section>
      {/* 
      <div className="dashboards">
        <div
          className="card"
          onClick={() => {
            navigate("/HomePage");
          }}
        >
          <div className="card-content">
            <div className="card-title">Unapproved Dashboard</div>
          </div>
        </div>

        <div
          className="card"
          onClick={() => {
            navigate("/AnalyticsDashboard");
          }}
        >
          <div className="card-content">
            <div className="card-title">Analytics</div>
          </div>
        </div>
      </div> */}
      <Footer />
    </div>
  );
}

export default Dashboards;
