import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../../Components/features/userSlice";

import Footer from "../../Components/Footer";
import financeU from "../../Images/financeU.png";
import storesandfuelU from "../../Images/storesandfuelU.png";
import transportU from "../../Images/transportU.png";
import workshopU from "../../Images/workshopU.png";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../Components/features/userSlice";
function TransportDashboardUnapproved() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const userEmail = user.email;
  const logoutOff = (e) => {
    e.preventDefault();
    dispatch(logout());
    navigate("/");
  };

  return (
    <div>
      <div
        className="Unapp_payment"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          position: "sticky",
          top: "0",
          left: "0",
          zIndex: "500",
        }}
      >
        <h1 style={{ textAlign: "center" }} className="Titles">
          Tech23 Dashboards
        </h1>
        <div className="flex items-center gap-[50px]">
          <p className="text-white">
            Logged in as : {userEmail.split("@")[0].toUpperCase()}
          </p>
          <div className="back_btn">
            <Link
              style={{ textDecoration: "none", color: "white" }}
              to={"/Dashboard2"}
            >
              <button className="View">Back</button>
            </Link>
          </div>
        </div>
      </div>
      <div className="h2">
        <h1>Welcome To Transport Approval Platform</h1>
      </div>

      {/*  */}

      <section className="for__main__dashboard">
        <div
          onClick={() => {
            navigate("/TransportOperationDashboard");
          }}
          className="align__img__and__heading"
        >
          <img src={transportU} height="150px" width="200px" alt="dashboard" />
          <h3>Transport operations</h3>
        </div>
        <div
          onClick={() => {
            navigate("/WorkshopDashboard");
          }}
          className="align__img__and__heading"
        >
          <img src={workshopU} height="150px" width="200px" alt="dashboard" />
          <h3>Workshop</h3>
        </div>
        <div
          onClick={() => {
            navigate("/StoreandFuelDashboard");
          }}
          className="align__img__and__heading"
        >
          <img
            src={storesandfuelU}
            height="150px"
            width="200px"
            alt="dashboard"
          />
          <h3>Stores and Fuel</h3>
        </div>
        <div
          onClick={() => {
            navigate("/FinanceUnapprovedDashboard");
          }}
          className="align__img__and__heading"
        >
          <img src={financeU} height="150px" width="200px" alt="dashboard" />
          <h3>Finance</h3>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default TransportDashboardUnapproved;
