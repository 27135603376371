import React from "react";
import "../../src/CSS/Loading3.css";
const LoadingSmall = () => {
  return (
    <div className="h-[40px] mt-4  lg:mt-0  lg:h-auto">
      <span class="loader3"></span>
    </div>
  );
};

export default LoadingSmall;
