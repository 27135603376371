import React, { useState, useEffect } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import Select from "react-select";
import axios from "axios";
import "../../../../CSS/AnalyticsBasedOnClientAndItem.css";
import ReactApexChart from "react-apexcharts";
import LoadingScreen from "../../../../Components/LoadingScreen";
import Loading2 from "../../../../Components/Loading2";
import Footer from "../../../../Components/Footer";
import { FaChartPie } from "react-icons/fa";
import { IoBarChartOutline } from "react-icons/io5";
import { BiScatterChart } from "react-icons/bi";
const FuelConsumptiondateandvahiclewise = () => {
  const [isLoading2, setLoading2] = useState(false);
  const [isloading, setisloading] = useState(true);
  const [data, setData] = useState([]);
  const [VehicleNo, setVehicleNo] = useState([]);
  const [selvehicleNo, setSelVehicleNo] = useState("");
  const [fromdate, setFromdate] = useState(
    new Date(new Date().getFullYear(), 0, 2).toISOString()
  );
  const [todate, setTodate] = useState(new Date().toISOString());
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  // if (isloading) {
  //   return <LoadingScreen />;
  // }
  const [chartType, setChartType] = useState("bar");

  const handleButtonClick = (type) => {
    setChartType(type);
  };
  useEffect(() => {
    AnalyzeBasedOnClient();
    getVehicleNo();
    setisloading(false);
  }, [fromdate, todate, selvehicleNo]);
  const getVehicleNo = () => {
    setisloading(true);
    axios
      .get(`${BASEURL}/getVehicleList`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        setVehicleNo(response.data);
        setisloading(false);
      })
      .catch((error) => {
        console.error("Error fetching VehicleNo:", error);
        setisloading(false);
      });
  };
  const AnalyzeBasedOnClient = () => {
    // setLoading2(true);
    const stertDatePass =
      fromdate.slice(8, 10) +
      "/" +
      fromdate.slice(5, 7) +
      "/" +
      fromdate.slice(0, 4);
    const endDatePass =
      todate.slice(8, 10) + "/" + todate.slice(5, 7) + "/" + todate.slice(0, 4);
    console.log("stertDatePass", stertDatePass, "endDatePass", endDatePass);
    if (stertDatePass && endDatePass && selvehicleNo) {
      axios
        .post(
          `${BASEURL}/fuelConsumptionDaterangeVehiclewise   `,
          {
            STARTDATE: stertDatePass,
            ENDDATE: endDatePass,
            VEHICLE: selvehicleNo,
          },
          {
            headers: {
              "auth-token": authToken,
            },
          }
        )
        // setLoading2(false);
        .then((response) => {
          setLoading2(false);
          if (response.data) {
            setData(response.data);
            console.log(response.data);
          } else {
            setData([]); // Set data to an empty array if response.data is null
          }
        })
        .catch((error) => {
          setLoading2(false);
          console.log(error);
        });
    }
  };
  if (isloading) {
    return <LoadingScreen />;
  }
  return (
    <div>
      {/* <div
          className="Unapp_payment"
          style={{
            display: "flex",
            justifyContent: "space-between",
            position: "sticky",
            top: "0",
            left: "0",
            zIndex: "500",
          }}
        >
          <h1 style={{ textAlign: "center" }} className="Titles">
            Tech23 Dashboards
          </h1>
          <div className="nav_1">
            <div className="back_btn">
              {" "}
              <Link
                style={{ textDecoration: "none", color: "white" }}
                to={"/TransportDashboard1"}
              >
                <button className="View">Back</button>
              </Link>
            </div>
          </div>
        </div> */}

      <div className="align__date_1">
        <div className="list_start">
          <div>
            <p>Start Date :</p>
          </div>
          <div>
            <input
              className="input_list_end"
              value={fromdate.split("T")[0]}
              type="date"
              onChange={(e) => {
                setFromdate(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="list_start">
          <div>
            <p>End Date :</p>
          </div>
          <div>
            <input
              className="input_list_end"
              value={todate.split("T")[0]}
              type="date"
              onChange={(e) => {
                setTodate(e.target.value);
              }}
            />
          </div>
          {/* <div>
              <button type="submit" onClick={() => AnalyzeBasedOnClient()}>
                SUBMIT
              </button>
            </div> */}
        </div>
        <div className="analytics_supp_select_1" style={{ zIndex: "100" }}>
          <aside>
            <p className="mt-2  lg:mt-0 lg:ml-[100px]">Vehicle Number :</p>
          </aside>
          <Select
            className="route_select"
            onChange={(e) => {
              setSelVehicleNo(e.value);
            }}
            value={
              selvehicleNo
                ? { label: selvehicleNo, value: selvehicleNo }
                : { label: "Select Vehicle", value: "" }
            }
            options={[
              { value: "", label: "" },
              ...VehicleNo.map((item) => ({
                value: item["VEHICLE REG NO"],
                label: item["VEHICLE REG NO"],
              })),
            ]}
          />
        </div>
      </div>
      <div style={{ width: "90%", margin: "0 auto", marginTop: "15px" }}>
        {isLoading2 ? (
          <Loading2 />
        ) : (
          <>
            {data.length > 0 ? (
              <div>
                <div className="chart__active__buttons">
                  <button
                    className={
                      chartType === "bar"
                        ? "active__chart"
                        : "not__active__chart"
                    }
                    onClick={() => handleButtonClick("bar")}
                  >
                    <IoBarChartOutline />
                  </button>
                  <button
                    className={
                      chartType === "pie"
                        ? "active__chart"
                        : "not__active__chart"
                    }
                    onClick={() => handleButtonClick("pie")}
                  >
                    <FaChartPie />
                  </button>

                  <button
                    className={
                      chartType === "scatter"
                        ? "active__chart"
                        : "not__active__chart"
                    }
                    onClick={() => handleButtonClick("scatter")}
                  >
                    <BiScatterChart />
                  </button>
                </div>

                {chartType === "bar" &&
                Array.isArray(data) &&
                data.length > 0 ? (
                  <ReactApexChart
                    options={{
                      chart: {
                        id: "bar-chart", // Change the ID for the bar chart
                      },
                      xaxis: {
                        categories: data.map((item) => item?.AMOUNT || 0), // Replace null with 0
                        title: {
                          text: "AMOUNT",
                        },
                      },
                      yaxis: {
                        title: {
                          text: "TOTAL LITRES", // Y-axis label
                        },
                      },
                      dataLabels: {
                        enabled: false, // Disable the data labels for bar chart
                      },
                    }}
                    series={[
                      {
                        name: "TOTAL LITRES",
                        data: data.map((item) => item?.LITRES),
                      },
                    ]}
                    type="bar" // Change chart type to "bar"
                    height={300}
                  />
                ) : null}

                {chartType === "pie" && (
                  <ReactApexChart
                    options={{
                      labels: data.map((item) => item?.LITRES),
                      title: {
                        text: "Pie Chart", // Title of the pie chart
                      },
                      dataLabels: {
                        enabled: true, // Enable data labels for pie chart
                      },
                      tooltip: {
                        enabled: true,
                        y: {
                          formatter: function (val, opts) {
                            let index = opts.dataPointIndex;
                            let name = "AMOUNT";
                            return name + ": " + val;
                          },
                        },
                      },
                    }}
                    series={data.map((item) => item?.AMOUNT ?? 0)}
                    type="pie" // Set chart type to "pie"
                    height={300}
                  />
                )}
                {chartType === "scatter" && (
                  <ReactApexChart
                    options={{
                      chart: {
                        id: "scatter-chart", // Change the ID for the scatter chart
                      },
                      xaxis: {
                        title: {
                          text: "AMOUNT",
                        },
                      },
                      yaxis: {
                        title: {
                          text: "TOTAL LITRES", // Y-axis label
                        },
                      },
                      dataLabels: {
                        enabled: false, // Disable the data labels for scatter chart
                      },
                    }}
                    series={[
                      {
                        name: "LITERS",
                        data: data.map((item) => ({
                          x: item?.AMOUNT || 0, // Use AMOUNT as x-coordinate
                          y: item?.LITRES, // Use LITRES as y-coordinate
                        })),
                      },
                    ]}
                    type="scatter" // Change chart type to "scatter"
                    height={300}
                  />
                )}
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "20px",
                  marginTop: "50px",
                  textDecoration: "underline",
                }}
              >
                {selvehicleNo && <p> No Data Available</p>}
                {!selvehicleNo && <p> Select Vehicle</p>}
              </div>
            )}
          </>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default FuelConsumptiondateandvahiclewise;
