import React, { useState, useEffect } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import Select from "react-select";
import axios from "axios";
import "../../../../CSS/AnalyticsBasedOnSupplier.css";
import ReactApexChart from "react-apexcharts";
import LoadingScreen from "../../../../Components/LoadingScreen";
import Loading2 from "../../../../Components/Loading2";
import Footer from "../../../../Components/Footer";

const AnalyticsBasedOnClientAndCategory = () => {
  const [fromdate, setFromdate] = useState(
    new Date(new Date().getFullYear(), 0, 2).toISOString()
  );
  const [todate, setTodate] = useState(new Date().toISOString());
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  var [selclientcode, setSelClientcode] = useState("");
  var [selclientname, setSelClientName] = useState("");
  var [selcategory, setSelCategory] = useState("");
  const [client, setClient] = useState([]);
  const [category, setCategory] = useState([]);
  const [data, setData] = useState([]);
  const [datawithcategory, setDataWithCategory] = useState([]);
  const [datawithclient, setDataWithClient] = useState([]);
  const [datawithcategoryandclient, setDataWithCategoryAndClient] = useState(
    []
  );
  const [isloading, setisloading] = useState(true);
  const [isLoading2, setLoading2] = useState(true);

  useEffect(() => {
    getClient();
    getCategory();
    AnalyticsBasedOnClientAndCategory();
    setisloading(false);
  }, [selcategory, selclientcode, selclientname, fromdate, todate]);

  const getClient = () => {
    axios
      .get(`${BASEURL}/Client`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        setClient(response.data);
      })
      .catch((error) => {
        console.error("Error fetching itemcode:", error);
      });
  };
  const getCategory = () => {
    axios
      .get(`${BASEURL}/Category`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        setCategory(response.data);
      })
      .catch((error) => {
        console.error("Error fetching Category:", error);
      });
  };

  const handleClientCode = (e) => {
    const selectedValue = e;
    const selectedOption = client.find(
      (obj) => obj.CLIENTCODE === selectedValue
    );
    if (selectedValue === "") {
      setSelClientName("");
      setSelClientcode("");
      setDataWithClient([]);
    } else if (selectedOption === "") {
      setSelClientName("");
      setSelClientcode("");
      setDataWithClient([]);
    } else {
      setSelClientcode(selectedValue);
      setSelClientName(selectedOption.CLIENTNAME);
    }
  };

  const handleClientName = (e) => {
    const selectedValue = e;
    const selectedOption = client.find(
      (obj) => obj.CLIENTNAME === selectedValue
    );
    if (selectedValue === "") {
      setSelClientName("");
      setSelClientcode("");
      setDataWithClient([]);
    } else if (selectedOption === "") {
      setSelClientcode("");
      setSelClientName("");
      setDataWithClient([]);
    } else {
      setSelClientName(selectedValue);
      setSelClientcode(selectedOption.CLIENTCODE);
    }
  };

  const AnalyticsBasedOnClientAndCategory = () => {
    if (selclientcode === "" && selcategory === "") {
      selcategory = "null";
      selclientcode = "null";
      setLoading2(true);
      axios
        .post(
          `${BASEURL}/AnalyticsBasedOnClientCategory/${selcategory}/${selclientcode}`,
          {
            Fromdate: moment(fromdate).format("DD/MM/YYYY"),
            Todate: moment(todate).format("DD/MM/YYYY"),
          },
          {
            headers: {
              "auth-token": authToken,
            },
          }
        )
        .then((response) => {
          setLoading2(false);
          setData(response.data);
        })
        .catch((error) => {
          setLoading2(false);
          console.log(error);
        });
    } else if (selcategory !== "" && selclientcode === "") {
      selclientcode = "null";
      setLoading2(true);
      axios
        .post(
          `${BASEURL}/AnalyticsBasedOnClientCategory/${selcategory}/${selclientcode}`,
          {
            Fromdate: moment(fromdate).format("DD/MM/YYYY"),
            Todate: moment(todate).format("DD/MM/YYYY"),
          },
          {
            headers: {
              "auth-token": authToken,
            },
          }
        )
        .then((response) => {
          setLoading2(false);
          setDataWithCategory(response.data);
        })
        .catch((error) => {
          setLoading2(false);
          console.log(error);
        });
    } else if (selcategory === "" && selclientcode !== "") {
      selcategory = "null";
      setLoading2(true);
      axios
        .post(
          `${BASEURL}/AnalyticsBasedOnClientCategory/${selcategory}/${selclientcode}`,
          {
            Fromdate: moment(fromdate).format("DD/MM/YYYY"),
            Todate: moment(todate).format("DD/MM/YYYY"),
          },
          {
            headers: {
              "auth-token": authToken,
            },
          }
        )
        .then((response) => {
          setLoading2(false);
          setDataWithClient(response.data);
        })
        .catch((error) => {
          setLoading2(false);
          console.log(error);
        });
    } else if (selcategory !== "" && selclientcode !== "") {
      setLoading2(true);
      axios
        .post(
          `${BASEURL}/AnalyticsBasedOnClientCategory/${selcategory}/${selclientcode}`,
          {
            Fromdate: moment(fromdate).format("DD/MM/YYYY"),
            Todate: moment(todate).format("DD/MM/YYYY"),
          },
          {
            headers: {
              "auth-token": authToken,
            },
          }
        )

        .then((response) => {
          setLoading2(false);
          setDataWithCategoryAndClient(response.data);
        })
        .catch((error) => {
          setLoading2(false);
          console.log(error);
        });
    }
  };

  if (isloading) {
    return <LoadingScreen />;
  }

  return (
    <div className="text-[14px]">
      {/* <div
        className="Unapp_payment"
        style={{
          display: "flex",
          justifyContent: "space-between",
          position: "sticky",
          top: "0",
          left: "0",
          zIndex: "500",
        }}
      >
        <h1 style={{ textAlign: "center" }} className="Titles">
          Tech23 Dashboards
        </h1>
        <div className="nav_1">
          <div className="back_btn">
            {" "}
            <Link
              style={{ textDecoration: "none", color: "white" }}
              to={"/SALESANALYSIS"}
            >
              <button className="View">Back</button>
            </Link>
          </div>
        </div>
      </div> */}
      {/*  */}{" "}
      <div className=" justify-between p-2">
        <div className="template__card w-[100%]">
          {" "}
          <div className="flex justify-between gap-1">
            <div className=" py-1">
              <p className="w-[100px]">Start Date</p>

              <input
                value={fromdate.split("T")[0]}
                type="date"
                className="w-[120px]"
                onChange={(e) => {
                  if (e.target.value === "") {
                    alert("Please select a valid fromdate");
                  } else {
                    setFromdate(e.target.value);
                  }
                }}
              />
            </div>
            <div className=" py-1">
              <p className="w-[100px]">End Date</p>

              <input
                value={todate.split("T")[0]}
                type="date"
                className="w-[120px]"
                onChange={(e) => {
                  if (e.target.value === "") {
                    alert("Please select a valid todate");
                  } else {
                    setTodate(e.target.value);
                  }
                }}
              />
            </div>
            <div className=" items-center ">
              <p className="w-[120px]">Client Code</p>

              <Select
                className="Select_item_code"
                onChange={(selectedOption) => {
                  if (data.length <= 0) {
                    alert("There is no data to sort!");
                  } else {
                    handleClientCode(selectedOption.value);
                  }
                }}
                value={
                  selclientcode
                    ? { label: selclientcode, value: selclientcode }
                    : { label: "", value: "" }
                }
                options={[
                  { value: "", label: "" },
                  ...client.map((item) => ({
                    value: item.CLIENTCODE,
                    label: item.CLIENTCODE,
                  })),
                ]}
              />
            </div>
            <div className=" items-center">
              <p className="w-[120px]">Client Name</p>

              <Select
                className="Select_item_name p-0"
                onChange={(selectedOption) => {
                  if (data.length <= 0) {
                    alert("There is no data to sort!");
                  } else {
                    handleClientName(selectedOption.value);
                  }
                }}
                value={
                  selclientname
                    ? { label: selclientname, value: selclientname }
                    : { label: "", value: "" }
                }
                options={[
                  { value: "", label: "" },
                  ...client.map((item) => ({
                    value: item.CLIENTNAME,
                    label: item.CLIENTNAME,
                  })),
                ]}
              />
            </div>{" "}
            <div className=" items-center">
              <p className="w-[100px]">Category</p>

              <Select
                styles={{
                  control: (styles) => ({
                    ...styles,
                    height: "30px",

                    borderRadius: "4px",
                  }),
                }}
                className="Select_Category"
                onChange={(e) => {
                  if (data.length <= 0) {
                    alert("There is no data to sort!");
                  } else {
                    setSelCategory(e.value);
                    if (e.value === "") {
                      setDataWithCategory([]);
                    }
                  }
                }}
                value={
                  selcategory
                    ? { label: selcategory, value: selcategory }
                    : { label: "", value: "" }
                }
                options={[
                  { value: "", label: "" },
                  ...category.map((item) => ({
                    value: item.CATEGORY,
                    label: item.CATEGORY,
                  })),
                ]}
              />
            </div>
          </div>
        </div>
        {/* <div className="template__card">
          {" "}
          <div className="flex flex-col gap-1"></div>
        </div> */}
      </div>
      {/* <div className="align__date">
        <div className="">
          <div>
            <p>Start Date :</p>
          </div>
          <div>
            <input
              value={fromdate.split("T")[0]}
              type="date"
              onChange={(e) => {
                if (e.target.value === "") {
                  alert("Please select a valid fromdate");
                } else {
                  setFromdate(e.target.value);
                }
              }}
            />
          </div>

          <div>
            <p>End Date :</p>
          </div>
          <div>
            <input
              value={todate.split("T")[0]}
              type="date"
              onChange={(e) => {
                if (e.target.value === "") {
                  alert("Please select a valid todate");
                } else {
                  setTodate(e.target.value);
                }
              }}
            />
          </div>
        </div>
        <div className="Product_analytics_item">
          <div className="analytics_product_select">
            <aside>
              <p>Client Code :</p>
            </aside>
            <Select
              className="Select_item_code"
              onChange={(selectedOption) => {
                if (data.length <= 0) {
                  alert("There is no data to sort!");
                } else {
                  handleClientCode(selectedOption.value);
                }
              }}
              value={
                selclientcode
                  ? { label: selclientcode, value: selclientcode }
                  : { label: "", value: "" }
              }
              options={[
                { value: "", label: "" },
                ...client.map((item) => ({
                  value: item.CLIENTCODE,
                  label: item.CLIENTCODE,
                })),
              ]}
            />
          </div>
          <div className="analytics_product_select">
            <aside>
              <p>Client Name :</p>
            </aside>
            <Select
              className="Select_item_name"
              onChange={(selectedOption) => {
                if (data.length <= 0) {
                  alert("There is no data to sort!");
                } else {
                  handleClientName(selectedOption.value);
                }
              }}
              value={
                selclientname
                  ? { label: selclientname, value: selclientname }
                  : { label: "", value: "" }
              }
              options={[
                { value: "", label: "" },
                ...client.map((item) => ({
                  value: item.CLIENTNAME,
                  label: item.CLIENTNAME,
                })),
              ]}
            />
          </div>
        </div>
      </div> */}
      {/* <main>
        <div className="Supp_analytics_Supp_Name">
          <div className="analytics_Category_select">
            <aside>
              <p>Category :</p>
            </aside>
            <Select
              className="Select_Category"
              onChange={(e) => {
                if (data.length <= 0) {
                  alert("There is no data to sort!");
                } else {
                  setSelCategory(e.value);
                  if (e.value === "") {
                    setDataWithCategory([]);
                  }
                }
              }}
              value={
                selcategory
                  ? { label: selcategory, value: selcategory }
                  : { label: "", value: "" }
              }
              options={[
                { value: "", label: "" },
                ...category.map((item) => ({
                  value: item.CATEGORY,
                  label: item.CATEGORY,
                })),
              ]}
            />
          </div>
        </div>
      </main> */}
      <div style={{ width: "80%", margin: "0 auto", marginTop: "15px" }}>
        {isLoading2 ? (
          <Loading2 />
        ) : (
          <>
            {" "}
            {selcategory && !selclientcode && datawithcategory.length > 0 ? (
              <ReactApexChart
                options={{
                  chart: {
                    id: "line-chart", // Change the ID for the line chart
                  },
                  xaxis: {
                    categories: datawithcategory.map((item) =>
                      moment(item.MONTHNAME).format("MMM-YYYY")
                    ),
                    title: {
                      text: "Months", // X-axis label
                    },
                  },
                  yaxis: {
                    title: {
                      text: "Amount", // Y-axis label
                    },
                  },
                  dataLabels: {
                    enabled: false, // Disable the data labels for line chart
                  },
                }}
                series={[
                  {
                    name: "Amount",
                    data: datawithcategory.map((item) => item.AMOUNT),
                  },
                ]}
                type="line"
                height={300}
              />
            ) : selclientcode && !selcategory && datawithclient.length > 0 ? (
              <ReactApexChart
                options={{
                  chart: {
                    id: "line-chart", // Change the ID for the line chart
                  },
                  xaxis: {
                    categories: datawithclient.map((item) =>
                      moment(item.MONTHNAME).format("MMM-YYYY")
                    ),
                    title: {
                      text: "Months", // X-axis label
                    },
                  },
                  yaxis: {
                    title: {
                      text: "Amount", // Y-axis label
                    },
                  },
                  dataLabels: {
                    enabled: false, // Disable the data labels for line chart
                  },
                }}
                series={[
                  {
                    name: "Amount",
                    data: datawithclient.map((item) => item.AMOUNT),
                  },
                ]}
                type="line"
                height={300}
              />
            ) : selcategory &&
              selclientcode &&
              datawithcategoryandclient.length > 0 ? (
              <ReactApexChart
                options={{
                  chart: {
                    id: "line-chart", // Change the ID for the line chart
                  },
                  xaxis: {
                    categories: datawithcategoryandclient.map((item) =>
                      moment(item.MONTHNAME).format("MMM-YYYY")
                    ),
                    title: {
                      text: "Months", // X-axis label
                    },
                  },
                  yaxis: {
                    title: {
                      text: "Amount", // Y-axis label
                    },
                  },
                  dataLabels: {
                    enabled: false, // Disable the data labels for line chart
                  },
                }}
                series={[
                  {
                    name: "Amount",
                    data: datawithcategoryandclient.map((item) => item.AMOUNT),
                  },
                ]}
                type="line"
                height={300}
              />
            ) : !selcategory && !selclientcode && data.length > 0 ? (
              <ReactApexChart
                options={{
                  chart: {
                    id: "line-chart", // Change the ID for the line chart
                  },
                  xaxis: {
                    categories: data.map((item) => item["CLIENT NAME"]),
                    title: {
                      text: "Client Name", // X-axis label
                    },
                  },
                  yaxis: {
                    title: {
                      text: "Revenue", // Y-axis label
                    },
                  },
                  dataLabels: {
                    enabled: false, // Disable the data labels for line chart
                  },
                }}
                series={[
                  {
                    name: "Revenue",
                    data: data.map((item) => item.AMOUNT),
                  },
                ]}
                type="line" // Change chart type to "line"
                height={300}
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "20px",
                  marginTop: "50px",
                  textDecoration: "underline",
                }}
              >
                NO RESULTS FOUND
              </div>
            )}{" "}
          </>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default AnalyticsBasedOnClientAndCategory;
