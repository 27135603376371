import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUser } from "../../../../Components/features/userSlice";
import LoadingScreen from "../../../../Components/LoadingScreen";
import Footer from "../../../../Components/Footer";
import moment from "moment";
import "../../../../CSS/TransportUnapproved.css";

const StoreandFuelDetailsPO = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const location = useLocation();
  const viewname = location.state?.value;
  const departmentname = location.state?.name;
  const user = useSelector(selectUser);
  const email = user.email;
  const navigate = useNavigate();
  const [isloading, setisloading] = useState(true);
  const authToken = window.sessionStorage.getItem("auth-token");
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const currentDate = new Date();
  const startOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  const localStartOfMonth = new Date(
    startOfMonth.getTime() - startOfMonth.getTimezoneOffset() * 60000
  );
  const [startdate, setstartdate] = useState(localStartOfMonth.toISOString());
  const [enddate, setEnddate] = useState(currentDate.toISOString());
  const [selectedRows, setSelectedRows] = useState([]);
  const [currentTime, setCurrentTime] = useState(
    new Date().toLocaleTimeString()
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date().toLocaleTimeString());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    axios
      .get(`${BASEURL}/getapprovaldetails?VIEWNAME=${viewname}`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        setData(response.data);
        setisloading(false);
      })
      .catch((err) => {
        console.log("error while fetching initial data", err);
        setisloading(false);
      });
  }, []);

  const filteredData = data.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);
    const sDate = item.PODATE;
    const eDate = item.PODATE;

    if (searchData.length === 0 && !startdate && !enddate) {
      return true;
    }

    const textMatch = searchData.every((term) => {
      return ["PONO", "DIVISION", "SUPPLIERNAME", "DAYS"].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });

    const dateMatch =
      (!startdate || sDate >= startdate) && (!enddate || eDate <= enddate);

    return textMatch && dateMatch;
  });

  const toggleRowSelection = (value, isChecked) => {
    const selectedIndex = selectedRows.findIndex(
      (row) => row.PONO === value.PONO && row.PODATE === value.PODATE
    );
    let updatedSelection = [...selectedRows];

    if (!isChecked) {
      if (selectedIndex !== -1) {
        updatedSelection.splice(selectedIndex, 1);
      }
    } else {
      if (selectedIndex === -1) {
        updatedSelection.push(value);
      } else {
        updatedSelection[selectedIndex] = value;
      }
    }
    console.log(updatedSelection);
    setSelectedRows(updatedSelection);
  };

  // ************************** APPROVE *********************************
  async function Approve(name) {
    setisloading(true);
    try {
      const headerPromises = selectedRows.map((value) => {
        return axios.post(`${BASEURL}/approvedocuments`, {
          DOCUMENTTYPE: departmentname,
          DOCUMENTNO: Number(value.PONO),
          APPROVED: name === "approve" ? 1 : 0,
          APPROVALDATE: currentDate,
          APPROVALTIME: currentTime,
          APPROVALBY: email.split("@")[0].toUpperCase(),
          APPROVEDAMOUNT: Number(value.POAMOUNT),
        });
      });

      const detailResponses = await Promise.all(headerPromises);
      setisloading(false);
      alert(detailResponses[0].data.message);
    } catch (error) {
      setisloading(false);
      console.error("Error While Approving Or Canceling", error);
    }
  }

  if (isloading) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          position: "sticky",
          top: "0",
          left: "0",
          zIndex: "500",
        }}
        className="Unapp_payment"
      >
        <h1 style={{ textAlign: "center" }} className="Titles">
          Tech23 Dashboards
        </h1>
        <div className="nav_1">
          <div className="back_btn">
            {" "}
            <Link
              style={{ textDecoration: "none", color: "white" }}
              to={"/StoreandFuelDashboard"}
            >
              <button className="View">Back</button>
            </Link>
          </div>
        </div>
      </div>

      <div>
        <div className="interface__name">
          <h2>{departmentname}</h2>
        </div>
      </div>

      <div className="header__section">
        <div className="filter__section">
          <div>
            <p>Search</p>
          </div>
          <div>
            <input
              type="text"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            ></input>
          </div>
          <p>Start Date:</p>
          <input
            type="date"
            onChange={(e) => {
              if (e.target.value > enddate) {
                alert("start Date Cannot be greater than end date");
              } else {
                setstartdate(e.target.value);
              }
            }}
            value={startdate.split("T")[0]}
          ></input>
          <p>End Date:</p>
          <input
            type="date"
            onChange={(e) => {
              if (e.target.value < startdate) {
                alert("end Date Cannot be less than start date");
              } else {
                setEnddate(e.target.value);
              }
            }}
            value={enddate.split("T")[0]}
          ></input>
        </div>
        <div className="buttons__section">
          <button
            className="button__style"
            onClick={async () => {
              if (selectedRows.length > 0) {
                await Approve("approve");
                window.location.reload();
              } else {
                alert("Kindly Select a Document To Approve!");
              }
            }}
          >
            Approve
          </button>
          <button
            className="button__style"
            onClick={async () => {
              if (selectedRows.length > 0) {
                await Approve("cancel");
                window.location.reload();
              } else {
                alert("Kindly Select a Document To Cancel!");
              }
            }}
          >
            Cancel
          </button>
        </div>
      </div>

      {/* table */}
      <div className="scroll">
        <table style={{ textTransform: "uppercase" }} className="table__style">
          <thead className="table__header">
            <tr className="Payment_tr_3">
              <th style={{ width: "50px" }}></th>
              <th className="text_left">PO NO</th>
              <th className="text_left" style={{ paddingLeft: "25px" }}>
                PO DATE
              </th>
              <th className="text_left">DIVISION</th>
              <th className="text_left" style={{ paddingLeft: "10px" }}>
                SUPPLIER NAME
              </th>
              <th className="text_left">DAYS</th>
              <th className="text_left">PO AMOUNT</th>
            </tr>
          </thead>

          <tbody className="Payments_tbody">
            {filteredData.map((pd) => {
              return (
                <tr className="Payments_tbody_tr" key={pd.PONO}>
                  <td style={{ maxWidth: "20px", width: "20px" }}>
                    <input
                      className="checkbox__style"
                      type="checkbox"
                      checked={selectedRows.includes(pd)}
                      onChange={(e) => toggleRowSelection(pd, e.target.checked)}
                      onClick={(e) => e.stopPropagation()}
                    />
                  </td>
                  <td className="text_right" style={{ paddingRight: "110px" }}>
                    {pd.PONO ? pd.PONO : "N/A"}
                  </td>

                  <td className="text_right" style={{ paddingRight: "65px" }}>
                    {pd.PODATE ? moment(pd.PODATE).format("DD/MM/YYYY") : "N/A"}
                  </td>
                  <td className="text_left">
                    {pd.DIVISION ? pd.DIVISION : "N/A"}
                  </td>
                  <td
                    className="text_left"
                    style={{
                      width: "350px",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      paddingLeft: "10px",
                    }}
                  >
                    {pd.SUPPLIERNAME ? pd.SUPPLIERNAME : "N/A"}
                  </td>
                  <td className="text_right" style={{ paddingRight: "116px" }}>
                    {pd.DAYS ? pd.DAYS : "N/A"}
                  </td>
                  <td className="text_right" style={{ paddingRight: "64px" }}>
                    {pd.POAMOUNT
                      ? pd.POAMOUNT.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : "N/A"}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Footer />
    </div>
  );
};

export default StoreandFuelDetailsPO;
