import React from "react";
import "../CSS/Navbar.css";
import { logout } from "./features/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  selectUser,
  setSelectedDepartment,
  resetData,
} from "./features/userSlice";

const Navbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const userEmail = user.email;
  console.log(user);

  const logoutOff = (e) => {
    e.preventDefault();
    dispatch(logout());
    dispatch(setSelectedDepartment(""));
    dispatch(resetData());
    navigate("/");
  };

  return (
    <nav className="">
      <div
        className="flex justify-between items-center bg-[#002d62] text-white "
        style={{
          position: "sticky",
          top: "0",
          left: "0",
          zIndex: 9999,
        }}
      >
        <h1 style={{ textAlign: "center" }} className="text-[25px]">
          Tech23 Dashboards
        </h1>

        <div className="flex items-center gap-[50px]">
          <p className="">
            Logged in as : {userEmail.split("@")[0].toUpperCase()}
          </p>
          <div className="back_btn">
            {" "}
            <button className="logout-button" onClick={(e) => logoutOff(e)}>
              Logout
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
