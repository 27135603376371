import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUser } from "../../Components/features/userSlice";
import LoadingScreen from "../../Components/LoadingScreen";
import Footer from "../../Components/Footer";
import moment from "moment";

const ViewPurchaseOrder = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const [viewPurchaseOrder, setViewPurchaseOrder] = useState([]);
  const location = useLocation();
  const propValue = location.state.value;
  const user = useSelector(selectUser);
  const email = user.email;
  const navigate = useNavigate();
  const [isloading, setisloading] = useState(true);
  const authToken = window.sessionStorage.getItem("auth-token");

  function getViewPurchaseOrder() {
    axios
      .post(
        `${BASEURL}/ViewPurchaseOrder`,
        {
          pono: propValue,
        },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((res) => {
        setViewPurchaseOrder(res?.data);
        setisloading(false);
      })
      .catch((err) => {
        setisloading(false);
        console.log("error while fetching view purchase order details", err);
      });
  }
  useEffect(() => {
    getViewPurchaseOrder();
  }, []);

  function Approve() {
    const alertConfirm = window.confirm("Are you sure you want to Approve");
    if (alertConfirm === true) {
      axios
        .post(
          `${BASEURL}/ApprovePurchaseOrders`,
          {
            data: { docno: propValue },
            mail: email.split("@")[0].toUpperCase(),
          },
          {
            headers: {
              "auth-token": authToken,
            },
          }
        )
        .then((response) => {
          alert("Approved Succesfully !");
          navigate("/UnapprovedPurchaseOrders");
        });
      // window.location.reload();
    }
  }

  if (isloading) {
    return <LoadingScreen />;
  }

  return (
    <>
      <div>
        <div
          className="Unapp_payment"
          style={{
            display: "flex",
            justifyContent: "space-between",
            position: "sticky",
            top: "0",
            left: "0",
            zIndex: "500",
          }}
        >
          <h1 style={{ textAlign: "center" }} className="Titles">
            Tech23 Dashboards
          </h1>
          {/* <div className="nav_1">
          <div className="Approve">
            <button
              className="View_1"
              onClick={(e) => {
                e.preventDefault();
                Approve();
              }}
            >
              Approve
            </button>
          </div>
        </div> */}
          <div className="back_btn">
            {" "}
            <Link
              style={{ textDecoration: "none", color: "white" }}
              to={"/UnapprovedPurchaseOrders"}
            >
              <button className="View">Back</button>{" "}
            </Link>
          </div>
        </div>

        <div>
          <div className="h2">
            <h2>Unapproved Purchase Orders</h2>
          </div>
          <div style={{ backgroundColor: "aliceblue", marginTop: "20px" }}>
            <div className="purchase__orders">
              <div>
                <label>
                  <b> PO NO </b>
                </label>
                <span> : </span>
                <label style={{ color: "red" }}>
                  {viewPurchaseOrder[0]?.PONO ? viewPurchaseOrder[0].PONO : ""}
                </label>
              </div>
              <div>
                <label>
                  <b>PO DATE</b>
                </label>
                <span> : </span>
                <label style={{ color: "red" }}>
                  {moment(
                    viewPurchaseOrder[0]?.PODATE
                      ? viewPurchaseOrder[0].PODATE
                      : ""
                  ).format("DD/MM/YYYY")}
                </label>
              </div>
              <div>
                <label>
                  <b>SUPPLIER NAME</b>
                </label>
                <span> : </span>
                <label style={{ color: "red" }}>
                  {viewPurchaseOrder[0]?.["SUPPLIER NAME"]
                    ? viewPurchaseOrder[0]["SUPPLIER NAME"]
                    : ""}
                </label>
              </div>
            </div>
            <div className="supp_addr">
              <div style={{ display: "flex", marginLeft: "18px", gap: "10px" }}>
                <label>
                  <span>
                    <b> SUPPLIER ADDRESS </b>
                  </span>
                </label>
                <span> : </span>
                <textarea className="Narration_1">
                  {viewPurchaseOrder[0]?.["SUPPLIER ADDR1"]
                    ? viewPurchaseOrder[0]["SUPPLIER ADDR1"]
                    : ""}
                </textarea>
              </div>

              <div>
                <button
                  className="button_2"
                  onClick={(e) => {
                    e.preventDefault();
                    Approve();
                  }}
                >
                  Approve
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="scroll">
          <table
            style={{ textTransform: "uppercase" }}
            className="Payment_data_header"
          >
            <thead className="thead">
              <tr className="Payment_tr_view">
                {/* <th style={{ padding: "0px 10px" }}>PO NO</th> */}
                <th>ITEM CODE</th>
                <th> ITEM DESCRIPTION</th>

                <th>ITEM AMOUNT</th>
                <th className="header_po_mode_of_pay">PO MODE OF PAY</th>

                <th>CURRENCY</th>
              </tr>
            </thead>

            <tbody className="Payments_tbody">
              {viewPurchaseOrder.map((or) => {
                return (
                  <tr className="Payments_tbody_tr" key={or.PONO}>
                    {/* <td className="td po_no">{or.PONO}</td> */}
                    <td className="po_item_code_1">
                      {or.POITEMCODE ? or.POITEMCODE : "N/A"}
                    </td>

                    <td className="po_item_descrip">
                      {or.POITEMDESCRIPTION ? or.POITEMDESCRIPTION : "N/A"}
                    </td>
                    <td className="po_item_amount_1">
                      {or.POITEMAMOUNT !== null
                        ? or.POITEMAMOUNT.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : "N/A"}
                    </td>

                    <td className="po__pay">
                      {or.POMODEOFPAY ? or.POMODEOFPAY : "N/A"}
                    </td>

                    <td className="PO_CURREN">
                      {or.POCURRENCY ? or.POCURRENCY : "N/A"}
                    </td>
                  </tr>
                );
              })}
            </tbody>
            {/* <tfoot>
          <tr>
            <td colSpan="2" style={{ textAlign: "right" }}>
              Total CR Amount:
            </td>
            <td>
              {totalCRAmount.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </td>
            <td colSpan="2"></td>
          </tr>
        </tfoot> */}
          </table>
        </div>
      </div>
      <div style={{ marginTop: "100px" }}>
        <Footer />
      </div>
    </>
  );
};

export default ViewPurchaseOrder;
