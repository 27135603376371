import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import moment from "moment";
import axios from "axios";
import ReactApexChart from "react-apexcharts";
import LoadingScreen from "../../../Components/LoadingScreen";
import Loading2 from "../../../Components/Loading2";
import { useNavigate } from "react-router-dom";
import TransportDashboardsNavbar from "../../../Components/TransportDashboardsNavbar";
// import FuelConsumptionClientWise from "./ConsumptionFuelAnalytics/FuelConsumptionClientWise";
import FuelConsumptionperiodic from "./ConsumptionFuelAnalytics/FuelConsumptionperiodic";
// import FuelConsumptionvehiclewise from "./ConsumptionFuelAnalytics/FuelConsumptionvehiclewise";

import FuelPurchaseSelectedSupplier from "./ProcurementFuelAnalytics/FuelPurchaseSelectedSupplier";
import FuelPurchaseSupplierWise from "./ProcurementFuelAnalytics/FuelPurchaseSupplierEise";
import FuelPurchasesMonthly from "./ProcurementFuelAnalytics/FuelPurchasesMonthly";
import FuelConsumptionperiodicvehiclewise from "./ConsumptionFuelAnalytics/FuelConsumptionperiodicvehiclewise";
import FuelConsumptionRoutewise from "./ConsumptionFuelAnalytics/FuelConsumptionRoutewise";
import FuelConsumptionDaterangeVehiclewise from "./ConsumptionFuelAnalytics/FuelConsumptionDaterangeVehiclewise";
import FuelConsumptionDriverWise from "./ConsumptionFuelAnalytics/FuelConsumptionDriverWise";
import FuelConsumptionClientWise from "./ConsumptionFuelAnalytics/FuelConsumptionClientWise";
const FuelExpense = () => {
  const [selectedComponent, setSelectedComponent] = useState({
    value: "FuelPurchaseSupplierWise",
    label: "Fuel Purchase Supplier Wise",
  });

  const options = [
    {
      value: "FuelPurchaseSupplierWise",
      label: "Fuel Purchase Supplier Wise",
    },
    {
      value: "FuelPurchasesMonthly",
      label: "Fuel Purchases Monthly",
    },
    {
      value: "FuelPurchaseSelectedSupplier",
      label: "Fuel Purchase Selected Supplier",
    },
  ];
  const handleChange = (selectedOption) => {
    setSelectedComponent(selectedOption);
  };
  const [selectedComponent2, setSelectedComponent2] = useState({
    value: "FuelConsumptionperiodic",
    label: "Fuel Consumption periodic",
  });
  const options2 = [
    // {
    //   value: "FuelConsumptionvehiclewise",
    //   label: "FuelConsumptionvehiclewise",
    // },
    {
      value: "FuelConsumptionperiodic",
      label: "Fuel Consumption periodic",
    },
    {
      value: "FuelConsumptionperiodicvehiclewise",
      label: "Fuel Consumption periodic vehicle wise",
    },
    {
      value: "FuelConsumptionDaterangeVehiclewise",
      label: "Fuel Consumption Date range Vehicle wise",
    },
    {
      value: "FuelConsumptionRoutewise",
      label: "Fuel Consumption Route wise",
    },
    {
      value: "FuelConsumptionClientWise",
      label: "Fuel Consumption Client Wise",
    },
    {
      value: "FuelConsumptionDriverWise",
      label: "Fuel Consumption Driver Wise",
    },
    // {
    //   value: "FuelConsumptionvehiclewise",
    //   label: "Fuel Consumption vehicle wise",
    // },
  ];
  const handleChange2 = (selectedOption) => {
    setSelectedComponent2(selectedOption);
  };
  const [tabCLick, setTabCLick] = useState(true);
  const [tabCLick2, setTabCLick2] = useState(false);
  function tabChange1() {
    setTabCLick(true);
    setTabCLick2(false);
  }
  function tabChange2() {
    setTabCLick(false);
    setTabCLick2(true);
  }

  const [isloading, setisloading] = useState(false);

  if (isloading) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <TransportDashboardsNavbar />

      <div className="fuel__dynamic__tab">
        <button
          className={tabCLick ? "active" : ""}
          onClick={() => tabChange1()}
        >
          PROCUREMENT OF FUEL
        </button>
        <button
          className={!tabCLick ? "active" : ""}
          onClick={() => tabChange2()}
        >
          CONSUMPTION OF FUEL
        </button>
      </div>

      {tabCLick && (
        <section>
          <div>
            <header className="header__of__main__dashboard flex justify-between items-end px-3">
              <h2 className="header__of__page">PROCUREMENT OF FUEL</h2>

              <div className="w-[400px] mx-2 " style={{ zIndex: 999 }}>
                <Select
                  value={selectedComponent}
                  onChange={handleChange}
                  options={options}
                  placeholder="Select Component"
                />
              </div>
            </header>
          </div>
          <div>
            {selectedComponent &&
              selectedComponent.value === "FuelPurchaseSelectedSupplier" && (
                <FuelPurchaseSelectedSupplier />
              )}
            {selectedComponent &&
              selectedComponent.value === "FuelPurchaseSupplierWise" && (
                <FuelPurchaseSupplierWise />
              )}
            {selectedComponent &&
              selectedComponent.value === "FuelPurchasesMonthly" && (
                <FuelPurchasesMonthly />
              )}
          </div>
        </section>
      )}

      {tabCLick2 && (
        <section>
          <div>
            <header className="header__of__main__dashboard flex justify-between items-end px-3">
              <h2 className="header__of__page"> CONSUMPTION OF FUEL</h2>

              <div className="w-[400px] mx-2 " style={{ zIndex: 999 }}>
                <Select
                  value={selectedComponent2}
                  onChange={handleChange2}
                  options={options2}
                  placeholder="Select Component"
                />
              </div>
            </header>
          </div>
          <div>
            {/* {selectedComponent2 &&
              selectedComponent2.value === "FuelConsumptionClientWise" && (
                <FuelConsumptionClientWise />
              )} */}
            {selectedComponent2 &&
              selectedComponent2.value === "FuelConsumptionperiodic" && (
                <FuelConsumptionperiodic />
              )}
            {selectedComponent2 &&
              selectedComponent2.value ===
                "FuelConsumptionperiodicvehiclewise" && (
                <FuelConsumptionperiodicvehiclewise />
              )}
            {selectedComponent2 &&
              selectedComponent2.value === "FuelConsumptionRoutewise" && (
                <FuelConsumptionRoutewise />
              )}
            {selectedComponent2 &&
              selectedComponent2.value ===
                "FuelConsumptionDaterangeVehiclewise" && (
                <FuelConsumptionDaterangeVehiclewise />
              )}
            {selectedComponent2 &&
              selectedComponent2.value === "FuelConsumptionClientWise" && (
                <FuelConsumptionClientWise />
              )}
            {selectedComponent2 &&
              selectedComponent2.value === "FuelConsumptionDriverWise" && (
                <FuelConsumptionDriverWise />
              )}
            {/* {selectedComponent2 &&
              selectedComponent2.value === "FuelConsumptionvehiclewise" && (
                <FuelConsumptionvehiclewise />
              )} */}
          </div>
        </section>
      )}
    </div>
  );
};

export default FuelExpense;
