import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUser } from "../../Components/features/userSlice";
import LoadingScreen from "../../Components/LoadingScreen";
import Footer from "../../Components/Footer";
import moment from "moment";

const ViewPettyCashVoucher = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const [viewPettycash, setViewPettycash] = useState([]);
  const location = useLocation();
  const propValue = location.state.value;
  const user = useSelector(selectUser);
  const email = user.email;
  const navigate = useNavigate();
  let totalCRAmount = 0;
  const [isloading, setisloading] = useState(true);
  const authToken = window.sessionStorage.getItem("auth-token");

  viewPettycash.forEach((pd) => {
    if (pd?.CRDR === "CR") {
      totalCRAmount += pd?.AMOUNT;
    }
  });

  function getViewPettyCashVoucher() {
    axios
      .post(
        `${BASEURL}/ViewPettyCashVouchers`,
        {
          docno: propValue,
        },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((res) => {
        setViewPettycash(res?.data);
        setisloading(false);
      })
      .catch((err) => {
        console.log("error while fetching View Petty Cash Voucher", err);
      });
  }

  useEffect(() => {
    getViewPettyCashVoucher();
  }, []);

  function Approve() {
    const alertConfirm = window.confirm("Are you sure you want to Approve");
    if (alertConfirm === true) {
      axios
        .post(
          `${BASEURL}/ApprovePettyCashVoucher`,
          {
            data: { docno: propValue },
            mail: email.split("@")[0].toUpperCase(),
          },
          {
            headers: {
              "auth-token": authToken,
            },
          }
        )
        .then((response) => {
          alert("Approved Succesfully !");
          navigate("/UnapprovedPettyCashVouchers");
        });
      // window.location.reload();
    }
  }

  if (isloading) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          position: "sticky",
          top: "0",
          left: "0",
          zIndex: "500",
        }}
        className="Unapp_payment"
      >
        <h1 style={{ textAlign: "center" }} className="Titles">
          Tech23 Dashboards
        </h1>
        <div className="nav_1">
          {/* <div className="Approve_4">
            <button
              className="View"
              style={{ width: "110px" }}
              onClick={(e) => {
                e.preventDefault();
                Approve();
              }}
            >
              Approve
            </button>
          </div> */}
          <div className="back_btn">
            {" "}
            <Link
              style={{ textDecoration: "none", color: "white" }}
              to={"/UnapprovedPettyCashVouchers"}
            >
              <button className="View">Back</button>
            </Link>
          </div>
        </div>
      </div>

      <div>
        <div className="h2">
          <h2>Unapproved Petty Cash Vouchers</h2>
        </div>
        <div className="petty_cash_vouchers">
          <div>
            <label>DOCNO</label>
            <span> : </span>
            <label>
              {viewPettycash[0]?.DOCNO ? viewPettycash[0].DOCNO : ""}
            </label>
          </div>
          <div>
            <label>DATE</label>
            <span> : </span>
            <label>
              {moment(
                viewPettycash[0]?.PAYMENTDATE
                  ? viewPettycash[0].PAYMENTDATE
                  : ""
              ).format("DD/MM/YY")}
            </label>
          </div>
          <div>
            <label>PROCESSED BY</label>
            <span> : </span>
            <label>
              {viewPettycash[0]?.PROCESSEDBY
                ? viewPettycash[0].PROCESSEDBY
                : ""}
            </label>
          </div>
        </div>

        <div className="Narration">
          <div style={{ display: "flex" }}>
            <label>
              <span> NARRATION </span>
            </label>
            <span> : </span>
            <textarea className="Narration_1">
              {viewPettycash[0]?.NARRATION ? viewPettycash[0].NARRATION : ""}
            </textarea>
          </div>
          <div>
            <button
              className="button_2"
              onClick={(e) => {
                e.preventDefault();
                Approve();
              }}
            >
              Approve
            </button>
          </div>
        </div>
      </div>

      {/* table */}

      <div className="scroll">
        <table
          style={{ textTransform: "uppercase" }}
          className="Payment_data_header"
        >
          <thead className="thead">
            <tr className="Payment_tr_2">
              {/* <th style={{}}>DOC NO</th> */}
              {/* <th style={{}}>ACCOUNT CODE</th> */}
              <th style={{}}>ACCOUNT NAME</th>
              {/* <th style={{}}>ACCOUNT TYPE</th> */}
              <th className="View_dr_cr_header">DR/CR</th>
              <th className="view_petty_amount_header">AMOUNT</th>
              <th style={{}}>CURRENCY</th>
              <th className="header_conv_rate">CONVERSION RATE</th>
            </tr>
          </thead>

          <tbody className="Payments_tbody">
            {viewPettycash.map((vo) => {
              return (
                <tr className="Payments_tbody_tr" key={vo.DOCNO}>
                  {/* <td className="td view_doc_no">{vo.DOCNO}</td> */}
                  {/* <td className="td view_acc_code">{vo.ACCOUNTCODE}</td> */}

                  <td className="td view_acc_name">
                    {vo.ACCOUNTNAME ? vo.ACCOUNTNAME : "N/A"}
                  </td>
                  {/* <td className="td view_acc_type" style={{}}>
                    {vo.ACCOUNTTYPE}
                  </td> */}
                  <td className="td view_cr_dr">{vo.CRDR ? vo.CRDR : "N/A"}</td>
                  <td className="td view_amount">
                    {vo.AMOUNT
                      ? vo.AMOUNT.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : "N/A"}
                  </td>
                  <td className="td view_acc_code">
                    {vo.CURRENCY ? vo.CURRENCY : "N/A"}
                  </td>
                  <td className="td view_conv_rate">
                    {vo.CONVRATE ? vo.CONVRATE : "N/A"}
                  </td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <td
                colSpan="2"
                style={{ textAlign: "right", paddingRight: "24px" }}
              >
                Total CR Amount:
              </td>
              <td className="total_cr_amount">
                {totalCRAmount
                  ? totalCRAmount.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : "N/A"}
              </td>
              {/* <td colSpan="2"></td> */}
            </tr>
          </tfoot>
        </table>
      </div>
      <Footer />
    </div>
  );
};

export default ViewPettyCashVoucher;
