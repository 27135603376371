import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";
import LoadingScreen from "../../Components/LoadingScreen";

const UnCrNo = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const [uncrno, setuncrno] = useState([]);
  const [isloading, setisloading] = useState(false);
  const authToken = window.sessionStorage.getItem("auth-token");

  useEffect(() => {
    setisloading(true);
    getUnapprovedDetails();
  }, []);
  const getUnapprovedDetails = async () => {
    const UnCrNo = await axios.get(`${BASEURL}/UnCrNo`, {
      headers: {
        "auth-token": authToken,
      },
    });
    setuncrno(UnCrNo.data);
    setisloading(false);
  };
  if (isloading) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <div
        className="Unapp_payment"
        style={{
          display: "flex",
          justifyContent: "space-between",
          position: "sticky",
          top: "0",
          left: "0",
          zIndex: "500",
        }}
      >
        <h1 style={{ textAlign: "center" }} className="Titles">
          Tech23 Dashboards
        </h1>
        <div className="back_btn">
          {" "}
          <Link
            style={{ textDecoration: "none", color: "white" }}
            to={"/HomePage"}
          >
            {" "}
            <div className="button_1">
              <div className="Approve_4">
                <button className="View" style={{ width: "110px" }}>
                  Approve
                </button>
              </div>
              <div>
                <button className="View">Back</button>{" "}
              </div>
            </div>
          </Link>
        </div>
      </div>
      <div className="scroll">
        <table
          style={{ textTransform: "uppercase" }}
          className="Payment_data_header"
        >
          <thead className="thead">
            <tr className="Payment_tr">
              <th style={{ padding: "0px 10px" }}>DOC NO</th>
              <th style={{ paddingLeft: "15px" }}>DATE</th>
              <th style={{ textAlign: "left" }}>ACCOUNT</th>
              <th style={{ textAlign: "left", paddingLeft: "30px" }}>AMOUNT</th>
              <th style={{ textAlign: "left" }}>CURRENCY</th>
              <th style={{ width: "100px" }}>Action</th>
            </tr>
          </thead>

          <tbody className="Payments_tbody">
            {uncrno.map((cr) => {
              return (
                <tr className="Payments_tbody_tr" key={cr.DOCNO}>
                  <td style={{ padding: "15px 0px 0px 0px" }}>
                    <input type="checkbox" className="checkbox"></input>
                  </td>
                  <td className="td" style={{ paddingLeft: "55px" }}>
                    {cr.DOCNO}
                  </td>
                  <td
                    className="td"
                    style={{
                      textAlign: "center",
                      margin: "2px 2px",
                      padding: "20px 74px 20px 0px",
                    }}
                  >
                    {moment(cr.DATE).format("DD/MM/YYYY")}
                  </td>
                  <td
                    style={{
                      textAlign: "right",
                      margin: "2px 2px",
                      padding: "-1px 74px 0px 0px",
                    }}
                  >
                    {cr.ACCOUNT}
                  </td>
                  <td
                    style={{
                      textAlign: "right",
                      margin: "2px 2px",
                      padding: "-1px 74px 0px 0px",
                    }}
                  >
                    {cr.AMOUNT}
                  </td>
                  <td
                    style={{
                      textAlign: "right",
                      margin: "2px 2px",
                      padding: "-1px 74px 0px 0px",
                    }}
                  >
                    {cr.CURRENCY}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UnCrNo;
