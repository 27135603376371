import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "./Components/features/userSlice";
import { useDispatch } from "react-redux";
import Footer from "./Components/Footer";
import sales from "./Images/sales.PNG";
import stock from "./Images/stock.PNG";
import sales_anaysis from "./Images/sales_anaysis.PNG";
import last_anaysis from "./Images/last_anaysis.PNG";
import { useSelector } from "react-redux";
import { selectUser } from "./Components/features/userSlice";
function AnalyticsDashboard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const userEmail = user.email;
  const logoutOff = (e) => {
    e.preventDefault();
    dispatch(logout());
    navigate("/");
  };

  return (
    <div>
      <div
        className="Unapp_payment"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          position: "sticky",
          top: "0",
          left: "0",
          zIndex: "500",
        }}
      >
        <h1 style={{ textAlign: "center" }} className="Titles">
          Tech23 Dashboards
        </h1>
        <div className="flex items-center gap-[50px]">
          <p className="text-white">
            Logged in as : {userEmail.split("@")[0].toUpperCase()}
          </p>
          <div className="back_btn">
            {" "}
            <Link
              style={{ textDecoration: "none", color: "white" }}
              to={"/Dashboard1"}
            >
              <button className="View">Back</button>
            </Link>
            {/* <button className="View" onClick={(e) => logoutOff(e)}>
              Logout
            </button> */}
          </div>
        </div>
      </div>
      <div className="h2">
        <h1>Analytics Dashboards</h1>
      </div>

      {/*  */}

      <section className="for__main__dashboard">
        <div
          onClick={() => {
            navigate("/SalesDashboard");
          }}
          className="align__img__and__heading"
        >
          <img src={sales} height="150px" width="200px" alt="dashboard" />
          <h3>Sales Analytics</h3>
        </div>
        <div
          onClick={() => {
            navigate("/StockDashboard");
          }}
          className="align__img__and__heading"
        >
          <img src={stock} height="150px" width="200px" alt="dashboard" />
          <h3>Stock Analytics</h3>
        </div>
        <div
          onClick={() => {
            navigate("/PurchasesDashboard");
          }}
          className="align__img__and__heading"
        >
          <img
            src={sales_anaysis}
            height="150px"
            width="200px"
            alt="dashboard"
          />
          <h3>Purchases Analytics</h3>
        </div>
        <div
          onClick={() => {
            navigate("/FinancialDashboard");
          }}
          className="align__img__and__heading"
        >
          <img
            src={last_anaysis}
            height="150px"
            width="200px"
            alt="dashboard"
          />
          <h3>Financial Analytics</h3>
        </div>
      </section>

      {/*
       */}
      {/* <div className="dashboard">
        <div className="first_line">
          <div
            className="card"
            onClick={() => {
              navigate("/SalesDashboard");
            }}
          >
            <div className="card-content">
              <div className="card-title">Sales Analytics</div>
            </div>
          </div>

          <div className="card">
            <div className="card-content">
              <div className="card-title">Stock Analytics</div>
            </div>
          </div>

          <div className="card">
            <div className="card-content">
              <div className="card-title">Purchases Analytics</div>
            </div>
          </div>

          <div className="card">
            <div className="card-content">
              <div className="card-title">Financial Analytics</div>
            </div>
          </div>
        </div>
      </div> */}
      <Footer />
    </div>
  );
}

export default AnalyticsDashboard;
