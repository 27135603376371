import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import "../../../../CSS/UnPayments.css";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import LoadingScreen from "../../../../Components/LoadingScreen";
import { useSelector } from "react-redux";
import { selectUser } from "../../../../Components/features/userSlice";
import Footer from "../../../../Components/Footer";
import TransportApprovalNavbar from "../../../../Components/TransportApprovalNavbar";

const FinanceUnapprovedDashboard = () => {
  const navigate = useNavigate();
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const [isloading, setisloading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get(`${BASEURL}/getPendingapprovals`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        setisloading(false);
        console.log(response.data);
        setData(response.data);
      })
      .catch((err) => {
        setisloading(false);
        console.log("error while fetching initial data", err);
      });
  }, []);

  const handleRowClick = (value, name) => {
    // value = VIEWNAME and name = DASHBOARDNAME
    switch (name) {
      case "Unapproved Payments":
        navigate("/FinanceDetailsPayments", { state: { value, name } });
        break;
      case "Unapproved Credit notes":
        navigate("/FinanceDetailsCreditNotes", { state: { value, name } });
        break;
      case "Unapproved Journals":
        navigate("/FinanceDetailsJournals", { state: { value, name } });
        break;
      default:
        break;
    }
  };

  if (isloading) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <TransportApprovalNavbar />

      <div className="h2">
        <h1>Unapproved List for Finance Department</h1>
      </div>
      <div className="scroll">
        <table
          style={{ textTransform: "uppercase" }}
          className="Payment_data_header"
        >
          <thead className="thead">
            <tr className="Payment_tr">
              <th className="header_payments_vendor">DASHBOARD NAME</th>
              <th className="value_payments_amount">COUNT</th>
            </tr>
          </thead>
          <tbody className="Payments_tbody">
            {data
              .filter((value) => {
                return value.DEPARTMENT === "ACCOUNTS";
              })
              .map((value, index) => {
                return (
                  <tr
                    key={index}
                    className="Payments_tbody_tr"
                    onClick={() =>
                      handleRowClick(value.VIEWNAME, value.DASHBOARDNAME)
                    }
                  >
                    <td
                      className="td value_payments_vendor"
                      style={{ width: "300px" }}
                    >
                      {value.DASHBOARDNAME}
                    </td>
                    <td
                      className="td value_payments_amount"
                      style={{ width: "60px" }}
                    >
                      {value.TRANSACTION_COUNT}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <Footer />
    </div>
  );
};

export default FinanceUnapprovedDashboard;
